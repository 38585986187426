/**
 * Generated by ReactYii 3.0.27 at 27.11.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import EventUserCheckpointParticipantsForm from "./_form";
import {EventUserCheckpointParticipantsModel} from "./_model";

class EventUserCheckpointParticipantsUpdate extends BaseUpdatePage {
    //public model = EventUserCheckpointParticipantsModel;
    //public FormComponent = EventUserCheckpointParticipantsForm;

    constructor(props: any) {
        super(props);
        super.model = EventUserCheckpointParticipantsModel;
        super.FormComponent = EventUserCheckpointParticipantsForm;
        super.relationTree = EventUserCheckpointParticipantsModel.relationTree
    }
}

export default withTranslation()(withRouter(EventUserCheckpointParticipantsUpdate));
