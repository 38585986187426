/**
 * Generated by ReactYii 3.0.27 at 22.11.2023
 */
import {Route} from "react-router-dom";
import WssLogCreate from './create';
import WssLogUpdate from './update';
import WssLogView from './view';
import WssLogIndex from './index';
import {WssLogModel} from "./_model";

const WssLogRoutes = [
    <>
        <Route path={WssLogModel.info.viewPath} element={<WssLogIndex/>}/>
        <Route path={WssLogModel.info.viewPath + '/create'} element={<WssLogCreate/>}/>
        <Route path={WssLogModel.info.viewPath + '/update/:id'} element={<WssLogUpdate/>}/>
        <Route path={WssLogModel.info.viewPath + '/view/:id'} element={<WssLogView/>}/>
    </>
];
export default WssLogRoutes;
