/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";
import MomentViewer from "../../crud/field_viewers/MomentViewer";
import {getInvoicePayStatus, getPaymentType} from "../kassa/helpers";
import PriceWidget from "../kassa/widgets/PriceWidget";
import UserProfileView from "../users/UserProfileView";
import {UserPaymentsModel} from "../user-payments/_model";
import {UserCheckpointsModel} from "../user-checkpoints/_model";

export const UserInvoicesModel: BaseModelInfo = {
    info: {
        name: "user-invoices",
        label: "User Invoices",
        apiPath: "/rapi/user-invoices",
        viewPath: "/user-invoices"
    },
    attrs: {
        id: {label: "№"},
        user_checkpoint_id: {label: "Событие входа"},
        user_id: {label: "ID Посетителя"},
        tarif_id: {label: "ID Тарифa"},
        //sum: {label: "Полная сумма"},
        sum: {label: "Сумма", type: FieldType.Custom, params: (val: any) => <PriceWidget value={val}/>},

        // minutes: {label: "Времени провел"},
        minutes: {
            label: "Просидел",
            type: FieldType.Custom,
            params: (val: any) => <span>{val} мин</span>
        },

        descr: {label: "Описание"},
        created_at: {label: "Дата", type: FieldType.MomentDate},
        tarif: {
            label: 'Тариф',
            type: FieldType.RelationOneData,
            params: {name: "name"},
            sortField: "tarif_id",
            searchField: ""
        },
        userCheckpoint: {
            label: '"Посещения"',
            type: FieldType.RelationMany,
            params: {name: "id", model: UserCheckpointsModel}
        },
        userPayments: {
            label: '"Чеки"',
            type: FieldType.RelationMany,
            params: {name: "id", model: UserPaymentsModel}
        },
        user: {label: 'Посетитель', type: FieldType.RelationOneData, params: {name: "name"}},
        profile: {
            sourceAttr: "user",
            label: "Профиль",
            sortField: "name",
            searchField: "name",
            type: FieldType.Custom,
            params: (val: any) => <UserProfileView id={val?.id}
                                                   image_name={val?.image_name}
                                                   name={val?.name}
                                                   role={val?.role?.name}/>
        },
        inDate: {
            sourceAttr: "userCheckpoint",
            label: "Вход",
            type: FieldType.Custom,
            params: (val: any) => <MomentViewer value={val?.created_at}/>
        },
        //  <th>Посетитель</th>
        //                     <th>Пришел в</th>
        //                     <th>Провел времени</th>
        //                     <th>Тариф</th>
        //                     <th>Сумма</th>
        //                     <th>Тип оплаты</th>
        //                     <th>Статус</th>
        outDate: {
            sourceAttr: "userCheckpoint",
            label: "Выход",
            type: FieldType.Custom,
            params: (val: any) => <MomentViewer value={val?.outside_at}/>
        },

        //is_payed: {label: "Флаг оплаты", type: FieldType.Bool},
        is_payed: {
            label: "Флаг оплаты",
            type: FieldType.Custom,
            params: (val: any) => <b> {getInvoicePayStatus(val)} </b>
        },

        //payment_type: {label: "Тип оплаты 0-нал, 1-credit card"},
        payment_type: {
            label: "Тип оплаты",
            type: FieldType.Custom,
            searchField: "payment_type",
            sortField: "payment_type",
            params: (val: any) => <b> {getPaymentType(val)} </b>
        },
    },

    /*  sum: new AttrInfo("sum", "Сумма", FieldType.custom, (val: any) => <PriceWidget value={val}/>),
        status: new AttrInfo(null, "Статус", FieldType.custom, (val: any) => <b>{getCheckpointStatus(val)} {getPayStatus(val)} </b>),
    */

    relationTree: {
        relation: {
            tarif: [],
            user: {
                //fields: ['username', 'id', 'barcode', 'fio', 'balance'],
                fields: ['image_name', 'id', 'barcode', 'name', 'balance'],
                relation: {'roles': []}
            },
        },
        relations: {
            userPayments: [],
            userCheckpoint: []
        }
    },
    defaultShowAttrs: ["created_at", "profile", "tarif", "minutes", "sum", "payment_type", "is_payed"]
};