/**
 * Generated by ReactYii 3.0.27 at 15.08.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField} from "crud";
import BaseForm from "crud/base/BaseForm";
import RelationOneDropDown from "crud/FormInputs/RelationOneDropDown";
import {UserPaymentsModel} from "./_model";

class UserPaymentsForm extends BaseForm {
    //public model = UserPaymentsModel;

    constructor(props: any) {
        super(props);
        super.model = UserPaymentsModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                user_id: '',
                user_invoice_id: '',
                uuid: '',
                sum: '',
                status: '',
                payment_type: '',
                descr: '',
                created_at: '',
                updated_at: '',
                canceled_at: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>
                    <InputField name={'id'}
                                model={this.model.info.name}
                                label={this.model.attrs.id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.id}/>

                    <InputField name={'user_id'}
                                model={this.model.info.name}
                                label={this.model.attrs.user_id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.user_id}/>

                    <InputField name={'user_invoice_id'}
                                model={this.model.info.name}
                                label={this.model.attrs.user_invoice_id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.user_invoice_id}/>

                    <InputField name={'uuid'}
                                model={this.model.info.name}
                                label={this.model.attrs.uuid.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.uuid}/>

                    <InputField name={'sum'}
                                model={this.model.info.name}
                                label={this.model.attrs.sum.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.sum}/>

                    <InputField name={'status'}
                                model={this.model.info.name}
                                label={this.model.attrs.status.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.status}/>

                    <InputField name={'payment_type'}
                                model={this.model.info.name}
                                label={this.model.attrs.payment_type.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.payment_type}/>

                    <InputField name={'descr'}
                                model={this.model.info.name}
                                label={this.model.attrs.descr.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.descr}/>

                    <InputField name={'created_at'}
                                model={this.model.info.name}
                                label={this.model.attrs.created_at.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.created_at}/>

                    <InputField name={'updated_at'}
                                model={this.model.info.name}
                                label={this.model.attrs.updated_at.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.updated_at}/>

                    <InputField name={'canceled_at'}
                                model={this.model.info.name}
                                label={this.model.attrs.canceled_at.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.canceled_at}/>


                    <RelationOneDropDown name={'user_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.user_id?.label}
                                         relationUrl={this.model.attrs.user.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "user_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['user_id']}/>

                    <RelationOneDropDown name={'userInvoice_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.userInvoice_id?.label}
                                         relationUrl={this.model.attrs.userInvoice.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "userInvoice_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['userInvoice_id']}/>


                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default UserPaymentsForm;
