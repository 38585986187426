/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {UserInvoicesModel} from "./_model";

class UserInvoicesView extends BaseViewPage {
    //public model = UserInvoicesModel;

    constructor(props: any) {
        super(props);
        super.model = UserInvoicesModel;
        super.relationTree = UserInvoicesModel.relationTree
    }
}

export default withRouter(UserInvoicesView);