import React, {useEffect} from 'react';
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";


import {Card, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import Calendar from "./calendar";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {checkoutStore} from "../../api/store/CheckoutStore";
import {observer} from "mobx-react-lite";
import {LoadByHour} from "../stat/loadByHour";

export const Index = observer(() => {
    const navigate = useNavigate();
    useEffect(() => {
        checkoutStore.getData();
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
        ];
    }, [])

    //const formatByTarif = () => { return Object.keys(data2.tarifs).map(key => { return Date.parse(key); }); }

    return (
        <div>
            <Row className="row">
                <div className="col-lg-3 col-6">
                    <div className="small-box bg-info" onClick={() => navigate("/kassa")}>
                        <div className="inner"><h3>{checkoutStore.checkpoints.length} чел</h3><p>Загрузка</p></div>
                        <div className="icon">
                            <FontAwesomeIcon icon={solid("users")} className="pl-2"/>
                        </div>
                        <a href="#" className="small-box-footer" onClick={() => navigate("/kassa")}>
                            Подробнее
                            <FontAwesomeIcon icon={solid("arrow-circle-right")} className="pl-2"/>
                        </a>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                        <div className="inner">
                            <h3> - </h3>
                            <p>Брони</p>
                        </div>
                        <div className="icon">
                            {/* <FontAwesomeIcon icon={solid("list-check")} className="pl-2"/>*/}
                            <FontAwesomeIcon icon={solid("shield-halved")} className="pl-2"/>
                        </div>
                        <a href="#" className="small-box-footer" onClick={() => toast.dark("Доступно по подписке")}>
                            Без подробностей
                            <FontAwesomeIcon icon={solid("arrow-circle-right")} className="pl-2"/>
                        </a>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-warning">
                        <div className="inner">
                            <h3>-</h3>
                            <p>Ивентов</p>
                        </div>
                        <div className="icon">
                            <FontAwesomeIcon icon={solid("face-dizzy")} className="pl-2"/>
                        </div>
                        <a href="#" className="small-box-footer" onClick={() => navigate("/table-games/events")}>
                            Перейти к ивентам
                            <FontAwesomeIcon icon={solid("arrow-circle-right")} className="pl-2"/>
                        </a>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-dark">
                        <div className="inner">
                            <Row>
                                {/*
                                <Col>
                                    <img src="/react/akim2.jpeg" height="93px" className="rounded"/>
                                </Col>
                                */}
                                <Col>
                                    <h5>Нет админа</h5>
                                    <p>Смена 2/3</p>
                                    <br/>
                                </Col>
                            </Row>
                        </div>
                        <div className="icon">
                            <FontAwesomeIcon icon={solid("face-dizzy")} className="pl-2"/>
                        </div>
                        <a href="#" className="small-box-footer" onClick={() => toast.dark("В чате узнай")}>
                            Календарь смен
                            <FontAwesomeIcon icon={solid("arrow-circle-right")} className="pl-2"/>
                        </a>
                    </div>
                </div>
            </Row>
            <Row>
                <Col md={6}>
                    <LoadByHour/>
                </Col>

                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Календарь броней</Card.Title>
                            <a href="https://calendar.google.com/calendar/u/0/r" target="_blank">
                                <FontAwesomeIcon icon={solid('arrow-up-right-from-square')} className="pl-2"/>
                            </a>
                        </Card.Header>
                        <Card.Body style={{padding: 0}}>
                            <Calendar/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/*
            <Card>
                <Card.Header>
                    <Card.Title>Гугл Каледарь</Card.Title>
                </Card.Header>
                <Card.Body>

                </Card.Body>
            </Card>
            */}
        </div>
    );
})