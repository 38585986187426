import React, {useEffect, useState} from 'react';
import {CheckpointUser} from "../../../models/users/UserCheckpoint";
import {KassaGetCheckpointsHistory} from "../../../api/kassa/kassa";
import {toast} from "react-toastify";
import {Pagination} from "../../../crud/types/Pagination";
import {UserCheckpointsModel} from "../../user-checkpoints/_model";
import TableIndex from "../../../crud/TableIndex/TableIndex";

export const KassaOfflineTable = () => {
    const defaultShowAttrs = ["created_at", "minutes", "profile", "role", "tarif", "sum", "status"];
    const [checkpoints, setCheckpoints] = useState<Array<CheckpointUser>>([]);
    const [pagination, setPagination] = useState<Pagination>(new Pagination());
    /*
        const attrs = {
            id: new AttrInfo("id", "ID"),
            users: new AttrInfo("users", "Пользователь", FieldType.relationOneData, {name: "fio"}),
            created_at: new AttrInfo("created_at", "Дата входа", FieldType.custom, (val: any) => moment(val).calendar()),
            outside_at: new AttrInfo("outside_at", "Дата выхода", FieldType.custom, (val: any) => moment(val).calendar()),
            minutes: new AttrInfo("minutes", "Времени провел", FieldType.custom, (val: any) => <span>{val}мин</span>),
            roles: new AttrInfo("users", "Роль", FieldType.custom, (val: any) => <span>{val?.roles?.name}</span>),
            sum: new AttrInfo("sum", "Сумма", FieldType.custom, (val: any) => <PriceWidget value={val}/>),
            status: new AttrInfo(null, "Статус", FieldType.custom, (val: any) => <b>{getCheckpointStatus(val)} {getPayStatus(val)} </b>),
            updated_at: new AttrInfo("updated_at", "Дата обновления"),
        };
    */


    const getData = () => {
        const treeJson = {
            relation: {
                tarif: [],
                user: {
                    fields: ['id', 'image_name', 'name', 'barcode', 'balance'],
                    relation: {'role': []}
                }
            }
        }

        KassaGetCheckpointsHistory(treeJson, pagination)
            .then((resp: any) => {
                setCheckpoints(resp.data);
                setPagination(resp.pagination)
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
    }

    useEffect(getData, [pagination.page]);
    //useEffect(() => getData(), [])

    return (
        <div className="table-responsive-sm">

            <TableIndex
                model={UserCheckpointsModel}
                data={checkpoints}
                enabledAttributes={defaultShowAttrs}
                showSettings={true}
                pagination={{
                    data: pagination,
                    callback: setPagination
                }}
                sortBy="id"
                //onSortChange={this.onSortChange}
                //searchQuery={this.state.searchString}
                //onSearch={this.onSearch}
                onRelationTreeChange={() => {
                }}
            />
        </div>
    )

}
