/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import EventTypesForm from "./_form";
import {EventTypesModel} from "./_model";

class EventTypesUpdate extends BaseUpdatePage {
    //public model = EventTypesModel;
    //public FormComponent = EventTypesForm;

    constructor(props: any) {
        super(props);
        super.model = EventTypesModel;
        super.FormComponent = EventTypesForm;
        super.relationTree = EventTypesModel.relationTree
    }
}

export default withTranslation()(withRouter(EventTypesUpdate));
