/**
 * Generated by ReactYii 3.0.27 at 02.10.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import UserTableGameEventParticipantsForm from "./_form";
import {UserTableGameEventParticipantsModel} from "./_model";

class UserTableGameEventParticipantsUpdate extends BaseUpdatePage {
    //public model = UserTableGameEventParticipantsModel;
    //public FormComponent = UserTableGameEventParticipantsForm;

    constructor(props: any) {
        super(props);
        super.model = UserTableGameEventParticipantsModel;
        super.FormComponent = UserTableGameEventParticipantsForm;
        super.relationTree = UserTableGameEventParticipantsModel.relationTree
    }
}

export default withTranslation()(withRouter(UserTableGameEventParticipantsUpdate));
