import {ApiRequest, HttpMethod} from "api/api";
import {format} from "date-fns";

const ApiLoadByHour = (date_from?: Date, date_to?: Date): Promise<any> => {
    //const params = {relationtree: JSON.stringify(tree)};
    return ApiRequest<any>(
        HttpMethod.GET,
        "/rapi/user-checkpoints/load-by-hour" +
        "?date_from=" + format(date_from ?? new Date(), 'yyyy-MM-dd') + "&" +
        "date_to=" + format(date_to ?? new Date(), 'yyyy-MM-dd'));
};


const TurnsLoadByHour = (turns_id: number): Promise<any> => {
    //const params = {relationtree: JSON.stringify(tree)};
    return ApiRequest<any>(
        HttpMethod.GET, "/rapi/user-turns/load-by-hour?user_turn_id=" + turns_id)
};

export {ApiLoadByHour, TurnsLoadByHour};