import '../../../css/404.scss'
import {breadcrumbsStore} from "../../../store/BreadcrumbsStore";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";

const NotFoundPage = () => {

    useEffect(() => {
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
            {name: "404"}
        ];
    }, []);

    // const visual = document.getElementById("visual")
    // const events = ['resize', 'load']

    /*function type(n:any, t:any) {
        var str = document.getElementsByTagName("code")[n].innerHTML.toString();
        var i = 0;
        document.getElementsByTagName("code")[n].innerHTML = "";

        setTimeout(function() {
            var se = setInterval(function() {
                i++;
                document.getElementsByTagName("code")[n].innerHTML =
                    str.slice(0, i) + "|";
                if (i == str.length) {
                    clearInterval(se);
                    document.getElementsByTagName("code")[n].innerHTML = str;
                }
            }, 10);
        }, t);
    }*/

    /* type(0, 0);
     type(1, 600);
     type(2, 1300);*/


    return (
        <div className="notfound">
            <p>HTTP: <span>404</span></p>
            <code><span>this_page</span>.<em>not_found</em> = true;</code>
            <code><span>if</span> (<b>you_spelt_it_wrong</b>) <span>try_again()</span>;</code>
            <code><span>else if (<b>we_screwed_up</b>)</span> <em>alert</em>(<i>"We're really sorry about
                that."</i>); <span>window</span>.<em>location</em> = home;</code>
            <center><Link to="/">HOME</Link></center>
        </div>
    )
}
export default NotFoundPage;