/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {UsersModel} from "./_model";
import ErrorWidget from "../../crud/components/ErrorWidget";
import {Button, Card} from "react-bootstrap";
import TableIndex from "../../crud/TableIndex/TableIndex";
import MainLoader from "../../ui/loaders/MainLoader";
import {Link} from "react-router-dom";
import {Pagination} from "../../crud/types/Pagination";

class UsersIndex extends BaseIndexPage {
    //public model = UsersModel;

    constructor(props: any) {
        super(props);
        super.model = UsersModel;
        super.defaultShowAttrs = UsersModel.defaultShowAttrs;
        super.relationTree = UsersModel.relationTree
        this.state = {
            data: {data: [], pagination: new Pagination()},
            isLoadedFirstPage: false,
            hasError: false,
            hasMore: true,
            sortBy: "-id", //id,-id, someField
            searchArray: {}, //id=2&someField=someValue&
            showAttr: {},
        };
        this.state.data.pagination.pageSize = 15;
    }

    render() {
        if (this.state.hasError) {
            return <ErrorWidget/>;
        }

        return (
            <Card className="card-outline card-success">
                <Card.Header className="p-2">
                    <Button variant="primary" className="btn-sm m-1"
                            onClick={() => this.onSearch({})}>
                        Все
                    </Button>
                    <Button variant="dark" className="btn-sm m-1"
                            onClick={() => this.onSearch({role_group_id: "3"})}>
                        Админы
                    </Button>
                    <Button variant="success" className="btn-sm m-1"
                            onClick={() => this.onSearch({role_group_id: "2"})}
                    >
                        Постоянники
                    </Button>

                    <Button variant="info" className="btn-sm m-1"
                            onClick={() => this.onSearch({comment: "Reg from Bot"})}>Регистрация из бота
                    </Button>
                    {/*  <Button variant="secondary" className="btn-sm m-1"
                            onClick={() => this.onSearch({is_dead_soul: 1})}>Legacy Карты
                    </Button>*/}
                    <Button variant="warning" className="btn-sm m-1"
                            onClick={() => this.onSearch({role_group: "Игроведы"})}>Игроведы</Button>
                    <Button variant="dark" className="btn-sm m-1"
                            onClick={() => this.onSearch({role_group: "Фришники"})}>Фришники</Button>
                    <Button variant="danger" className="btn-sm m-1" disabled>Бан</Button>

                    <div className="float-right">
                        <Link className="btn-outline-success btn btn-sm m-2" to={'create'}>Create</Link>
                    </div>
                </Card.Header>

                <Card.Body className="p-0">
                    {this.state.isLoadedFirstPage ? (
                        <>
                            {/* <InfiniteScroll
                                dataLength={this.state.data.data.length}
                                next={this.loadMore}
                                //hasMore={this.state.hasMore}
                                hasMore={false}
                                loader={<MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>}
                                //endMessage={<p style={{textAlign: 'center'}}>На этом все</p>}
                                endMessage=""*/}
                            <TableIndex
                                model={this.model}
                                data={this.state.data.data}
                                enabledAttributes={this.defaultShowAttrs}
                                actions={{update: {title: "update"}}}
                                pagination={{data: this.state.data.pagination, callback: this.actionPagination}}
                                sortBy={this.state.sortBy}
                                onSortChange={this.onSortChange}
                                searchQuery={this.state.searchArray}
                                onSearch={this.onSearch}
                                onRelationTreeChange={this.onTreeChange}
                            />
                            {/*  <span>
                                Страница: {this.state.data.pagination.page} из {this.state.data.pagination.pageCount}
                                <br/>
                            Элементы: {this.state.data.pagination.pageSize}/{this.state.data.pagination.totalItems},
                            </span>*/}
                        </>
                    ) : (
                        <MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>
                    )}
                </Card.Body>
            </Card>
        );
    }

    /* render() {
         if (this.state.hasError) {
             return <ErrorWidget/>
         }
         return (
             <>
                 <Card className="card-outline card-success">
                     <Card.Header className="p-2">
                         <Button variant="dark" className="btn-sm m-1"
                                 onClick={() => this.onSearch("role_id=8,9,10,11,12")}>
                             Админы
                         </Button>
                         <Button variant="success" className="btn-sm m-1" disabled>Постоянники</Button>
                         <Button variant="info" className="btn-sm m-1" disabled>Из Телеги</Button>
                         <Button variant="secondary" className="btn-sm m-1"
                                 onClick={() => this.onSearch("is_dead_soul=1")}>Legacy Карты
                         </Button>
                         <Button variant="warning" className="btn-sm m-1"
                                 onClick={() => this.onSearch("role_id=14,15,16")}>Игроведы</Button>
                         <Button variant="danger" className="btn-sm m-1" disabled>Бан</Button>

                         <div className="float-right">
                             <Link className="btn-outline-success btn btn-sm m-2" to={'create'}>Create</Link>
                         </div>

                     </Card.Header>
                     <Card.Body className="p-0">
                         {
                             this.state.isLoadedFirstPage
                                 ? <TableIndex
                                     model={this.model}
                                     data={this.state.data.data}
                                     enabledAttributes={this.defaultShowAttrs}
                                     pagination={{data: this.state.data.pagination, callback: this.actionPagination}}
                                     sortBy={this.state.sortBy}
                                     onSortChange={this.onSortChange}
                                     searchQuery={this.state.searchString}
                                     onSearch={this.onSearch}
                                     onRelationTreeChange={this.onTreeChange}
                                 />
                                 : <MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>

                         }
                     </Card.Body>
                 </Card>
             </>
         );
     }*/
}

export default UsersIndex;