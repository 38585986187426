/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TableGamesCoopsModel} from "./_model";

class TableGamesCoopsIndex extends BaseIndexPage {
    //public model = TableGamesCoopsModel;

    constructor(props: any) {
        super(props);
        super.model = TableGamesCoopsModel;
        super.defaultShowAttrs = TableGamesCoopsModel.defaultShowAttrs;
        super.relationTree = TableGamesCoopsModel.relationTree
    }
}

export default TableGamesCoopsIndex;