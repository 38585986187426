import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {MenuItem} from '../../../adminLTE';
import {uiStore} from "../../../store/UiStore";
import {observer} from "mobx-react-lite";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

export interface IMenuItem {
    name: string;
    icon?: IconDefinition;
    path?: string;
    newTab?: boolean;
    children?: Array<IMenuItem>;
}

type Props = {
    menu: IMenuItem[],
    showSearch: boolean,
}

const MenuSidebar = observer((props: Props) => {
    const {sidebarSkin, menuItemFlat, menuChildIndent} = uiStore;
    const [t] = useTranslation();
    const [searchText, setSearchText] = useState('');

    return <aside className={`main-sidebar elevation-4 ${sidebarSkin}`}>
        <Link to="/" className="brand-link">
            <img className="brand-image img-circle elevation-3 bg-black" src="/logo.png" alt="logo"/>
            <span className="brand-text font-weight-light">ZD Admin</span>
        </Link>
        <div className="sidebar">
            {
                (props.showSearch) &&
                <div className="form-inline mt-3">
                    <div slot="button">
                        <div className="input-group">
                            <input className="form-control form-control-sidebar"
                                   type="text" placeholder={t("main.search") + "..."}
                                   aria-label="Search" value={searchText}
                                   onInput={(e) => setSearchText((e.target as any).value)}
                            />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-sidebar" onClick={() => setSearchText("")}>
                                    {
                                        (searchText.length === 0) &&
                                        <FontAwesomeIcon icon={solid("search")}/>

                                    }
                                    {
                                        (searchText.length > 0) &&
                                        <FontAwesomeIcon icon={solid("times")}/>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <nav className="mt-2" style={{overflowY: 'hidden'}}>
                <ul role="menu" className={`nav nav-pills nav-sidebar flex-column nav-compact
                            ${menuItemFlat ? ' nav-flat' : ''}
                            ${menuChildIndent ? ' nav-child-indent' : ''}`
                }>
                    {props.menu.map((menuItem: IMenuItem) => (
                        <MenuItem
                            key={menuItem.name + menuItem.path}
                            menuItem={menuItem}
                            searchString={searchText}
                        />
                    ))}
                </ul>
            </nav>
        </div>
    </aside>
});

export default MenuSidebar;