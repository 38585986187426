import React, {Component} from 'react';
import {toast} from "react-toastify";
import {Translation} from "react-i18next";
import {Card} from "react-bootstrap";
import IndexDataLoader from "ui/loaders/IndexDataLoader";
import {breadcrumbsStore} from "store/BreadcrumbsStore";
import BaseForm from "./BaseForm";
import {GetModel} from "../api/crudApi";
import {BaseModelInfo, defaultModel} from "../types/BaseModelInfo";

type State = {
    model: any,
    isDataLoaded: boolean
}

class BaseUpdatePage extends Component<any, State> {
    public model: BaseModelInfo = defaultModel;
    public relationTree = {}
    public FormComponent = BaseForm;

    constructor(props: any) {
        super(props);
        this.state = {model: {}, isDataLoaded: false}
    }

    componentDidMount() {
        const id = this.props?.match?.params.id;

        GetModel(this.model.info.apiPath, id, this.relationTree)
            .then((resp: any) => {
                this.setState({model: resp.data});
                breadcrumbsStore.items = [
                    /* {name: 'Home', link: '/'},*/
                    {name: <Translation>{(t: any) => t('crud.home')}</Translation>},
                    {name: this.model.info.label, link: this.model.info.viewPath},
                    {
                        name: resp.data?.name ?? "#" + resp.data.id,
                        link: this.model.info.viewPath + '/view/' + resp.data.id
                    },
                    {name: <Translation>{(t: any) => t('crud.update')}</Translation>},
                ]
                this.setState({isDataLoaded: true});
            })
            .catch((error) => toast.error(error.toString() || 'Failed'));

    }

    render() {
        return (
            <Card>
                <Card.Header>
                    <Card.Title>
                        <Translation>{(t: any) => t('crud.update')}</Translation> {this.state.model?.name}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {(this.state.isDataLoaded)
                        ? <this.FormComponent
                            action={this.model.info.apiPath + '/update?id=' + this.state.model.id}
                            data={this.state.model}/>
                        : <IndexDataLoader/>
                    }
                </Card.Body>
            </Card>
        );
    }
}

export default BaseUpdatePage;