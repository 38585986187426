/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseCreatePage from "crud/base/BaseCreatePage";
import UserInvoicesForm from "./_form";
import {UserInvoicesModel} from "./_model";


class UserInvoicesCreate extends BaseCreatePage {
    //public model = UserInvoicesModel;
    //public FormComponent = UserInvoicesForm;

    constructor(props: any) {
        super(props);
        super.model = UserInvoicesModel;
        super.FormComponent = UserInvoicesForm;
        super.relationTree = UserInvoicesModel.relationTree
    }
}

export default UserInvoicesCreate;