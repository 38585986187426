/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField} from "crud";
import BaseForm from "crud/base/BaseForm";
import RelationOneDropDown from "crud/FormInputs/RelationOneDropDown";
// @ts-ignore
import {RolesModel} from "./_model";

class RolesForm extends BaseForm {
    //public model = RolesModel;

    constructor(props: any) {
        super(props);
        super.model = RolesModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                tarif_id: '',
                role_group_id: '',
                level: '',
                name: '',
                descr: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>
                    {/*   <InputField name={'tarif_id'}
                                model={this.model.info.name}
                                label={this.model.attrs.tarif_id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['tarif_id']}/>

                    <InputField name={'role_group_id'}
                                model={this.model.info.name}
                                label={this.model.attrs.role_group_id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['role_group_id']}/>
*/}


                    <InputField name={'name'}
                                model={this.model.info.name}
                                label={this.model.attrs.name.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['name']}/>

                    <InputField name={'descr'}
                                model={this.model.info.name}
                                label={this.model.attrs.descr.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['descr']}/>


                    <RelationOneDropDown name={'role_group_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.role_group_id?.label}
                                         relationUrl={this.model.attrs.role_group_id.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "role_group_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['role_group_id']}/>

                    <RelationOneDropDown name={'tarif_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tarif_id?.label}
                                         relationUrl={this.model.attrs.tarif_id.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tarif_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tarif_id']}/>

                    <InputField name={'level'}
                                model={this.model.info.name}
                                label={this.model.attrs.level.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['level']}/>

                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default RolesForm;
