import React from 'react';
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {toast} from "react-toastify";
import {checkoutStore} from "../../api/store/CheckoutStore";
import {observer} from "mobx-react-lite";
import {LoadByHour} from "./loadByHour";

export const Statistic = observer(() => {
    return (
        <div>
            <Row className="row">
                <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                        <div className="inner"><h3>{checkoutStore.checkpoints.length} чел</h3><p>Загрузка</p></div>
                        <div className="icon">
                            <FontAwesomeIcon icon={solid("users")} className="pl-2"/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                        <div className="inner">
                            <h3> - </h3>
                            <p>Брони</p>
                        </div>
                        <div className="icon">
                            {/* <FontAwesomeIcon icon={solid("list-check")} className="pl-2"/>*/}
                            <FontAwesomeIcon icon={solid("shield-halved")} className="pl-2"/>
                        </div>
                        <a href="#" className="small-box-footer" onClick={() => toast.dark("Доступно по подписке")}>
                            Без подробностей
                            <FontAwesomeIcon icon={solid("arrow-circle-right")} className="pl-2"/>
                        </a>
                    </div>
                </div>
            </Row>
            <Row>
                <Col md={12}>
                    <LoadByHour/>
                </Col>
            </Row>

            {/*
            <Card>
                <Card.Header>
                    <Card.Title>Гугл Каледарь</Card.Title>
                </Card.Header>
                <Card.Body>

                </Card.Body>
            </Card>
            */}
        </div>
    );
})