/**
 * Generated by ReactYii 3.0.27 at 27.11.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const EventUserCheckpointParticipantsModel: BaseModelInfo = {
    info: {
        name: "event-user-checkpoint-participants",
        label: "Event User Checkpoint Participants",
        apiPath: "/rapi/event-user-checkpoint-participants",
        viewPath: "/event-user-checkpoint-participants"
    },
    attrs: {
        id: {label: "ID"},
        event_id: {label: "Событие"},
        user_checkpoint_id: {label: "Посещение"},
        created_at: {label: "Дата"},
        event: {label: '"Event"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        userCheckpoint: {
            label: '"UserCheckpoint"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
    },
    relationTree: {},
    defaultShowAttrs: ["event_id", "user_checkpoint_id", "created_at",]
};


