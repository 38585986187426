/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {UsersModel} from "./_model";
import {Link, Navigate} from "react-router-dom";
import {Button, Card, Col, Row} from "react-bootstrap";
import {BaseDeleteModal} from "../../crud/dialogs/BaseDeleteModal";
import {Translation} from "react-i18next";
import DataTable from "../../crud/data_viewers/DataTable";
import TableLoader from "../../ui/loaders/TableLoader";
import React from "react";
import {Constants} from "../../utils/constants";
import axios from "axios";

class UsersView extends BaseViewPage {
    //public model = UsersModel;

    constructor(props: any) {
        super(props);
        super.model = UsersModel;
        super.relationTree = UsersModel.relationTree
    }

    barCodeActive = () => {
        let barCodeUrl = Constants.API_URL + `/api/scanner/scan?barcode=${this.state.data?.barcode}`;
        axios.get(barCodeUrl, {})
            .then((resp) => {
                console.log(resp.data);

            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath}/>;
        }

        return (this.state.isDataLoaded)
            ? <Card className="card-outline card-success">
                <BaseDeleteModal show={this.state.modal} onDelete={this.deleteModel} onClose={this.closeModal}/>
                <Card.Header>
                    <Row className="justify-content-between">
                        <Col><h1>{this.state.data?.name ?? "#" + this.state.data?.id}</h1></Col>
                        <Col>
                            <div className="float-right">
                                <Link className="btn btn-primary"
                                      to={this.model.info.viewPath + '/update/' + this.state.data.id}>
                                    <Translation>{(t: any) => t('crud.update')}</Translation>
                                </Link>
                                <Link className="m-1 btn btn-info"
                                      onClick={() => this.barCodeActive()}
                                      to="#">
                                    Записать
                                </Link>
                                <Button variant="danger" className="ml-2"
                                        onClick={() => this.openModal(this.state.data.id)}>
                                    <Translation>{(t: any) => t('crud.delete')}</Translation>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        data={this.state.data}
                        model={this.model}
                        disabledFields={this.disabledAttr}
                    />
                </Card.Body>
            </Card> : <TableLoader/>;
    }
}

export default withRouter(UsersView);