import React, {useEffect, useState} from 'react';
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {KassaOnlineTable} from "./tabs/online";
import {KassaOfflineTable} from "./tabs/offline";
import {checkoutStore} from "../../api/store/CheckoutStore";
import {observer} from "mobx-react-lite";
import {InvoicesTable} from "./tabs/invoices";
import {Translation} from "react-i18next";
import {InvoiceModal} from "./modals/InvoiceModal";
import {KassaTurns} from "./tabs/turns";
import {toast} from "react-toastify";
import {KassaGetCurrentTurns} from "../../api/kassa/kassa";
import moment from "moment";
import {CheckpointsModal} from "./modals/CheckpointsModal";
import {PaymentsTable} from "./tabs/payments/payments";

export const KassaIndex = observer(() => {
    useEffect(() => {
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
            {name: "Касса"}
        ];
    });

    const [currentTurns, setCurrentTurns] = useState<any>();

    useEffect(() => {
        KassaGetCurrentTurns()
            .then((resp: any) => {
                console.log(resp.data)
                setCurrentTurns(resp.data);
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed get Turns'));

        console.log(currentTurns)
        moment.locale("ru");
    }, []);

    return <Row>
        <CheckpointsModal/>
        <InvoiceModal onClose={() => checkoutStore.currentInvoice = null} invoice={checkoutStore.currentInvoice}/>
        <Col>
            <Tabs defaultActiveKey="online" onSelect={(eventKey, e)=> console.log(eventKey)}>
                <Tab eventKey="online"
                     title={<span className="btn btn-sm btn-success">
                        Онлайн
                        <span className="badge  right text-md">{checkoutStore.checkpoints.length}</span>
                    </span>}>
                    <KassaOnlineTable/>
                </Tab>
                <Tab eventKey="offline" title={<span className="btn btn-sm btn-secondary">Записи</span>}>
                    <KassaOfflineTable/>
                </Tab>
                <Tab eventKey="invoices" title={<span className="btn btn-sm btn-warning">Оплаты</span>}>
                    <InvoicesTable/>
                </Tab>
                <Tab eventKey="payments" title={<span className="btn btn-sm btn-success">Приходы расходы</span>}>
                    <PaymentsTable/>
                </Tab>


                <Tab eventKey="turns"
                     tabClassName="text-right float-right nav-tabs-highlight"
                     title={
                         <span className="btn btn-sm btn-dark float-right">
                         Смена
                             {/*   <Moment fromNow ago>{currentTurns?.open_dt}</Moment>*/}
                             <span className="pl-1">{moment(currentTurns?.open_dt + "Z").calendar()}</span>
                    </span>}>
                    <KassaTurns/>
                </Tab>


            </Tabs>
        </Col>

    </Row>
})

export default KassaIndex;