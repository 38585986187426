import React, {ReactElement, useEffect, useState} from 'react'
import Select from 'react-select'
import {InputGroup} from "react-bootstrap";
import {AttrInfo} from "../types/AttrInfo";

export type Props = {
    name: string,
    model: string,
    value: any,
    options: any
    label: string | ReactElement,
    class?: string,
    placeholder?: string,
    onChange: Function,
    required?: boolean,
    disabled?: boolean,
    helpBlock?: string,
    validated?: any,
    pluginProps?: any,
    attr?: AttrInfo,
};


export default function DropDownList(props: Props) {
    //const [helpBlock, setHelpBlock] = useState("");
    //const [validated, setValidated] = useState<boolean | undefined>();

    const [selected, setSelected] = useState();


    useEffect(() => {
        if (Array.isArray(props.options)) {
            setSelected(props.options.find((v: any) => v.value === props?.value?.toString()));
        }

    }, [props]);

    //todo add validation
    /*const onChangeValidate = (event: any) => {
         props.onChange(event)

         if (props.model !== undefined && props.model !== '') {
             validate(
                 props.model,
                 props.name,
                 event.value,
                 () => {
                     setHelpBlock("")
                     setValidated(true)
                 },
                 (err: any) => {
                     setHelpBlock(err)
                     setValidated(false)
                 }).then();
         }
     }*/

    return (
        <InputGroup
            className={'form-group field-' + props.model + '-' + props.name + ' ' + props.class}>
            <InputGroup.Prepend>
                <InputGroup.Text className={'control-label ' + {/*props.required*/}}>{props.label}</InputGroup.Text>
            </InputGroup.Prepend>

            <Select
                {...props.pluginProps}
                styles={{
                    container: (baseStyles, _) => ({...baseStyles, padding: '0',}),
                    control: (baseStyles, _) => ({...baseStyles, border: "none", minHeight: "9"}),
                }}
                classNames={{container: () => "form-control"}}
                options={props.options}
                isSearchable={true}
                //value={props.value}
                value={selected}
                // validated={validated}
                name={props.name}
                // onChange={props.onChange}
                onChange={(e: any) => props.onChange({name: props.name, value: e.value})}
                required={props.required}
                //className={(validated !== '' ? (validated === true) ? 'is-valid' : 'is-invalid' : '')}
            />
            {/*  {
                (validated !== undefined && !validated) &&
                <InputGroup.Append>
                    <InputGroup.Text>
                        {(props.validated !== undefined) ? props.helpBlock : helpBlock}
                    </InputGroup.Text>
                </InputGroup.Append>
            }*/}
        </InputGroup>
    );
}