/**
 * Generated by ReactYii 3.0.27 at 22.11.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import WssLogForm from "./_form";
import {WssLogModel} from "./_model";


class WssLogCreate extends BaseCreatePage {
    //public model = WssLogModel;
    //public FormComponent = WssLogForm;

    constructor(props: any) {
        super(props);
        super.model = WssLogModel;
        super.FormComponent = WssLogForm;
        super.relationTree = WssLogModel.relationTree
    }
}

export default WssLogCreate;