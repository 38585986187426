/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseCreatePage from "crud/base/BaseCreatePage";
import TableGamesTypesForm from "./_form";
import {TableGamesTypesModel} from "./_model";


class TableGamesTypesCreate extends BaseCreatePage {
    //public model = TableGamesTypesModel;
    //public FormComponent = TableGamesTypesForm;

    constructor(props: any) {
        super(props);
        super.model = TableGamesTypesModel;
        super.FormComponent = TableGamesTypesForm;
        super.relationTree = TableGamesTypesModel.relationTree
    }
}

export default TableGamesTypesCreate;