import React, {useEffect, useState} from 'react';
import {Location, NavLink, useLocation, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IMenuItem} from './MenuSidebar';
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {uiStore} from "../../../store/UiStore";

const MenuItem = ({menuItem, searchString}: { menuItem: IMenuItem, searchString: string }) => {
    const [isMenuExtended, setIsMenuExtended] = useState(false);
    const [isExpandable, setIsExpandable] = useState(false);
    const [isMainActive, setIsMainActive] = useState(false);
    const [isOneOfChildrenActive, setIsOneOfChildrenActive] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();


    const filterRule = (item: IMenuItem) => item.name.toLowerCase().includes(searchString.toLowerCase()) || item.path?.toLowerCase().includes(searchString.toLowerCase());

    const toggleMenu = () => {
        setIsMenuExtended(!isMenuExtended);
    };

    const handleMainMenuAction = () => {
        if (isExpandable) {
            toggleMenu();
            return;
        }
        if (menuItem.path) {
            (uiStore.screenSize === 'xs') && onMobileClick()
            if (menuItem.path.startsWith('https://')) {
                window.open(menuItem.path, menuItem.newTab ? '_blank' : '');
            } else {
                navigate(menuItem.path);
            }
        }
    };

    const calculateIsActive = (url: Location) => {
        setIsMainActive(false);
        setIsOneOfChildrenActive(false);
        if (isExpandable && menuItem && menuItem.children) {
            menuItem.children.forEach((item: any) => {
                if (item.path === url.pathname) {
                    setIsOneOfChildrenActive(true);
                    setIsMenuExtended(true);
                }
            });
        } else if (menuItem.path === url.pathname) {
            setIsMainActive(true);
        }
    };

    useEffect(() => {
        if (location) {
            calculateIsActive(location);
        }
    }, [location, isExpandable, menuItem]);

    useEffect(() => setIsExpandable(Boolean(menuItem && menuItem.children && menuItem.children.length > 0)), [menuItem]);

    useEffect(() => setIsMenuExtended(searchString.length > 0), [searchString]);


    const filteredChildren = menuItem?.children?.filter(
        (menu) => {
            if (menu.children) {
                return menu.children.filter((menu2) => {
                    //console.log("MenuItem menu2",menu2)
                    if (menu2.children) {
                        return menu2.children.filter((menu3) => {
                            console.log("MenuItem menu3", menu3)
                            return filterRule(menu3)
                        });
                    } else {
                        return filterRule(menu2)
                    }
                })
            } else {
                return filterRule(menu)
            }

        }
    );

    const filteredSelf = filterRule(menuItem) || (filteredChildren?.length ?? 0) > 0

    const onMobileClick = () => {
        console.log("Click menu on xs");
        (uiStore.menuSidebarCollapsed && uiStore.screenSize === 'xs') && uiStore.toggleMenuSidebarCollapsed()
    }

    return (filteredSelf) ? (
        <li className={`nav-item ${isMenuExtended ? 'menu-open' : ''}`}>
            <a className={`nav-link ${isMainActive || isOneOfChildrenActive ? 'active' : ''}`}
               role="link" target={menuItem.newTab ? "_blank" : ""}
               onClick={handleMainMenuAction} style={{cursor: 'pointer'}}>
                {
                    (menuItem.icon !== undefined) &&
                    <FontAwesomeIcon icon={(menuItem?.icon)} className="nav-icon"/>
                }
                <p>{(menuItem.name)}</p>
                {
                    isExpandable
                        ? <FontAwesomeIcon icon={solid("angle-left")} className="right"/>
                        : null
                }
            </a>

            {isExpandable && (
                <ul className="nav nav-treeview">
                    {filteredChildren?.map((item) => {
                        return ((item.children?.length ?? 0) >> 0)
                            ? <MenuItem key={item.name} menuItem={item} searchString={searchString}/>
                            : <li key={item.name} className="nav-item">
                                <NavLink className="nav-link"
                                         onClick={() => (uiStore.screenSize === 'xs') && onMobileClick()}
                                         to={item?.path ?? "#"}
                                         target={item.newTab ? "_blank" : ""}>
                                    {item.icon && <FontAwesomeIcon icon={item.icon} className="nav-icon"/>}
                                    <p>{item.name}</p>
                                </NavLink>
                            </li>
                    })}
                </ul>
            )}
        </li>
    ) : <></>;
};

export default MenuItem;