/**
 * Generated by ReactYii 3.0.27 at 02.10.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {UserTableGameEventParticipantsModel} from "./_model";

class UserTableGameEventParticipantsIndex extends BaseIndexPage {
    //public model = UserTableGameEventParticipantsModel;

    constructor(props: any) {
        super(props);
        super.model = UserTableGameEventParticipantsModel;
        super.defaultShowAttrs = UserTableGameEventParticipantsModel.defaultShowAttrs;
        super.relationTree = UserTableGameEventParticipantsModel.relationTree
    }
}

export default UserTableGameEventParticipantsIndex;