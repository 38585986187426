/**
 * Generated by ReactYii 3.0.27 at 15.08.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import UserPaymentsForm from "./_form";
import {UserPaymentsModel} from "./_model";

class UserPaymentsUpdate extends BaseUpdatePage {
    //public model = UserPaymentsModel;
    //public FormComponent = UserPaymentsForm;

    constructor(props: any) {
        super(props);
        super.model = UserPaymentsModel;
        super.FormComponent = UserPaymentsForm;
        super.relationTree = UserPaymentsModel.relationTree
    }
}

export default withTranslation()(withRouter(UserPaymentsUpdate));
