/**
 * Generated by ReactYii 3.0.27 at 15.08.2023
 */
import {Route} from "react-router-dom";
import UserPaymentsCreate from './create';
import UserPaymentsUpdate from './update';
import UserPaymentsView from './view';
import UserPaymentsIndex from './index';
import {UserPaymentsModel} from "./_model";

const UserPaymentsRoutes = [
    <>
        <Route path={UserPaymentsModel.info.viewPath} element={<UserPaymentsIndex/>}/>
        <Route path={UserPaymentsModel.info.viewPath + '/create'} element={<UserPaymentsCreate/>}/>
        <Route path={UserPaymentsModel.info.viewPath + '/update/:id'} element={<UserPaymentsUpdate/>}/>
        <Route path={UserPaymentsModel.info.viewPath + '/view/:id'} element={<UserPaymentsView/>}/>
    </>
];
export default UserPaymentsRoutes;
