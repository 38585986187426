import React from 'react';
import {Card, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";

type Props = {
    isShow: boolean,
    onClose: any,

}

export const PaymentsCreateModal = (props: Props) => {
    // const navigate = useNavigate();

    const onClose = () => {
        props.onClose();
    }

    //const checkout = (id: number, type: keyof typeof CheckoutType) => {
    /*    const checkout = (id: number, type: CheckoutType) => {
            KassaCheckout(id, type)
                .then((resp: any) => {
                    console.log(resp)
                    //setCurrentUsers(resp.data);
                    onClose()
                })
                .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
        }*/

    return <Modal className="modal-warning" show={(props.isShow)} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Добавить платеж</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Card>
                <Card.Body>
                    <>
                        <table className="table">
                            <tbody>
                            {
                                /*  <tr>
                                <td>Карта</td>
                                <td>{props.invoice?.user?.barcode}</td>
                                 </tr>
                            */}
                            <tr>
                                <td colSpan={2}>
                                    {/* <button type="button" className="btn btn-outline-info btn-sm"
                                        onClick={() => navigate(UsersModel.info.viewPath + "/view/" + props.invoice?.user?.id)}>
                                    {props.invoice?.user?.name}
                                </button>*/}





                                </td>
                            </tr>
                            {/*  <tr>
                            <td>Роль</td>
                            <td>{props.invoice?.user?.role?.name}</td>
                        </tr>*/}
                            <tr>
                                <td>Тариф</td>

                            </tr>
                            <tr>
                                <td>Тип оплаты</td>

                            </tr>

                            <tr>
                                <td>Время прихода</td>

                            </tr>
                            <tr>
                                <td>Времени провел</td>

                            </tr>
                            <tr>
                                <td>Итого</td>
                                <td>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>

                    </>
                </Card.Body>
            </Card>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">

        </Modal.Footer>
    </Modal>
}
