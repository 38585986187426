/**
 * Generated by ReactYii 3.0.27 at 02.10.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {UserTableGameEventsModel} from "./_model";

class UserTableGameEventsView extends BaseViewPage {
    //public model = UserTableGameEventsModel;

    constructor(props: any) {
        super(props);
        super.model = UserTableGameEventsModel;
        super.relationTree = UserTableGameEventsModel.relationTree
    }
}

export default withRouter(UserTableGameEventsView);