/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import {TableGamesModel} from "./_model";
import {lazy, Suspense} from "react";

const TableGamesCreate = lazy(() => import("./create"));
const TableGamesUpdate = lazy(() => import("./update"));
const TableGamesView = lazy(() => import("./view"));
const TableGamesIndex = lazy(() => import("./index"));

const TableGamesRoutes = [
    <>
        <Route path={TableGamesModel.info.viewPath} element={<Suspense><TableGamesIndex/></Suspense>}/>
        <Route path={TableGamesModel.info.viewPath + '/create'} element={<Suspense><TableGamesCreate/></Suspense>}/>
        <Route path={TableGamesModel.info.viewPath + '/update/:id'} element={<Suspense><TableGamesUpdate/></Suspense>}/>
        <Route path={TableGamesModel.info.viewPath + '/view/:id'} element={<Suspense><TableGamesView/></Suspense>}/>
    </>
];
export default TableGamesRoutes;
