/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import BaseCreatePage from "crud/base/BaseCreatePage";
import WikiCategoriesForm from "./_form";
import {WikiCategoriesModel} from "./_model";


class WikiCategoriesCreate extends BaseCreatePage {
    //public model = WikiCategoriesModel;
    //public FormComponent = WikiCategoriesForm;

    constructor(props: any) {
        super(props);
        super.model = WikiCategoriesModel;
        super.FormComponent = WikiCategoriesForm;
        super.relationTree = WikiCategoriesModel.relationTree
    }
}

export default WikiCategoriesCreate;