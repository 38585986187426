/**
 * Generated by ReactYii 3.0.27 at 07.05.2023
 */
import BaseCreatePage from "crud/base/BaseCreatePage";
import TagsForm from "./_form";
import {TagsModel} from "./_model";


class TagsCreate extends BaseCreatePage {
    //public model = TagsModel;
    //public FormComponent = TagsForm;

    constructor(props: any) {
        super(props);
        super.model = TagsModel;
        super.FormComponent = TagsForm;
        super.relationTree = TagsModel.relationTree
    }
}

export default TagsCreate;