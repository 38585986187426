import React, {useEffect} from 'react';
import useWebSocket, {ReadyState} from "react-use-websocket";
import {Constants} from "../../utils/constants";
import {checkoutStore} from "../../api/store/CheckoutStore";
import {observer} from "mobx-react-lite";
import {Button, ButtonGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug, faCheck, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import {uiStore} from "../../store/UiStore";
import onCheckpoint from "./onCheckpoint";
import onPayment from "./onPayment";

export const WssHeader = observer(() => {
    const {sendMessage, lastMessage, readyState} = useWebSocket(Constants.WSS_URL,
        {
            shouldReconnect: (_) => true,
            reconnectAttempts: 10,
            reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
            //reconnectInterval: 3000,
        }
    );

    useEffect(() => {
        if (lastMessage !== null) {
            console.log("WSS://data:", lastMessage);
            const resp = JSON.parse(lastMessage?.data);
            console.log("WSS://resp:", resp);

            if (resp?.scan) {
                return onCheckpoint(resp);
            }

            if (resp?.payment) {
                console.log()
                return onPayment(resp);
            }

            checkoutStore.getData()
        }
    }, [lastMessage]);


    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    useEffect(() => {
        console.log("wss status: ", connectionStatus)
        //toast.info("WSS://" + connectionStatus);
    }, [connectionStatus, readyState]);

    const connectionStatusIcon = {
        [ReadyState.CONNECTING]: <FontAwesomeIcon icon={faSpinner} color="yellow"/>,
        [ReadyState.OPEN]: <FontAwesomeIcon icon={faCheck} color="green"/>,
        [ReadyState.CLOSING]: <FontAwesomeIcon icon={faSpinner} color="yellow"/>,
        [ReadyState.CLOSED]: <FontAwesomeIcon icon={faTimes} color="red"/>,
        [ReadyState.UNINSTANTIATED]: <FontAwesomeIcon icon={faBug} color="red"/>,
    }[readyState];


    return (
        (uiStore.screenSize === 'lg')
            ? <ButtonGroup aria-label="WSS status" className="btn-group-sm">
                <Button variant="dark">WSS://</Button>
                <Button variant="light">{connectionStatusIcon}</Button>
            </ButtonGroup>
            : <></>
    );
})

export default WssHeader;