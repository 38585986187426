/**
 * Generated by ReactYii 3.0.27 at 02.10.2023
 */
import {Route} from "react-router-dom";
import UserTableGameEventParticipantsCreate from './create';
import UserTableGameEventParticipantsUpdate from './update';
import UserTableGameEventParticipantsView from './view';
import UserTableGameEventParticipantsIndex from './index';
import {UserTableGameEventParticipantsModel} from "./_model";

const UserTableGameEventParticipantsRoutes = [
    <>
        <Route path={UserTableGameEventParticipantsModel.info.viewPath}
               element={<UserTableGameEventParticipantsIndex/>}/>
        <Route path={UserTableGameEventParticipantsModel.info.viewPath + '/create'}
               element={<UserTableGameEventParticipantsCreate/>}/>
        <Route path={UserTableGameEventParticipantsModel.info.viewPath + '/update/:id'}
               element={<UserTableGameEventParticipantsUpdate/>}/>
        <Route path={UserTableGameEventParticipantsModel.info.viewPath + '/view/:id'}
               element={<UserTableGameEventParticipantsView/>}/>
    </>
];
export default UserTableGameEventParticipantsRoutes;
