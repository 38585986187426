/**
 * Generated by ReactYii 3.0.27 at 23.11.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const UserTurnsModel: BaseModelInfo = {
    info: {
        name: "user-turns",
        label: "Смены",
        apiPath: "/rapi/user-turns",
        viewPath: "/user-turns"
    },
    attrs: {
        id: {label: "ID"},
        user_id: {label: "Администратор"},
        is_closed: {label: "Закрытая смена", type: FieldType.Bool},
        open_dt: {label: "Дата открытия", type: FieldType.MomentDate},
        close_dt: {label: "Дата закрытия", type: FieldType.MomentDate},
        user: {label: '"Админ"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },
    relationTree: {
        relation: {
            user: [],
        },
    },
    defaultShowAttrs: ["user", "is_closed", "open_dt", "close_dt",]
};


