import axios, {AxiosResponse} from "axios";
import {authStore} from "store/AuthStore";
import {Constants} from "utils/constants";

export enum HttpMethod {
    GET = "get",
    POST = "post",
    PUT = "put",
    DELETE = "delete",
}


export function ApiRequest<T>(method: HttpMethod, url: string, params?: any): Promise<T> {

    if (method === HttpMethod.GET) {
        return axios.get<T>(Constants.API_URL + url, {params})
            .then((response) => response.data)
            .catch((error) => {
                GlobalOnError(error);
                throw error
            })
    } else if (method === HttpMethod.POST) {
        return axios.post(Constants.API_URL + url, params)
            .then((response) => response.data)
            .catch((error) => {
                GlobalOnError(error);
                throw error
            });
    } else {
        return axios[method]<T>(Constants.API_URL + url, {params})
            .then((response) => response.data)
            .catch((error) => {
                throw error
                //  throw new Error(error);
            });
    }

}


/*export function ApiRequest1<T>(method: HttpMethod, url: string, params?: any) {
    return async function (onResponse: (data: T) => void) {
        try {
            const response = await axios[method]<T>(Constants.API_URL + url, {params});
            GlobalOnResponse(response, onResponse);
        } catch (error) {
            GlobalOnError(error, onResponse);
        }
    };
}*/


export const GlobalOnResponse = (response: AxiosResponse<any>, onResponse: any) => {
    if (response.data !== '' && response.data.constructor === Object) {
        onResponse(response).onSuccess()
    } else {
        console.log('Error while fetching events data!')
        console.log(response.data)
        onResponse(response).onError()
    }
}

export const GlobalOnError = (error: any) => {
    if (error.response.status === 401) {
        //TODO CHECK TOKEN
        console.log("logout")
        authStore.logout()
        window.location.href = "/"
    }
    console.log(error.response.status)
}