import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Dropdown, NavItem, NavLink} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {authStore} from "../../../store/AuthStore";
import {uiStore} from "../../../store/UiStore";
import {Constants} from "../../../utils/constants";


const UserDropdown = () => {
    // const user = useSelector((state: any) => state.auth.currentUser);
    const navigate = useNavigate();

    const CustomToggle = React.forwardRef<any, any>(({children, onClick}, ref) => (
        <NavItem>
            <NavLink ref={ref} onClick={(e: any) => {
                e.preventDefault();
                onClick(e);
            }}>
                <div className="user-panel d-flex">
                    {
                        (uiStore.screenSize === 'lg') &&
                        <div className="info">
                            <b>{authStore.profile?.name ?? "%USER%"}</b>
                            {/*    <small>{authStore.profile?.role?.name}</small>*/}
                        </div>
                    }

                    <div className="image">
                        <img
                            src={(authStore.profile?.image_name)
                                ? Constants.publicImageViewPath + "users/400/" + authStore.profile?.image_name
                                : Constants.publicImageViewPath + "noAvatar.png"
                            }
                            className="img-circle elevation-2" alt="Ava"/>
                    </div>
                </div>
            </NavLink>
        </NavItem>
    ));

    const logout = () => {
        authStore.logout();
        navigate("/login")
    }
    return (
        <>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" as={CustomToggle} menuVariant="dark"/>
                <Dropdown.Menu>
                    <Dropdown.Item disabled={true}>
                        <p>{authStore.profile?.name ?? "%USER%"} <br/>
                            <small>{authStore.profile?.role?.name}</small>
                        </p>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/profile")}>
                        <FontAwesomeIcon icon={solid("user")} className="mr-2"/>
                        <span>Профиль</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logout}>
                        <FontAwesomeIcon icon={solid("arrow-right-from-bracket")} className="mr-2"/>
                        <span>Выход</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>)
};


export default UserDropdown;
