import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import React from "react";

export function BaseAcceptModal({show, onClose, onYes, title, text}: {
    show: boolean,
    onClose: any,
    onYes: any,
    title: string,
    text: any
}) {
    return <Modal show={show} onHide={onClose}>
        <Modal.Header>
            <h5 className="modal-title">{title}</h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <p>{text}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>Отмена</Button>
            <button type="button" className="btn btn-danger" onClick={onYes}>Да!</button>
        </Modal.Footer>
    </Modal>
}