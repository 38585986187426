import React from 'react';
import {Form} from "react-bootstrap";
import {uiStore} from "../../../store/UiStore";
import Select from 'react-select';
import {
    NAVBAR_DARK_VARIANTS,
    NAVBAR_LIGHT_VARIANTS,
    SIDEBAR_DARK_SKINS,
    SIDEBAR_LIGHT_SKINS
} from "adminLTE/utils/themes";
import {observer} from "mobx-react-lite";

const AppearanceTab = observer(() => {
    return (
        <div>

            <h5>Customize AdminLTE</h5>
            <hr className="mb-2"/>

            <div style={{padding: '8px 0'}}>
                <div className="mb-4">
                    <Form.Check type="switch" id="darkMode"
                                checked={uiStore.darkMode}
                                onChange={uiStore.toggleDarkMode}
                                label="Dark mode"/>

                    <Form.Check type="switch" id="layoutBoxed"
                                checked={uiStore.layoutBoxed}
                                onChange={uiStore.toggleLayoutBoxed}
                                label="Boxed (Broken when header or footer is fixed)"/>

                </div>

                <h6>Header Options</h6>

                <div className="mb-4">
                    <Form.Check type="switch" id="privateHeaderFixed"
                                checked={uiStore.privateHeaderFixed}
                                onChange={uiStore.togglePrivateHeaderFixed}
                                label="Fixed"
                    />

                    <Form.Check type="switch" id="privateHeaderBorder"
                                checked={uiStore.privateHeaderBorder}
                                onChange={uiStore.togglePrivateHeaderBorder}
                                label="No Border"
                    />

                </div>

                <h6>Sidebar Options</h6>

                <div className="mb-4">
                    <Form.Check type="switch" id="menuSidebarCollapsed"
                                checked={uiStore.menuSidebarCollapsed}
                                onChange={uiStore.toggleMenuSidebarCollapsed}
                                label="Collapse"
                    />

                    <Form.Check type="switch" id="layoutFixed"
                                checked={uiStore.layoutFixed}
                                onChange={uiStore.toggleLayoutFixed}
                                label="Fixed"
                    />

                    <Form.Check type="switch" id="menuItemFlat"
                                checked={uiStore.menuItemFlat}
                                onChange={uiStore.toggleMenuItemFlat}
                                label="Nav Flat Style"
                    />

                    <Form.Check type="switch" id="menuChildIndent"
                                checked={uiStore.menuChildIndent}
                                onChange={uiStore.toggleMenuChildIndent}
                                label="Nav Child Indent"
                    />

                </div>

                <h6>Footer Options</h6>

                <div className="mb-4">
                    <Form.Check type="switch" id="footerFixed"
                                checked={uiStore.footerFixed}
                                onChange={uiStore.toggleFooterFixed}
                                label="Fixed"
                    />

                </div>

                <p>
                    <label>
                        Light Navbar Variants
                        <Select
                            onChange={(v) => (uiStore.navbarVariant = v?.value ?? "")}
                            options={NAVBAR_LIGHT_VARIANTS}
                        />
                    </label>

                    <label>
                        Dark Navbar Variants
                        <Select
                            onChange={(v) => (uiStore.navbarVariant = v?.value ?? "")}
                            options={NAVBAR_DARK_VARIANTS}
                        />
                    </label>
                </p>
                <p>
                    <label>
                        Light Sidebar Variants
                        <Select
                            onChange={(v) => (uiStore.sidebarSkin = v?.value ?? "")}
                            options={SIDEBAR_LIGHT_SKINS}
                        />
                    </label>
                    <label>
                        Dark Sidebar Variants
                        <Select
                            onChange={(v) => (uiStore.sidebarSkin = v?.value ?? "")}
                            options={SIDEBAR_DARK_SKINS}
                        />
                    </label>
                </p>

            </div>

        </div>
    );
});

export default AppearanceTab;
