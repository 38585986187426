import {PaymentType} from "./PaymentType";


export const PaymentTypeValues: { [key in string]: PaymentType } = {
    "reseted": -1,
    "cash": 0,
    "card": 1,
    "deposit": 2,
    "qr": 3,
    "qr_spb": 4,
    "telegram": 5,
};
