/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseCreatePage from "crud/base/BaseCreatePage";
import TableGamesImgsForm from "./_form";
import {TableGamesImgsModel} from "./_model";


class TableGamesImgsCreate extends BaseCreatePage {
    //public model = TableGamesImgsModel;
    //public FormComponent = TableGamesImgsForm;

    constructor(props: any) {
        super(props);
        super.model = TableGamesImgsModel;
        super.FormComponent = TableGamesImgsForm;
        super.relationTree = TableGamesImgsModel.relationTree
    }
}

export default TableGamesImgsCreate;