import React, {useEffect} from 'react';
import PriceWidget from "../widgets/PriceWidget";
import {checkoutStore} from "../../../api/store/CheckoutStore";
import {observer} from "mobx-react-lite";
import moment from "moment/moment";
import UserProfileView from "../../users/UserProfileView";

type Props = {
    //newCheckpoint?: CheckpointUser
}
export const KassaOnlineTable = observer((props: Props) => {
    //const [checkpoints, setCheckpoints] = useState<Array<CheckpointUser>>([]);
    //const [currentCheckpoint, setCurrentCheckpoint] = useState<CheckpointUser | null>(null);
    //checkoutStore.checkpoints
    /*
        useEffect(() => {
            if (props.newCheckpoint != null) {
                getData()
                setCurrentCheckpoint(props.newCheckpoint)
            }
        }, [props]);
    */

    useEffect(() => {
        checkoutStore.getData();
        setInterval(async () => {
            checkoutStore.getData()
        }, 10000);
        // return () => clearInterval(interval);
    }, []);


    /*    const getOnlineCount = () => {
            return checkpoints.filter((e) => {
                return (e.is_inside && !e.is_outside)
            }).length
        }

        const getOutsideCount = () => {
            return checkpoints.filter((e) => {
                return (e.is_outside)
            }).length
        }*/


    const renderUsers = () => {
        return checkoutStore.checkpoints.map((checkpoint, index) => {
            //console.log(checkpoint.created_at)
            return (
                <tr key={index}
                    onClick={(e) => {
                        checkoutStore.addCurrentCheckpoint(checkpoint);
                        checkoutStore.reCalcCurIndex();
                    }}>
                    {/*<td>{checkpoint.user?.name}</td>*/}
                    <td><UserProfileView id={checkpoint?.user?.id} name={checkpoint.user?.name}
                                         image_name={checkpoint.user?.image_name}
                                         role={checkpoint?.user?.role?.name}/>
                    </td>
                    {/*   <td>Сегодня, <Moment interval={0} format="hh:mm" date={checkpoint.created_at}/></td>*/}
                    <td>{moment(checkpoint.created_at + "Z").calendar()}</td>
                    <td>{checkpoint.minutes}мин</td>
                    {/*<td><Moment date={checkpoint.created_at} format="hч mмин" trim durationFromNow/></td>*/}
                    <td>{checkpoint.user?.role?.name}</td>
                    <td>{checkpoint.tarif?.name}</td>
                    <td><PriceWidget value={checkpoint?.sum ?? 0.0} tarif={checkpoint.tarif}/></td>
                    {/*   <td><PriceCalc tarif={checkpoint.tarif} checkInStamp={checkpoint.created_at}/></td>*/}
                    {/*   <td>{getCheckpointStatus(checkpoint)} {getPayStatus(checkpoint.is_payed)}</td>*/}
                </tr>
            )
        })
    }

    return (
        <div className="table-responsive-sm">
            <table className="table table-striped table-hover">
                <thead>
                <tr>
                    <th>Посетитель</th>
                    <th>Пришел в</th>
                    <th>Провел времени</th>
                    <th>Роль</th>
                    <th>Тариф</th>
                    <th>Сумма</th>
                    {/*   <th>Статус</th>*/}
                </tr>
                </thead>
                <tbody>
                {
                    renderUsers()
                }
                </tbody>
            </table>
        </div>
    )

})
