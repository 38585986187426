/**
 * Generated by ReactYii 3.0.27 at 23.11.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {UserTurnsModel} from "./_model";
import {Pagination} from "../../crud/types/Pagination";

class UserTurnsIndex extends BaseIndexPage {
    //public model = UserTurnsModel;

    constructor(props: any) {
        super(props);
        super.model = UserTurnsModel;
        super.defaultShowAttrs = UserTurnsModel.defaultShowAttrs;
        super.relationTree = {
            relation: {
                user: [],
            },
            relations: [
                "userCheckpoints",
            ],
        };
        super.state = {
            data: {data: [], pagination: new Pagination()},
            isLoadedFirstPage: false,
            hasError: false,
            hasMore: true,
            sortBy: "-id", //id,-id, someField
            searchArray: {},//{"user_id": props?.profile?.id},
            showAttr: {},
            options: null,
        };
    }
}

export default UserTurnsIndex;