/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import {Route} from "react-router-dom";
import {EventsModel} from "./_model";

import {lazy, Suspense} from "react";

const EventsCreate = lazy(() => import("./create"));
const EventsUpdate = lazy(() => import("./update"));
const EventsView = lazy(() => import("./view"));
const EventsIndex = lazy(() => import("./index"));

const EventsRoutes = [
    <>
        <Route path={EventsModel.info.viewPath} element={<Suspense><EventsIndex/></Suspense>}/>
        <Route path={EventsModel.info.viewPath + '/create'} element={<Suspense><EventsCreate/></Suspense>}/>
        <Route path={EventsModel.info.viewPath + '/update/:id'} element={<Suspense><EventsUpdate/></Suspense>}/>
        <Route path={EventsModel.info.viewPath + '/view/:id'} element={<Suspense><EventsView/></Suspense>}/>
    </>
];
export default EventsRoutes;
