/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import BaseIndexPage from "crud/base/BaseIndexPage";
import {WikiCategoriesModel} from "./_model";

class WikiCategoriesIndex extends BaseIndexPage {
    //public model = WikiCategoriesModel;

    constructor(props: any) {
        super(props);
        super.model = WikiCategoriesModel;
        super.defaultShowAttrs = WikiCategoriesModel.defaultShowAttrs;
        super.relationTree = WikiCategoriesModel.relationTree
    }
}

export default WikiCategoriesIndex;