import React from 'react'
import IndexDataLoader from '../../ui/loaders/IndexDataLoader'
import FieldViewer from "../functions/FieldViewer";
import {BaseModelInfo} from "../types/BaseModelInfo";

type Props = {
    data: any,
    model: BaseModelInfo,
    fields?: any,
    disabledFields?: any,
}

export default function DataTable(props: Props) {
    if (props?.data?.id > 0) {
        const rows: any = []
        Object.entries(props.model.attrs).forEach(function (attr2: any,) {
            let attrName = attr2[0];

            if (props.disabledFields.includes(attrName)) {
                return;
            }

            rows.push(
                <tr key={attrName}>
                    <th>{props.model.attrs[attrName].label}</th>
                    <td>
                        <FieldViewer
                            attrName={attrName}
                            attr={attr2[1]}
                            //value={props.data[attrName]}
                            value={
                                //if set sourceAttr, use it
                                (props.model.attrs[attrName].sourceAttr)
                                    ? props.data[props.model.attrs[attrName].sourceAttr!]
                                    //else if sourceAttr is NULL, send full model
                                    : (props.model.attrs[attrName].sourceAttr === null)
                                        ? props.data
                                        // else just send current model.attr
                                        : props.data[attrName]
                            }
                            model={props.model}
                            params={{modelId: props.data.id}}/>
                    </td>
                </tr>
            )


            /*           if (props.hasOwnProperty('fields')) {
                           if (props.fields.includes(attrName)) {
                               rows.push(
                                   <tr key={attrName}>
                                       <th>{props.model.attrs[attrName].label}</th>
                                       <td>
                                           <FieldViewer
                                               attrName={attrName}
                                               attr={attr2[1]}
                                               value={props.data[attrName]}
                                               model={props.model}
                                               params={{modelId: props.data.id}}/>
                                       </td>
                                   </tr>
                               )
                           }
                       } else {
                           console.log(attrName, props.data[attrName])
                           rows.push(
                               <tr key={attrName}>
                                   <th>{props.model.attrs[attrName]?.label}</th>
                                   <td>
                                       <FieldViewer attrName={attrName}
                                                    attr={attr2[1]}
                                                    value={props.data[attrName]}
                                                    model={props.model}
                                                    params={{modelId: props.data.id}}
                                       />
                                   </td>
                               </tr>
                           )
                       }*/
        })

        return (
            <div className="table-responsive-sm">
                <table className='table table-striped table-sm'>
                    <tbody>{rows}</tbody>
                </table>
            </div>
        )
    } else {
        return <IndexDataLoader/>
    }

}


