/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TarifsModel} from "./_model";

class TarifsIndex extends BaseIndexPage {
    //public model = TarifsModel;

    constructor(props: any) {
        super(props);
        super.model = TarifsModel;
        super.defaultShowAttrs = TarifsModel.defaultShowAttrs;
        super.relationTree = TarifsModel.relationTree
    }
}

export default TarifsIndex;