/**
 * Generated by ReactYii 3.0.27 at 02.10.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import UserTableGameEventParticipantsForm from "./_form";
import {UserTableGameEventParticipantsModel} from "./_model";


class UserTableGameEventParticipantsCreate extends BaseCreatePage {
    //public model = UserTableGameEventParticipantsModel;
    //public FormComponent = UserTableGameEventParticipantsForm;

    constructor(props: any) {
        super(props);
        super.model = UserTableGameEventParticipantsModel;
        super.FormComponent = UserTableGameEventParticipantsForm;
        super.relationTree = UserTableGameEventParticipantsModel.relationTree
    }
}

export default UserTableGameEventParticipantsCreate;