import {Button, Card, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSignature} from "@fortawesome/free-solid-svg-icons";
import MomentViewer from "../../../../crud/field_viewers/MomentViewer";
import React, {useState} from "react";
import {User} from "../../../../models/users/User";
import Modal from "react-bootstrap/Modal";
import {ApiRequest, HttpMethod} from "../../../../api/api";
import stringify from "qs-stringify";
import {toast} from "react-toastify";

type Props = {
    profile?: User
}

export default function LoginsAccount(props: Props) {
    const [loginId, setLoginId] = useState<number | null>(null);
    const [userName, setUserName] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [createModal, setCreateModal] = useState<boolean>(false);

    const createLogin = () => {
        const data = {
            user_id: props?.profile?.id,
            username: userName,
            password: password
        }
        //alert(data)
        console.log(data)
        ApiRequest<any>(HttpMethod.POST, "/rapi/users/create-login", stringify(data))
            .then((resp: any) => {
                console.log(resp);
                if (resp.data) {
                    setLoginId(null);
                    toast.info("Успешно!")
                } else {
                    toast.error("Не получилось :(")
                }
            })
            .catch(() => toast.error("Не получилось :("))
    }

    const changePassword = () => {
        const data = {
            id: loginId,
            user_id: props?.profile?.id,
            password: password
        }
        ApiRequest<any>(HttpMethod.POST, "/rapi/users/update-password-login", stringify(data))
            .then((resp: any) => {
                console.log(resp);
                if (resp.data) {
                    setLoginId(null);
                    toast.info("Пароль установлен")
                } else {
                    toast.error("Пароль не установлен")
                }
            });
    }

    return (
        <>
            <form onSubmit={() => createLogin()}>
                <Modal show={createModal} onHide={() => setCreateModal(false)}>
                    <Modal.Header>
                        <h5 className="modal-title">Создание логина</h5>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <InputGroup
                            className={'form-group field-newLoginName'}>
                            <InputGroup.Prepend>
                                <InputGroup.Text className={'control-label '}>Логин</InputGroup.Text>
                            </InputGroup.Prepend>
                            <input
                                id="newLoginName"
                                className={'form-control '}
                                name="newLoginName"
                                required={true}
                                placeholder={"Л0ГиH"}
                                onChange={(event) => setUserName(event?.target?.value)}
                            />
                        </InputGroup>

                        <InputGroup
                            className={'form-group field-newLoginPass'}>
                            <InputGroup.Prepend>
                                <InputGroup.Text className={'control-label '}>Новый пароль</InputGroup.Text>
                            </InputGroup.Prepend>
                            <input
                                id="newLoginPass"
                                className={'form-control '}
                                name="newLoginPass"
                                required={true}
                                min={5}
                                type="password"
                                placeholder={"Новый пароль"}
                                onChange={(event) => setPassword(event?.target?.value)}
                            />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setCreateModal(false)}>Отмена</Button>
                        <Button type="submit" className="btn btn-danger" onClick={createLogin}>Создать логин</Button>
                    </Modal.Footer>
                </Modal>
            </form>


            <form onSubmit={() => changePassword()}>
                <Modal show={loginId != null} onHide={() => setLoginId(null)}>
                    <Modal.Header>
                        <h5 className="modal-title">Назначение пароля</h5>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <p>Старый пароль будет перезаписан без уведомления пользователя</p>

                        <InputGroup
                            className={'form-group field-newLoginPass'}>
                            <InputGroup.Prepend>
                                <InputGroup.Text className={'control-label '}>Новый пароль</InputGroup.Text>
                            </InputGroup.Prepend>
                            <input
                                id="newLoginPass"
                                className={'form-control '}
                                name="newLoginPass"
                                required={true}
                                type="password"
                                //disabled={props.disabled}
                                //placeholder={props.placeholder}
                                //value={props.value || ''}
                                onSubmit={() => changePassword()}
                                onChange={(event) => setPassword(event?.target?.value)}
                            />

                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setLoginId(null)}>Отмена</Button>
                        <button type="submit" className="btn btn-danger" onClick={changePassword}>Задать пароль</button>
                    </Modal.Footer>
                </Modal>
            </form>
            <Card>
                <Card.Header>
                    <Card.Title><FontAwesomeIcon icon={faSignature} className="pr-1"/>Login's</Card.Title>
                    <button className="float-right btn btn-sm btn-outline-success" onClick={() => setCreateModal(true)}>
                        <FontAwesomeIcon icon={faPlus}/>
                    </button>
                </Card.Header>
                {
                    (props.profile?.userAuthLogins?.length >= 1) &&
                    <Card.Body>
                        {props.profile?.userAuthLogins.map((login: any, i: number) => (
                            <Card className="bg-info" key={i}>
                                <Card.Body>
                                    <p>#{login.id} @{login.username}</p>
                                    <p>Status {login.login_status}</p>
                                    <p>updated_at <MomentViewer value={login.updated_at}/></p>
                                    <button className="btn btn-dark" onClick={() => setLoginId(login.id)}>Задать
                                        пароль
                                    </button>
                                </Card.Body>
                            </Card>
                        ))}
                    </Card.Body>
                }
            </Card>
        </>
    )
}