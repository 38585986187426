/**
 * Generated by ReactYii 3.0.27 at 22.11.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import WssLogForm from "./_form";
import {WssLogModel} from "./_model";

class WssLogUpdate extends BaseUpdatePage {
    //public model = WssLogModel;
    //public FormComponent = WssLogForm;

    constructor(props: any) {
        super(props);
        super.model = WssLogModel;
        super.FormComponent = WssLogForm;
        super.relationTree = WssLogModel.relationTree
    }
}

export default withTranslation()(withRouter(WssLogUpdate));
