/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const TableGamesTypesModel: BaseModelInfo = {
    info: {
        name: "table-games-types",
        label: "Table Games types",
        apiPath: "/rapi/table-games-types",
        viewPath: "/table-games/table-games-types"
    },
    attrs: {
        id: {label: "ID"},
        name: {label: "Name"},
        descr: {label: "Descr"},
        tableGamesToTypes: {
            label: '"TableGamesToTypes"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
    },
    relationTree: {},
    defaultShowAttrs: ["name", "descr",]
};


