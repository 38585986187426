/**
 * Generated by ReactYii 3.0.27 at 02.10.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import UserTableGameEventsForm from "./_form";
import {UserTableGameEventsModel} from "./_model";


class UserTableGameEventsCreate extends BaseCreatePage {
    //public model = UserTableGameEventsModel;
    //public FormComponent = UserTableGameEventsForm;

    constructor(props: any) {
        super(props);
        super.model = UserTableGameEventsModel;
        super.FormComponent = UserTableGameEventsForm;
        super.relationTree = UserTableGameEventsModel.relationTree
    }
}

export default UserTableGameEventsCreate;