/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import UserCheckpointsCreate from './create';
import UserCheckpointsUpdate from './update';
import UserCheckpointsView from './view';
import UserCheckpointsIndex from './index';
import {UserCheckpointsModel} from "./_model";

const UserCheckpointsRoutes = [
    <>
        <Route path={UserCheckpointsModel.info.viewPath} element={<UserCheckpointsIndex/>}/>
        <Route path={UserCheckpointsModel.info.viewPath + '/create'} element={<UserCheckpointsCreate/>}/>
        <Route path={UserCheckpointsModel.info.viewPath + '/update/:id'} element={<UserCheckpointsUpdate/>}/>
        <Route path={UserCheckpointsModel.info.viewPath + '/view/:id'} element={<UserCheckpointsView/>}/>
    </>
];
export default UserCheckpointsRoutes;
