import React, {useState} from "react";
import 'react-alice-carousel/lib/alice-carousel.css';
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import DndImages from "./DndImages";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";

type Props = {
    images: any,
    publicImageViewPath: string,
    onUpload: (formData: FormData) => void;
    onDelete: (image: any) => boolean;

}

export default function ImagesCrud(props: Props) {

    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [deleteModal, setDeleteModal] = useState<number | null>(null);


    const SliderItems = () => props?.images?.map((image: any) => (
        <div key={image.id}>
            <div className='img-thumbnail m-1 text-center'>
                <Row className="align-items-stretch">
                    <Col>
                        <p style={{
                            overflow: "auto",
                            textOverflow: "ellipsis",
                            wordWrap: "normal",
                        }}>{image.image_name}</p>
                    </Col>
                    <Col>
                        <button className="btn btn-outline-info" onClick={() => setSelectedImage(image.image_name)}>
                            <FontAwesomeIcon icon={faEye}/>
                        </button>

                        <button className="btn btn-outline-danger ml-1" onClick={() => setDeleteModal(image.id)}>
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </button>

                    </Col>
                </Row>
                <img alt={image.name}
                     style={{pointerEvents: "none"}}
                     className='img-fluid'
                     src={`${props.publicImageViewPath}/400/${image?.image_name}`}
                />
            </div>
        </div>
    ));

    return (
        <>
            <Modal size={"lg"} show={selectedImage != null} onHide={() => setSelectedImage(null)}>
                <img src={props.publicImageViewPath + selectedImage} alt={"selectedImage"}/>
            </Modal>

            <Modal show={deleteModal != null} onHide={() => setDeleteModal(null)}>
                <Modal.Body>
                    <p>Точно удалить #{deleteModal} ???!!</p>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            if (props.onDelete(deleteModal!)) {
                                setDeleteModal(null)
                            }
                        }}>
                        УНИЧТОЖИТЬ!
                    </button>
                </Modal.Body>

            </Modal>
            <Row>
                <Col md={8}>
                    <Slider
                        infinite={false}
                        dots={true}
                        draggable={true}
                        slidesToShow={3}>
                        {SliderItems()}
                    </Slider>

                </Col>
                <Col md={4}>
                    <DndImages onDrop={(formData) => props.onUpload(formData)}/>
                </Col>
            </Row>
        </>
    );
}