/**
 * Generated by ReactYii 3.0.27 at 02.10.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {UserTableGameEventParticipantsModel} from "./_model";

class UserTableGameEventParticipantsView extends BaseViewPage {
    //public model = UserTableGameEventParticipantsModel;

    constructor(props: any) {
        super(props);
        super.model = UserTableGameEventParticipantsModel;
        super.relationTree = UserTableGameEventParticipantsModel.relationTree
    }
}

export default withRouter(UserTableGameEventParticipantsView);