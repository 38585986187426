import React from 'react';
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import {faAt, faPlus} from "@fortawesome/free-solid-svg-icons";
import {User} from "../../../../models/users/User";
import LoginsAccount from "./loginsAccount";

type Props = {
    profile?: User
}
const AccountTab = (props: Props) => {
    return (
        <div>

            <LoginsAccount profile={props.profile}/>
            <Card>
                <Card.Header>
                    <Card.Title><FontAwesomeIcon icon={faAt} className="pr-1"/>E-mail's</Card.Title>
                    <button className="float-right btn btn-sm btn-outline-success">
                        <FontAwesomeIcon icon={faPlus}/>
                    </button>
                </Card.Header>
                {
                    (props.profile?.userAuthEmails?.length >= 1) &&
                    <Card.Body>
                        {props.profile?.userAuthEmails.toString()}
                    </Card.Body>
                }
            </Card>
            <Card>
                <Card.Header>
                    <Card.Title><FontAwesomeIcon icon={faTelegram} className="pr-1"/>Telegram</Card.Title>
                    <button className="float-right btn btn-sm btn-outline-success">
                        <FontAwesomeIcon icon={faPlus}/>
                    </button>
                </Card.Header>
                {
                    (props.profile?.userAuthTelegrams?.length >= 1) &&
                    <Card.Body>
                        {props.profile?.userAuthTelegrams.map((telegram: any) => (
                            <Card className="bg-gray">
                                <Card.Body>
                                    <p>#{telegram.id} ChatID: {telegram.telegram_chat_id}</p>
                                    <p>Status {telegram.login_status}</p>
                                    <p>phone {telegram.phone}</p>
                                    <p>telegram_status {telegram.telegram_status}</p>
                                    <p>created_at {telegram.created_at}</p>
                                    <p>updated_at {telegram.updated_at}</p>
                                </Card.Body>
                            </Card>
                        ))}
                    </Card.Body>
                }
            </Card>

        </div>
    );
};

export default AccountTab;
