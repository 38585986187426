/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import UsersForm from "./_form";
import {UsersModel} from "./_model";

class UsersUpdate extends BaseUpdatePage {
    //public model = UsersModel;
    //public FormComponent = UsersForm;

    constructor(props: any) {
        super(props);
        super.model = UsersModel;
        super.FormComponent = UsersForm;
        super.relationTree = UsersModel.relationTree
    }
}

export default withTranslation()(withRouter(UsersUpdate));
