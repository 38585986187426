import {makeAutoObservable, observable} from 'mobx'

export class BreadcrumbsStore {

    constructor() {
        makeAutoObservable(this);
    }

    @observable private _items: any[] = [];

    get items(): any[] {
        return this._items;
    }

    set items(value: any[]) {
        this._items = value;
    }

}

export const breadcrumbsStore = new BreadcrumbsStore()