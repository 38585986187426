/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {TableGamesImgsModel} from "./_model";

class TableGamesImgsView extends BaseViewPage {
    //public model = TableGamesImgsModel;

    constructor(props: any) {
        super(props);
        super.model = TableGamesImgsModel;
        super.relationTree = TableGamesImgsModel.relationTree
    }
}

export default withRouter(TableGamesImgsView);