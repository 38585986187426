/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseIndexPage from "crud/base/BaseIndexPage";
import {UserCheckpointsModel} from "./_model";
import ErrorWidget from "../../crud/components/ErrorWidget";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import TableIndex from "../../crud/TableIndex/TableIndex";
import MainLoader from "../../ui/loaders/MainLoader";
import React from "react";

class UserCheckpointsIndex extends BaseIndexPage {
    //public model = UserCheckpointsModel;

    constructor(props: any) {
        super(props);
        super.model = UserCheckpointsModel;
        super.defaultShowAttrs = UserCheckpointsModel.defaultShowAttrs;
        super.relationTree = UserCheckpointsModel.relationTree
    }


    render() {
        if (this.state.hasError) {
            return <ErrorWidget/>;
        }

        return (
            <Card className="card-outline card-success">
                <Card.Header>
                    <Card.Title>{this.model.info.label}</Card.Title>
                    <div className="card-tools">
                        <Link className="btn-outline-success btn btn-sm" to={'create'}>Create</Link>
                    </div>
                </Card.Header>

                <Card.Body className="p-0">
                    {this.state.isLoadedFirstPage ? (
                        <>
                            <TableIndex
                                model={this.model}
                                data={this.state.data.data}
                                enabledAttributes={this.defaultShowAttrs}
                                actions={{update: {title: "update"}}}
                                pagination={{data: this.state.data.pagination, callback: this.actionPagination}}
                                sortBy={this.state.sortBy}
                                onSortChange={this.onSortChange}
                                searchQuery={this.state.searchArray}
                                onSearch={this.onSearch}
                                onRelationTreeChange={this.onTreeChange}
                            />
                        </>
                    ) : (
                        <MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>
                    )}
                </Card.Body>
            </Card>
        );
    }
}

export default UserCheckpointsIndex;