import React, {useEffect, useRef} from "react";
import QRCodeStyling from "qr-code-styling";

type Props = {
    value: any
    displayValue?: boolean
    mode?: "Numeric" | "Alphanumeric" | "Byte" | "Kanji"
}

export function BarCodeViewer2(props: Props) {
    const ref = useRef(null)

    useEffect(() => {
        //@ts-ignore
        qrCode.append(ref?.current);
    }, []);

    useEffect(() => {
        qrCode.update({
            data: props.value
        });
    }, [props.value]);


    const qrCode = new QRCodeStyling({
        width: 82,
        height: 82,
        data: props.value,
        qrOptions: {
            typeNumber: 6,
            //mode: "Alphanumeric",
            mode: props.mode ?? "Numeric",
            errorCorrectionLevel: "H",
        },
        dotsOptions: {
            //color: "#019915",
            color: "#2D6F36",
            //type: "classy"
            type: "rounded"
        },
        //backgroundOptions: {color: "#ffe4bd"},
        backgroundOptions: {color: "#ffffff"},
        //image: "https://zdver.com/favicon/android-icon-144x144.png",
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 0,
            //saveAsBlob: false,
            //hideBackgroundDots: false,
            imageSize: 0.3,
        }
    });

    /*
        qrCode.toBuffer("png").then(
            (code) => {
                if (event.callback) {
                    bot.answerCallbackQuery(event.callback.id, {
                        text: 'Ваш QR код',
                        show_alert: false
                    });
                }
                const fileOpts: any = {filename: 'image', contentType: 'image/png',};
                bot.sendPhoto(context.userId, code, fileOpts);
            }
        );*/

    //qrCode.append(document.getElementById("canvas"));
    //qrCode.download({ name: "qr", extension: "svg" });
    return (
        <div ref={ref}/>

    )
}