/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import React from 'react';
import {Translation} from "react-i18next";
import {DropDownList, InputField, SwitchField} from "crud";
import BaseForm from "crud/base/BaseForm";
import {EventCalendarsModel} from "./_model";
import {daysOfWeek} from "../events/schedule/daysOfWeek";
import DateTimeField from "../../../crud/FormInputs/DateTimeField";
import {SaveModel} from "../../../crud/api/crudApi";
import {toast} from "react-toastify";
import {format} from "date-fns";

type Props = {
    data: any
    action: string
    eventId?: any;
    id: number;
    onSubmit: (resp: any) => {};
}

class EventCalendarsForm extends BaseForm {
    //public model = EventCalendarsModel;

    constructor(props: Props) {
        super(props);
        console.log("props", props)
        super.model = EventCalendarsModel;
        this.state = {
            id: props.id ?? '',
            validation: '',
            redirect: false,
            form: {
                id: props.id ?? '',
                event_id: props.eventId ?? '',
                day_of_week: '0',
                repeatable: 0,
                from_dt: '',
                to_dt: '',
            },
        };

    }


    handleSubmit = (event: any) => {
        event.preventDefault();
        //let data = PrepareForm(this.modelName, this.state.form);
        let data = this.state.form;

        SaveModel(this.props.action, data)
            .then((resp: any) => {
                this.setState({validation: ''});
                this.setState({id: resp.data.id});
                //this.setState({redirect: true});

                // @ts-ignore
                this.props.onSubmit(resp);

                toast.success("Сохранено")
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
    }

    render() {
        /* if (this.state.redirect) {

             return <Navigate to={( "/table-games/events/view/" + this.props?.eventId) ?? "/table-games/events/"}/>;
         }*/
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>
                    {/*   <InputField name={'id'}
                                model={this.model.info.name}
                                label={this.model.attrs.id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.id}/>*/}

                    <InputField name={'event_id'}
                                model={this.model.info.name}
                                label={this.model.attrs.event_id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.event_id}/>


                    <DropDownList name={'day_of_week'}
                                  model={this.model.info.name}
                                  label={this.model.attrs?.day_of_week?.label}
                                  onChange={this.handleInputChange}
                                  value={this.state.form['day_of_week']}
                                  options={
                                      Object.entries(daysOfWeek).map(([value, label]) => ({value, label}))
                                  }/>

                    {/* <InputField name={'day_of_week'}
                                model={this.model.info.name}
                                label={this.model.attrs.day_of_week.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.day_of_week}/>*/}

                    <SwitchField name={'repeatable'}
                                 model={this.model.info.name}
                                 label={this.model.attrs.repeatable.label}
                                 onChange={(v: any) => {
                                     //let v = parseInt(value)
                                     console.log(v)
                                     this.handleInputChange({
                                         name: "repeatable",
                                         value: parseInt(v.value),
                                     })
                                     /*let form = this.state.form;
                                     form["repeatable"] = v;
                                     console.log("repeatable", form["repeatable"])
                                     this.setState({form: form});*/
                                     console.log("repeatable state", this.state.form.repeatable)
                                 }}
                                 value={this.state.form.repeatable}/>

                    <DateTimeField name={'from_dt'}
                                   dateFormat={"HH:mm"}
                                   model={this.model.info.name}
                                   label={this.model.attrs.from_dt.label}
                                   showTimeSelect={true}
                                   onChange={(val: any) => this.handleInputChange({
                                       name: "from_dt",
                                       //value: val.toISOString().slice(0, 19).replace('T', ' ')
                                       value: format(val, 'yyyy-MM-dd HH:mm:ss')
                                   })}
                                   value={this.state.form.from_dt}/>


                    {/*    <DatePicker
                        locale="ru"
                        placeholderText={"10:00"}
                        className="form-control"
                        wrapperClassName="form-control"
                        showTimeSelect={true}
                        //required={true}
                        //onSelect={(date) => onChangeValidate(date)}
                        dateFormat="HH:mm"
                        onChange={this.handleInputChange}
                        //selected={(props.value) ? new Date(props.value) : null}
                        //onChange={(date) => onChangeValidate(date)}
                    />*/}
                    {/*   <TimePicker value={"10:00"} onChange={this.handleInputChange} />*/}


                    <DateTimeField name={'to_dt'}
                                   dateFormat={"HH:mm"}
                                   model={this.model.info.name}
                                   label={this.model.attrs.to_dt.label}
                                   showTimeSelect={true}
                                   onChange={(val: any) => this.handleInputChange({
                                       name: "to_dt",
                                       //value: val.toISOString().slice(0, 19).replace('T', ' ')
                                       value: format(val, 'yyyy-MM-dd HH:mm:ss')
                                   })}
                                   value={this.state.form.to_dt}/>

                    {/*<InputField name={'to_dt'}
                                model={this.model.info.name}
                                label={this.model.attrs.to_dt.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.to_dt}/>
*/}

                    {/* <RelationOneDropDown name={'event_id'}
                            model={this.model.info.name}
                            label={this.model.attrs?.event_id?.label}
                            relationUrl={this.model.attrs.event.params.model?.info?.apiPath + "/index"}
                            onChange={(val: any) => this.handleInputChange({name: "event_id", value: val.id})}
                            value={this.state.form['event_id']}/>*/}


                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default EventCalendarsForm;
