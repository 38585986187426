import {Constants} from "utils/constants";
import axios from "axios";
import ImagesCrud from "modules/imagesCrud/ImagesCrud";
import React, {useEffect, useState} from "react";

type Props = {
    images: any
}
const ProfileImages = (props: Props) => {
    const ImagesCrudRelationTree = {relations: {tableGamesImgs: []}};

    const [images, setImages] = useState<any>();

    useEffect(() => setImages(props.images), [props]);

    const removeImage = (id: number): boolean => {
        let apiDeleteUrl = `${Constants.API_URL}/rapi/profile/delete-image?relationtree=${JSON.stringify(ImagesCrudRelationTree)}`
        axios.get(`${apiDeleteUrl}&id=${id}`).then((resp) => {
            // setDeleteModal(null);
            //return resp.data;
            setImages(resp.data.data.userImages)

            return true;
        }).catch((error) => {
            console.log(error);
            return false;
            //throw new Error(error.message);
        });
        return true;
    }

    const uploadImage = (formData: FormData) => {
        let apiUploadUrl = `${Constants.API_URL}/rapi/profile/add-image?relationtree=${JSON.stringify(ImagesCrudRelationTree)}`
        axios.post(apiUploadUrl, formData, {})
            .then((resp) => {
                console.log(resp.data.data);
                setImages(resp.data.data.userImages)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <ImagesCrud
            images={images}
            publicImageViewPath={Constants.publicImageViewPath + "users/"}
            onUpload={uploadImage}
            onDelete={removeImage}
        />
    )

}
export default ProfileImages;