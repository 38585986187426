/**
 * Generated by ReactYii 3.0.27 at 23.11.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import UserTurnsForm from "./_form";
import {UserTurnsModel} from "./_model";

class UserTurnsUpdate extends BaseUpdatePage {
    //public model = UserTurnsModel;
    //public FormComponent = UserTurnsForm;

    constructor(props: any) {
        super(props);
        super.model = UserTurnsModel;
        super.FormComponent = UserTurnsForm;
        super.relationTree = UserTurnsModel.relationTree
    }
}

export default withTranslation()(withRouter(UserTurnsUpdate));
