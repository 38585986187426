/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseCreatePage from "crud/base/BaseCreatePage";
import TarifsForm from "./_form";
import {TarifsModel} from "./_model";

class TarifsCreate extends BaseCreatePage {
    //public model = TarifsModel;
    //public FormComponent = TarifsForm;

    constructor(props: any) {
        super(props);
        super.model = TarifsModel;
        super.FormComponent = TarifsForm;
        super.relationTree = TarifsModel.relationTree
    }
}

export default TarifsCreate;