/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const TableGamesCoopsModel: BaseModelInfo = {
    info: {
        name: "table-games-coops",
        label: "Table Games Coops",
        apiPath: "/rapi/table-games-coops",
        viewPath: "/table-games/table-games-coops"
    },
    attrs: {
        id: {label: "ID"},
        name: {label: "Name"},
        decr: {label: "Decr"},
        tableGamesToCoops: {
            label: '"TableGamesToCoops"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
    },
    relationTree: {},
    defaultShowAttrs: ["name", "decr",]
};


