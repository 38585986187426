import {Component} from 'react'
import {prepareLabel, validate} from '../api/yiiValidation'

type Props = {
    name: string,
    model: string,
    label: boolean | string,
    class: string,
    placeholder: string,
    onChange: Function,
    required?: boolean,
    helpBlock?: string,
    validated?: any,
    pluginProps?: any,
    value: any,

};

interface State {
    helpBlock: string,
    validation: string | boolean,
}

class TextArea extends Component<Props, State> {
    private labelName: any;

    constructor(props: Props) {
        super(props)

        this.state = {helpBlock: '', validation: ''}
        this.labelName = prepareLabel(this.props.label, this.props.name);
        this.editApi = this.editApi.bind(this)
    }

    editApi = async (event: any) => {
        // this.setState({value: event.target.value});
        this.props.onChange(event)
        // console.log(event.target.value);

        if (this.props.model !== undefined && this.props.model !== '') {

            validate(this.props.model,
                this.props.name,
                event.target.value,
                () => {
                    this.setState({helpBlock: ''})
                    this.setState({validation: true})
                },
                (err: any) => {
                    this.setState({helpBlock: err})
                    this.setState({validation: false})
                });
        }
    }

    render() {
        let validated = (this.props.validated !== undefined) ? this.props.validated : this.state.validation;
        let required = (this.props.pluginProps !== undefined) ? (this.props.pluginProps.hasOwnProperty("required") ? 'required' : '') : '';

        return (
            <div
                className={
                    'form-group field-' +
                    this.props.model +
                    '-' +
                    this.props.name +
                    ' ' +
                    this.props.class
                }
            >
                <label
                    className={'control-label ' + required}
                    htmlFor={this.props.model + '-' + this.props.name}
                >
                    {this.labelName}
                </label>
                <textarea
                    {...this.props.pluginProps}
                    id={this.props.model + '-' + this.props.name}
                    className={
                        'form-control ' +
                        (validated !== ''
                            ? validated === true
                                ? 'is-valid'
                                : 'is-invalid'
                            : '')
                    }
                    //type='textarea'
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    rows='6'
                    onChange={this.editApi}
                    required={this.props.required}
                />
                <div
                    className={
                        validated !== ''
                            ? validated === true
                                ? 'valid-feedback'
                                : 'invalid-feedback'
                            : ''
                    }
                >
                    {(this.props.validated !== undefined) ? this.props.helpBlock : this.state.helpBlock}
                </div>
            </div>
        )
    }
}

export default TextArea
