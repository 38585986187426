/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import EventTypesCreate from './create';
import EventTypesUpdate from './update';
import EventTypesView from './view';
import EventTypesIndex from './index';
import {EventTypesModel} from "./_model";

const EventTypesRoutes = [
    <>
        <Route path={EventTypesModel.info.viewPath} element={<EventTypesIndex/>}/>
        <Route path={EventTypesModel.info.viewPath + '/create'} element={<EventTypesCreate/>}/>
        <Route path={EventTypesModel.info.viewPath + '/update/:id'} element={<EventTypesUpdate/>}/>
        <Route path={EventTypesModel.info.viewPath + '/view/:id'} element={<EventTypesView/>}/>
    </>
];
export default EventTypesRoutes;
