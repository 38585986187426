import React from "react";
import {BaseModelInfo} from "../types/BaseModelInfo";
import {Link} from "react-router-dom";
import FieldViewer from "../functions/FieldViewer";

type Props = {
    model: BaseModelInfo,
    enabledAttributes: string[],
    data: any,
    searchByField: (attr: string, value: string) => any,
    onCreateClick: () => void,
    onRowClick?: (data: any) => void,
}

export default function TableViewRows(props: Props) {
    //const navigate = useNavigate();

    const genRow = (data: any) => {
        let items: any = [];
        props.enabledAttributes.map((attr: string, i) =>
            (props.model.attrs.hasOwnProperty(attr))
                ? items.push(
                    <div className="d-table-cell divTd" key={i + data?.id + "-" + attr}>
                        {/* <FieldViewer attr={props.attributes[attr]} value={model[attr]}/>*/}
                        <FieldViewer
                            attrName={attr}
                            attr={props.model.attrs[attr]}
                            model={props.model}
                            value={
                                //if set sourceAttr, use it
                                (props.model.attrs[attr].sourceAttr)
                                    ? data[props.model.attrs[attr].sourceAttr!]
                                    //else if sourceAttr is NULL, send full model
                                    : (props.model.attrs[attr].sourceAttr === null)
                                        ? data
                                        // else just send current model.attr
                                        : data[attr]
                            }
                        />
                    </div>
                ) : console.log("ignore::", attr)
        )
        return items;
    }

    return props.data?.map((_data: any) => {
        let viewUrl = props.model.info.viewPath + "/view/" + _data.id;

        if (props.onRowClick) {
            //@ts-ignore
            return <a href="#" onClick={() => props.onRowClick(_data)} className="d-table-row divTr btn" key={_data.id}>
                {genRow(_data)}
            </a>
        } else {
            return <Link to={viewUrl} className="d-table-row divTr btn" key={_data.id}>
                {genRow(_data)}
                {
                    //TODO
                    /* (props.actions != null) &&
                     <td key={_data.id + "-actions"} className={"grid-item action-buttons"}>
                         <TableViewActions _data={_data} {...props}/>
                     </td>*/
                }
            </Link>
        }

        /* onClick={() => {
          if (props.onRowClick) {
              props.onRowClick(_data)
          } else {
              navigate(viewUrl)
          }
          }*/
    })
}