/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const EventCalendarsModel: BaseModelInfo = {
    info: {
        name: "event-calendars",
        label: "Event Calendars",
        apiPath: "/rapi/event-calendars",
        viewPath: "/table-games/event-calendars"
    },
    attrs: {
        id: {label: "ID"},
        event_id: {label: "Event ID"},
        day_of_week: {label: "День недели"},
        repeatable: {label: "Повторять?"},
        from_dt: {label: "Время начала"},
        to_dt: {label: "Время окончания"},
        event: {label: '"Event"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },
    relationTree: {},
    defaultShowAttrs: ["event_id", "day_of_week", "repeatable", "from_dt", "to_dt",]
};


