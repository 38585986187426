import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, Card, Row} from "react-bootstrap";
import {KassaCloseTurns, KassaGetCurrentTurns} from "../../../api/kassa/kassa";
import {toast} from "react-toastify";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";
import {BaseAcceptModal} from "../../../crud/components/AcceptModal";
import MomentViewer from "../../../crud/field_viewers/MomentViewer";

type Props = {
    //newCheckpoint?: CheckpointUser
}
export const KassaTurns = observer((props: Props) => {
    //const [checkpoints, setCheckpoints] = useState<Array<CheckpointUser>>([]);
    //const [currentCheckpoint, setCurrentCheckpoint] = useState<CheckpointUser | null>(null);
    //checkoutStore.checkpoints
    const navigate = useNavigate();

    const [currentTurns, setCurrentTurns] = useState<any>();
    const [showCloseTurnsModal, setShowCloseTurnsModal] = useState<boolean>(false);

    useEffect(() => {
        KassaGetCurrentTurns()
            .then((resp: any) => {
                console.log("KassaGetCurrentTurns", resp.data)
                setCurrentTurns(resp.data);
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed get Turns'));

        //console.log(currentTurns)
        moment.locale("ru");
    }, []);

    return (
        <div className="table-responsive-sm">
            <BaseAcceptModal
                onClose={() => setShowCloseTurnsModal(false)}
                onYes={() => {
                    KassaCloseTurns(currentTurns.id).then(resp => {
                        console.log(resp)
                        setShowCloseTurnsModal(false);
                        navigate("/user-turns/view/" + currentTurns.id)
                    })
                }}
                show={showCloseTurnsModal}
                title="Закрыть смену?"
                text={
                    <p>
                        <span className="pl-1">Смена длилась {moment(currentTurns?.open_dt + "Z").hours()} ч</span><br/>
                        Уже около 9 утра и ты точно хочешь закрыть смену?! <br/>
                        "Будет сделан отчет по этой смене, и открыта новая" <br/>
                        "Не выписанные пользователи будут перенесены в новую смену" <br/>
                    </p>
                }
            />
            <Card>
                <Card.Body>
                    <Row>
                        <h2>Текущая смена #{currentTurns?.id}</h2>
                        <span className="pl-1">Открыта <MomentViewer value={currentTurns?.open_dt}/></span>
                    </Row>
                    <Button onClick={() => setShowCloseTurnsModal(true)}>Закрыть смену</Button>
                    <br/>
                    <br/>
                    <Button onClick={() => navigate("/user-turns/view/" + currentTurns.id)}>Посмотреть отчет</Button>
                </Card.Body>
            </Card>
        </div>
    )

})
