import React, {useEffect, useState} from 'react';
import {Translation, useTranslation} from 'react-i18next';
import SettingsTab from './tabs/SettingsTab';
import AppearanceTab from './tabs/AppearanceTab';
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";
import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {toast} from "react-toastify";
import getProfile from "../../api/profile/Profile";
import AccountTab from "./tabs/AccountTab/AccountTab";
import UserBox from "./userBox";
import {User} from "../../models/users/User";
import CheckpointTab from "./tabs/CheckpointTab";
import {authStore} from "../../store/AuthStore";
import InfoTab from "./tabs/InfoTab/InfoTab";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

const Profile = () => {
    const [t] = useTranslation();
    const [profile, setProfile] = useState<User>();

    const relationTree = {
        relation: [
            "role"
        ],
        relations: {
            userCheckpoints: {
                relations: [
                    "tarif",
                ]
            },
            userImages: [],
            userAuthLogins: [],
            userAuthEmails: [],
            userAuthTelegrams: [],
            /* "userCheckpoints",
             "userImages",
             "userAuthLogins",
             "userAuthEmails",
             "userAuthTelegrams",*/
        },
        counts: [
            //"userCheckpoints",
        ]
    }

    useEffect(() => {
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
            {name: <Translation>{(t: any) => t('user.profile')}</Translation>},
        ];
        getProfile(relationTree)
            .then((resp: any) => {
                console.log(resp)
                authStore.profile = resp.data
                setProfile(resp.data)
            })
            .catch((error) => toast.error(error.toString() || 'Failed'));

    }, []);

    return (
        <Container fluid={true}>
            <Row>
                <Col md={3}>
                    <UserBox profile={profile}/>
                </Col>
                <Col md={9}>

                    <Tabs defaultActiveKey="InfoTab" className="mb-3 card-header bg-light" fill variant="pills">
                        {
                            /*  <Tab eventKey="activity" title={t('main.label.activity')}>
                              <ActivityTab/>
                          </Tab>
                          <Tab eventKey="timeline" title={t('main.label.timeline')}>
                              <TimelineTab/>
                          </Tab>*/
                        }
                        <Tab eventKey="InfoTab" tabClassName="btn-success"
                             title={<><FontAwesomeIcon icon={solid("info-circle")} className="right pr-1"/>
                                 {t('main.label.info')}</>}>
                            <InfoTab profile={profile}/>
                        </Tab>
                        <Tab eventKey="settings"
                             title={<><FontAwesomeIcon icon={solid("cog")} className="right pr-1"/>
                                 {t('main.label.settings')}</>}>
                            <SettingsTab profile={profile}/>
                        </Tab>
                        <Tab eventKey="accounts"
                             title={<><FontAwesomeIcon icon={solid("cog")} className="right pr-1"/>
                                 {t('main.label.accounts')}</>}>
                            <AccountTab profile={profile}/>
                        </Tab>
                        <Tab eventKey="appearance"
                             title={<><FontAwesomeIcon icon={solid("palette")} className="right pr-1"/>
                                 {t('main.label.appearance')}</>}>
                            <AppearanceTab/>
                        </Tab>
                        <Tab eventKey="checkpoint"
                             title={<><FontAwesomeIcon icon={solid("list")} className="right pr-1"/>
                                 {t('main.label.activity')}</>}>
                            <CheckpointTab profile={profile}/>
                        </Tab>
                    </Tabs>

                </Col>
            </Row>
        </Container>
    );
};

export default Profile;
