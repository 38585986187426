/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import TableGamesTypesCreate from './create';
import TableGamesTypesUpdate from './update';
import TableGamesTypesView from './view';
import TableGamesTypesIndex from './index';
import {TableGamesTypesModel} from "./_model";

const TableGamesTypesRoutes = [
    <>
        <Route path={TableGamesTypesModel.info.viewPath} element={<TableGamesTypesIndex/>}/>
        <Route path={TableGamesTypesModel.info.viewPath + '/create'} element={<TableGamesTypesCreate/>}/>
        <Route path={TableGamesTypesModel.info.viewPath + '/update/:id'} element={<TableGamesTypesUpdate/>}/>
        <Route path={TableGamesTypesModel.info.viewPath + '/view/:id'} element={<TableGamesTypesView/>}/>
    </>
];
export default TableGamesTypesRoutes;
