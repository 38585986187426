/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseIndexPage from "crud/base/BaseIndexPage";
import {EventTypesModel} from "./_model";

class EventTypesIndex extends BaseIndexPage {
    //public model = EventTypesModel;

    constructor(props: any) {
        super(props);
        super.model = EventTypesModel;
        super.defaultShowAttrs = EventTypesModel.defaultShowAttrs;
        super.relationTree = EventTypesModel.relationTree
    }
}

export default EventTypesIndex;