import React from 'react'
import FullCalendar from '@fullcalendar/react'
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ruLocale from '@fullcalendar/core/locales/ru';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';

export default class Calendar extends React.Component {

    render() {
        return (
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin, googleCalendarPlugin, listPlugin]}
                headerToolbar={{
                    left: 'prev,next today',
                    right: 'dayGridMonth,listWeek,timeGridWeek,timeGridDay'  //listWeek
                }}
                locale={ruLocale}
                initialView={'listDay'}
                //listWeek
                // themeSystem: 'bootstrap'
                /* editable={false}
                 selectable={true}
                 selectMirror={true}
                 locale={ruLocale}
                 dayMaxEvents={true}
                 weekends={this.state.weekendsVisible}*/
                //googleCalendarApiKey ='AIzaSyBQ8LWXOMLAh13Yvm082-04Z4g0mD4EyK4'
                googleCalendarApiKey='AIzaSyAWCTBVLwozPW_T6scdUC3oMFxZ_0dSaMQ'
                /*events={
                    googleCalendarId: 'abcd1234@group.calendar.google.com'
                }*/
                /* events={{
                     googleCalendarId: '030effa65022b96980379a3524e44a3194f1b44ccf4586ff8c261b757716f4c5@group.calendar.google.com'

                 }}*/
                eventSources={
                    [
                        {
                            //googleCalendarId: '030effa65022b96980379a3524e44a3194f1b44ccf4586ff8c261b757716f4c5@group.calendar.google.com'
                            googleCalendarId: 'indooractivity@gmail.com',
                            color: "#17a2b8"
                        },
                        {
                            googleCalendarId: '7hlsbjfgbk6k74pb47ev3o4n64@group.calendar.google.com',
                            color: "#28a745"
                            //className: 'gcal-event-green',
                        },
                        {
                            googleCalendarId: '879q5nujjssapup1uaj7snc8tc@group.calendar.google.com',
                            color: "#dc3545"
                            // className: 'gcal-event-red',
                        },
                    ]
                }

                //initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                // select={this.handleDateSelect}
                // eventContent={renderEventContent} // custom render function
                eventClick={this.handleEventClick}
                // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                //longPressDelay={1000}
                // eventLongPressDelay={1000}
                // selectLongPressDelay={1000}
                allDaySlot={false}
                /* you can update a remote database when these fire:
                eventAdd={function(){}}
                eventChange={function(){}}
                eventRemove={function(){}}
                */
            />
        )
    }


    handleDateSelect = (selectInfo: any) => {
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                // id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }

    handleEventClick = (e: any) => {
        e.jsEvent.preventDefault();
        console.log(e)
        //console.log(e.event._def.url)
        window.open(e.event._def.url, '_blank')
    }

    handleEvents = (events: any) => {
        this.setState({
            currentEvents: events
        })
    }

}
