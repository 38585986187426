import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import ru from './ru/ru.json';

const resources = {
    /* en: {
         translation: en
     },*/
    ru: {
        translation: ru
    },
};

i18n.use(initReactI18next as any) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'ru',
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            useSuspense: true
            //wait: true
        }
    } as any).then();

export default i18n;
