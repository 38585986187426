import {ApiRequest, HttpMethod} from "api/api";


const getProfile = (tree: any): Promise<any> => {
    const params = {relationtree: JSON.stringify(tree)};
    return ApiRequest<any>(HttpMethod.GET, `/rapi/profile/index`, params);
};

// async function getProfile(onResponse: any) {
//     await axios.get(Constants.API_URL + "/rapi/profile/index")
//         .then((response) => GlobalOnResponse(response, onResponse))
//         .catch((error: any) => GlobalOnError(error, onResponse))
// }

export default getProfile;