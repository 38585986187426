import React, {useEffect, useState} from "react";
import {Tarif} from "../../../models/tarif";

type Props = {
    value: number
    tarif?: Tarif | null
}

export default function PriceWidget(props: Props) {
    const [value, setValue] = useState<number | undefined>(undefined);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (props.value !== value) {
            setValue(props.value)
            setAnimate(true)
        }
    }, [props, value]);
    useEffect(() => {
        if (animate) {
            setTimeout(() => {
                setAnimate(false)
            }, 1000);
        }
    }, [animate]);

    const hasStop = (): boolean => {
        if (props?.tarif?.stop_check_price) {
            return (props?.value ?? 0) > (props?.tarif?.stop_check_price ?? 0);
        }
        return false
    }

    if (hasStop()) {
        if (props?.tarif?.stop_check_price) {
            return <span className={`Score${animate ? " Score--bounce" : ""}`}>
                {props?.tarif?.stop_check_price}₽
            </span>
        } else {
            return <span className={`Score${animate ? " Score--bounce" : ""}`}>{value}₽</span>
        }
    } else {
        return <span className={`Score${animate ? " Score--bounce" : ""}`}>{value}₽</span>
    }

}


