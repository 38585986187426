/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseCreatePage from "crud/base/BaseCreatePage";
import EventTypesForm from "./_form";
import {EventTypesModel} from "./_model";


class EventTypesCreate extends BaseCreatePage {
    //public model = EventTypesModel;
    //public FormComponent = EventTypesForm;

    constructor(props: any) {
        super(props);
        super.model = EventTypesModel;
        super.FormComponent = EventTypesForm;
        super.relationTree = EventTypesModel.relationTree
    }
}

export default EventTypesCreate;