/**
 * Generated by ReactYii 3.0.27 at 07.05.2023
 */
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TagsModel} from "./_model";

class TagsIndex extends BaseIndexPage {
    //public model = TagsModel;

    constructor(props: any) {
        super(props);
        super.model = TagsModel;
        super.defaultShowAttrs = TagsModel.defaultShowAttrs;
        super.relationTree = TagsModel.relationTree
    }
}

export default TagsIndex;