/**
 * Generated by ReactYii 3.0.27 at 15.08.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";
import {getPaymentStatus, getPaymentType} from "../kassa/helpers";
import UserProfileView from "../users/UserProfileView";
import PriceWidget from "../kassa/widgets/PriceWidget";

export const UserPaymentsModel: BaseModelInfo = {
    info: {
        name: "user-payments",
        label: "User Payments",
        apiPath: "/rapi/user-payments",
        viewPath: "/user-payments"
    },
    attrs: {
        id: {label: "ID"},
        user_id: {label: "Пользователь"},
        user_invoice_id: {label: "Счет на оплату"},
        uuid: {label: "id платежа во внешней системе"},
        //sum: {label: "Сумма платежа"},
        sum: {label: "Сумма", type: FieldType.Custom, params: (val: any) => <PriceWidget value={val}/>},

        //status: {label: "Статус оплаты"},
        status: {
            sourceAttr: "status",
            label: "Статус",
            searchField: "status",
            sortField: "status",
            type: FieldType.Custom,
            params: (val?: any) => getPaymentStatus(val)
        },
        //payment_type: {label: "Тип оплаты"},
        payment_type2: {
            sourceAttr: "payment_type",
            label: "Тип",
            type: FieldType.Custom,
            params: (val: any) => getPaymentType(val),
            //onSort: () => "name",
            sortField: "payment_type",
            //onSearch: () => "name"
            searchField: "payment_type"
        },
        descr: {label: "Описание"},
        created_at: {label: "Дата создания", type: FieldType.MomentDate},
        updated_at: {label: "Дата изменения", type: FieldType.MomentDate},
        canceled_at: {label: "Дата отмены", type: FieldType.MomentDate},
        user: {label: '"User"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        profile: {
            sourceAttr: "user",
            label: "Профиль",
            type: FieldType.Custom,
            params: (val: any) => {
                //alert(val)
                return <UserProfileView id={val?.id} image_name={val?.image_name} name={val?.name}
                                        role={val?.role?.name}/>;
            }
        },
        userInvoice: {label: '"UserInvoice"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },
    relationTree: {"relation": {"user": [], "userInvoice": []}},
    defaultShowAttrs: ["profile", "sum", "status", "payment_type2", "created_at"]
};


