/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const TableGamesThemesModel: BaseModelInfo = {
    info: {
        name: "table-games-themes",
        label: "Table Games themes",
        apiPath: "/rapi/table-games-themes",
        viewPath: "/table-games/table-games-themes"
    },
    attrs: {
        id: {label: "ID"},
        name: {label: "Name"},
        descr: {label: "Descr"},
        tableGames: {label: '"TableGames"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        tableGamesToThemes: {
            label: '"TableGamesToThemes"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
    },
    relationTree: {},
    defaultShowAttrs: ["name", "descr",]
};


