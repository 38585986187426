/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const TarifsModel: BaseModelInfo = {
    info: {
        name: "tarifs",
        label: "Тарифы",
        apiPath: "/rapi/tarifs",
        viewPath: "/tarifs"
    },
    attrs: {
        id: {label: "ID"},
        free_minutes: {label: "бесплатных минут"},
        first_period_price: {label: "Цена за первый период"},
        first_period_minutes: {label: "Длина первого периода"},
        second_period_price: {label: "Цена за второй период"},
        stop_check_price: {label: "Cтоп чек"},
        use_stopcheck_for_cash: {label: "Cтоп чек при наличнке", type: FieldType.Bool},
        use_stopcheck_for_card: {label: "Cтоп чек по карте", type: FieldType.Bool},
        discount_percent: {label: "Скидка до стопчека"},
        name: {label: "Название тарифа"},
        descr: {label: "Описание тарифа"},
        created_at: {label: "Created At", type: FieldType.MomentDate},
        updated_at: {label: "Updated At", type: FieldType.MomentDate},
        roles: {label: '"Roles"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        userCheckpoints: {
            label: '"UserCheckpoints"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
        userInvoices: {label: '"UserInvoices"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },
    relationTree: {},
    defaultShowAttrs: ["name", "descr", "stop_check_price", "first_period_price", "second_period_price", "discount_percent"]
};


