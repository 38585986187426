import {ApiRequest, HttpMethod} from "api/api";
import stringify from "qs-stringify";
import {Pagination} from "../types/Pagination";

const GetModel = (apiPath: string, id: number, tree: any): Promise<any> => {
    const params = {relationtree: JSON.stringify(tree)};
    return ApiRequest<any>(HttpMethod.GET, `${apiPath}/view?id=${id}`, params);
};

const GetModels = (url: string, search: string, sort: string, relationTree: any, pagination?: Pagination,): Promise<any> => {
    const params = {
        page: pagination?.page ?? 0,
        pageSize: pagination?.pageSize ?? 12,
        sort,
        search,
        relationtree: JSON.stringify(relationTree)
    };
    console.log(params)
    console.log(url)
    return ApiRequest<any>(HttpMethod.GET, `${url}/index`, params);
};

const DeleteModel = (model: string, id: number): Promise<any> => {
    return ApiRequest<any>(HttpMethod.GET, `/${model}/delete?id=${id}`);
};

const SaveModel = (action: string, data: any): Promise<any> => {
    return ApiRequest<any>(HttpMethod.POST, `${action}`, stringify(data));
};

export {GetModel, GetModels, DeleteModel, SaveModel}