import React from "react";
import {AttrInfo} from "../types/AttrInfo";


type Props = {
    value: any
    attr: AttrInfo
    modelName?: string
    params?: any
}

export interface State {
    data: any,
    isDataLoaded: boolean,
    hasError: boolean
    hasMore: boolean
}

function RelationOneViewer(props: Props) {
    //console.log(props.value)
    return (props.value) ?
        (
            <>
                <p>{props?.value[props?.attr?.params?.name ?? "name"]}</p>
            </>
        ) :
        (<p className="text-muted">empty</p>)

}

export default RelationOneViewer