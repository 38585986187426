/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import UserInvoicesForm from "./_form";
import {UserInvoicesModel} from "./_model";

class UserInvoicesUpdate extends BaseUpdatePage {
    //public model = UserInvoicesModel;
    //public FormComponent = UserInvoicesForm;

    constructor(props: any) {
        super(props);
        super.model = UserInvoicesModel;
        super.FormComponent = UserInvoicesForm;
        super.relationTree = UserInvoicesModel.relationTree
    }
}

export default withTranslation()(withRouter(UserInvoicesUpdate));
