import React, {useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExpand, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

type Props = {
    title: any,
    children: any,
    initialOpen?: boolean
    style?: any
}
export default function CollapsedCard(props: Props) {
    const [open, setOpen] = useState(props.initialOpen ?? false);
    const divRef = useRef<HTMLDivElement>(null);

    const handleFullscreen = () => {
        if (divRef.current) {
            if (divRef.current.requestFullscreen) {
                divRef.current.requestFullscreen().then(_ => {
                });
            } /*else if (divRef.current?.webkitRequestFullscreen) {
                divRef.current.webkitRequestFullscreen();
            } else if (divRef.current.msRequestFullscreen) {
                divRef.current.msRequestFullscreen();
            }*/
        }
    };


    return <Card className="card-primary">
        <Card.Header style={{padding: "0.55rem 1.05rem"}} onClick={() => setOpen(!open)}>
            <Card.Title>{props.title}</Card.Title>
            <div className="card-tools">
                <Button
                    className="btn-sm"
                    onClick={handleFullscreen}
                    aria-controls="fullscreen">
                    <FontAwesomeIcon icon={faExpand}/>
                </Button>

                <Button
                    className="btn-sm"
                    onClick={() => setOpen(!open)}
                    aria-controls="collapse"
                    aria-expanded={open}>
                    {(open) ? <FontAwesomeIcon icon={faMinus}/> : <FontAwesomeIcon icon={faPlus}/>}
                </Button>
            </div>
        </Card.Header>
        <Collapse in={open}>
            <Card.Body ref={divRef} style={props.style}>
                {props.children}
            </Card.Body>
        </Collapse>
    </Card>
}