export class Pagination {
    page = 0;
    pageCount = 0;
    pageSize = 0;
    totalItems = 0;

}

export type PaginationCallback = {
    data: Pagination,
    callback(data: Pagination): any;
}
