/**
 * Generated by ReactYii 3.0.27 at 22.11.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField} from "crud";
import BaseForm from "crud/base/BaseForm";
import {WssLogModel} from "./_model";

class WssLogForm extends BaseForm {
    //public model = WssLogModel;

    constructor(props: any) {
        super(props);
        super.model = WssLogModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                type: '',
                content: '',
                created_at: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>
                    <InputField name={'id'}
                                model={this.model.info.name}
                                label={this.model.attrs.id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.id}/>

                    <InputField name={'type'}
                                model={this.model.info.name}
                                label={this.model.attrs.type.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.type}/>

                    <InputField name={'content'}
                                model={this.model.info.name}
                                label={this.model.attrs.content.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.content}/>

                    <InputField name={'created_at'}
                                model={this.model.info.name}
                                label={this.model.attrs.created_at.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.created_at}/>


                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default WssLogForm;
