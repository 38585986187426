import React, {useEffect, useState} from 'react'
import {Form, InputGroup} from "react-bootstrap";
import {FieldInputProps} from "../types/FieldInputProps";

export default function SwitchField(props: FieldInputProps) {
    const [value, setValue] = useState<boolean | undefined>(props.value);

    useEffect(() => {
        if (typeof value == "boolean") {
            console.log("SwitchField", {name: props.name, value: value})
            props?.onChange({name: props.name, value: value ? 1 : 0})
        }
    }, [value]);

    const onChangeValidate = (event: any) => {
        let value = event?.target?.checked;
        setValue(value);
    }

    return (
        <InputGroup className={'form-group w-100 field-' + props.model + '-' + props.name}>
            <InputGroup.Text style={{backgroundColor: "#FFF"}}>
                <Form.Check
                    type="switch"
                    required={props.required}
                    id={props.model + '-' + props.name}
                    //className={'form-control ' + classPostfix(validated)}
                    name={props.name}
                    label={props.label}
                    placeholder={props.placeholder}
                    checked={props.value}
                    //value={props.value || ''}
                    onChange={onChangeValidate}
                    {...props.pluginProps}
                />
            </InputGroup.Text>
        </InputGroup>
    )
}

