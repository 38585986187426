import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownZA, faArrowUpAZ} from "@fortawesome/free-solid-svg-icons";
import TableIndexAttrDropdown from "./TableIndexAttrDropdown";
import React from "react";
import {BaseModelInfo} from "../types/BaseModelInfo";

type Props = {
    model: BaseModelInfo,
    enabledAttributes: string[],
    showSettings?: boolean,
    sortBy: any,
    sortByField: (attr: string) => any,
    toggleAttrShow: (attr: string) => any,
}

export default function TableViewHeader(props: Props) {
    const customButton = (attr: string) => {
        let sortField = props.model?.attrs?.[attr]?.sortField;
        if (sortField) {
            return <button className="link-button" onClick={() => props.sortByField(sortField ?? "")}>
            <span className="text-muted font-monospace p-0 font-weight-bold">
                {props.model.attrs[attr]?.label}
            </span>
                <span className="p-2">
                {(props.sortBy === sortField) && <FontAwesomeIcon icon={faArrowUpAZ}/>}
                    {(props.sortBy === "-" + sortField) && <FontAwesomeIcon icon={faArrowDownZA}/>}
            </span>
            </button>
        } else {
            return <span className="text-muted font-monospace p-0 font-weight-bold">
                {props.model.attrs[attr]?.label}
            </span>
        }
    }

    const defaultButton = (attr: string) =>
        <button className="link-button" onClick={() => props.sortByField(attr)}>
            <span className="text-muted font-monospace p-0 font-weight-bold">
                {props.model.attrs[attr]?.label}
            </span>
            <span className="p-2">
                {(props.sortBy === attr) && <FontAwesomeIcon icon={faArrowUpAZ}/>}
                {(props.sortBy === "-" + attr) && <FontAwesomeIcon icon={faArrowDownZA}/>}
            </span>
        </button>


    let items = props.enabledAttributes.map((attr: string, index) =>
        (props.model.attrs.hasOwnProperty(attr)) &&
        <div className="d-table-cell" key={'head-' + attr + index} id={'head-' + attr + index}>
            {
                ('sortField' in props.model.attrs[attr])
                    ? customButton(attr)
                    : defaultButton(attr)
            }

            {
                // if last index, add settings button
                //((props.showSettings ?? true) && (index === enabledAttributes.length - 1)) &&

            }
        </div>
    )

    if (props.showSettings ?? true)
        items.push(
            <div className="d-table-row" key="showSettings">
                <TableIndexAttrDropdown
                    attributes={props.model.attrs}
                    enabledAttributes={props.enabledAttributes}
                    onChange={props.toggleAttrShow}/>
            </div>
        )
    return <div className="d-table-row divTr" key="head">{items}</div>
}