import React from 'react';
import {Card, OverlayTrigger, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {User} from "../../models/users/User";
import MainLoader from "../../ui/loaders/MainLoader";
import Slider from "react-slick";
import {Constants} from "../../utils/constants";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";


const UserBox = ({profile}: ({ profile?: User })) => {
    //const [t] = useTranslation();
    //const [profile, setProfile] = useState<any>([]);


    const popoverTelegram = (telegram: any) => (
        <Popover id="popover-pages">
            {/*  <Popover.Title as="h3">Подробнее</Popover.Title>*/}
            <Popover.Content>
                <p>internal ID #{telegram.id}</p>
                <p>ChatID: #{telegram.telegram_chat_id}</p>
                <p>Status {telegram.login_status}</p>
                <p>Phone {telegram.phone}</p>
                <p>Name @{telegram.telegram_name}</p>
                <p>telegram_status {telegram.telegram_status}</p>
                <p>created_at {telegram.created_at}</p>
                <p>updated_at {telegram.updated_at}</p>
            </Popover.Content>
        </Popover>
    );

    const SliderItems = () => profile?.userImages
        ?.reverse()
        ?.map((image: any) => (
            <div key={image.id} className="text-center">
                {/*   <button className="btn btn-outline-info" onClick={() => setSelectedImage(image.name)}>
                            <FontAwesomeIcon icon={faEye}/>
                        </button>

                        <button className="btn btn-outline-danger ml-1" onClick={() => setDeleteModal(image.id)}>
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </button>*/}

                <img alt={image.name}
                     style={{pointerEvents: "none", minHeight: "40px"}}
                     className='img-fluid center'
                     src={`${Constants.publicImageViewPath}/users/400/${image?.image_name}`}
                />
            </div>
        ));

    if (profile) {
        return (
            <>
                <Card className="card card-primary card-outline">
                    <Card.Body className="card-body box-profile">
                        <div className="text-center">
                            <Slider
                                infinite={false}
                                dots={true}
                                draggable={true}
                                slidesToShow={1}>
                                {SliderItems()}
                            </Slider>

                            {/*  <img width={100} height={100} src="/logo.png" alt="User profile"/>*/}
                        </div>
                        <h3 className="profile-username text-center">{profile.name}</h3>
                        <p className="text-muted text-center">@{profile.name}</p>
                        <p className="text-muted text-center">{profile?.role?.name}</p>
                        <ul className="list-group list-group-unbordered mb-3">
                            <li className="list-group-item">
                                <FontAwesomeIcon icon={solid("phone")}/>
                                <a className="float-right" target="_blank"
                                   href={`tel://${profile.phone}`} rel="noreferrer">
                                    {profile.phone}
                                </a>
                            </li>

                            {
                                (profile?.userAuthTelegrams?.length >= 1) &&
                                <li className="list-group-item">
                                    <FontAwesomeIcon icon={faTelegram}/>
                                    {profile?.userAuthTelegrams.map((telegram: any) => (
                                            <a className="float-right" target="_blank"
                                               href={`https://t.me/${telegram.telegram_name}`} rel="noreferrer">
                                                @{telegram.telegram_name}
                                                <OverlayTrigger
                                                    /*trigger="click" */
                                                    placement="top"
                                                    overlay={popoverTelegram(telegram)}
                                                    defaultShow={false}
                                                    delay={100}>
                                                    <FontAwesomeIcon size={"1x"} className="pl-1" icon={faInfoCircle}/>
                                                </OverlayTrigger>
                                            </a>
                                        )
                                    )}
                                </li>
                            }

                            {/*  <li className="list-group-item">
                                <FontAwesomeIcon icon={solid("envelope")}/>
                                <span className="float-right">{profile.email}</span>
                            </li>*/}
                            <li className="list-group-item">
                                <b>Посещений</b>
                                <span className="float-right">{profile?.counts?.userCheckpoints}</span>
                            </li>
                            <li className="list-group-item"></li>

                            {/* <li className="list-group-item">
                                <b>Смен</b>
                                <span className="float-right">16</span>
                            </li>*/}
                            {/*  <li className="list-group-item">
                                <b>Залетов</b>
                                <span className="float-right">20</span>
                            </li>
                            <li className="list-group-item">
                                <b>Опозданий</b>
                                <span className="float-right">13</span>
                            </li>*/}
                        </ul>

                    </Card.Body>
                    {/* /.card-body */}
                </Card>

                {/*  <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title">
                                {t<string>('main.label.aboutMe')}
                            </h3>
                        </div>
                        <div className="card-body">
                            <strong>
                                <i className="fas fa-book mr-1"/>
                                {t<string>('main.label.education')}
                            </strong>
                            <p className="text-muted">
                                B.S. in Computer Science from the University of Tennessee at
                                Knoxville
                            </p>
                            <hr/>
                            <strong>
                                <i className="fas fa-map-marker-alt mr-1"/>
                                {t<string>('main.label.location')}
                            </strong>
                            <p className="text-muted">Malibu, California</p>
                            <hr/>
                            <strong>
                                <i className="fas fa-pencil-alt mr-1"/>
                                {t<string>('main.label.skills')}
                            </strong>
                            <p className="text-muted">
                                <span className="tag tag-danger">UI Design</span>
                                <span className="tag tag-success">Coding</span>
                                <span className="tag tag-info">Javascript</span>
                                <span className="tag tag-warning">PHP</span>
                                <span className="tag tag-primary">Node.js</span>
                            </p>
                            <hr/>
                            <strong>
                                <i className="far fa-file-alt mr-1"/>
                                {t<string>('main.label.notes')}
                            </strong>
                            <p className="text-muted">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Etiam fermentum enim neque.
                            </p>
                        </div>
                    </div>*/}
            </>
        )
    } else {
        return <MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>
    }
};

export default UserBox;
