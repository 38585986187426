import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {AttrInfo} from "../types/AttrInfo";
import {Link} from "react-router-dom";
import {camelToYii} from "../../utils/case_transforms";
import {ButtonGroup, Dropdown, Row} from "react-bootstrap";
import Select from 'react-select';
import {RelationManyAdd, RelationManyGetList, RelationManyRemove} from "../api/realtionMany";
import {toast} from "react-toastify";
import {BaseModelInfo} from "../types/BaseModelInfo";
import {Constants} from "../../utils/constants";

type Props = {
    show: boolean
    closeModal: any
    modelId: number,
    attrName: string
    attr: AttrInfo
    value: any
    model?: BaseModelInfo
    params?: any
}

function RelationManyViewer(props: Props) {
    const [relationData, setRelationData] = useState([]);
    const [values, setValues] = useState<any>([]);
    const [isDataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        if (props.show) {
            getRelation();
        }
    }, [props])

    useEffect(() => setValues(props.value), [props.value])

    const filterExistingItems = (data: any) => {
        let enabledIdsList = values?.map((v: any) => v.id) ?? []
        //console.log("enabledIdsList",enabledIdsList)
        return data.filter((v: any) => !enabledIdsList.includes(v.id));
    }

    const getRelation = () => {
        const url = (props.attr.params?.relationUrl)
            ? props.attr.params?.relationUrl
            : (props.attr?.params?.model?.info?.apiPath)
                ? props.attr?.params?.model?.info?.apiPath
                : Constants.ApiVersion + "/" + camelToYii(props.attrName);

        console.log("getRelation", url)
        setDataLoaded(false);
        RelationManyGetList(url)
            .then((resp: any) => {
                console.log(resp.data)
                let filteredItems = filterExistingItems(resp.data)
                //console.log("filteredItems", filteredItems)
                setRelationData(filteredItems);
                setDataLoaded(true);
            })
            .catch((error) => {
                console.log(error)
                return toast.error(error?.response?.data?.message || 'Failed');
            });
    }

    const addItem = (e: any) => {
        setDataLoaded(false)
        RelationManyAdd(props?.model?.info.name ?? "", props.modelId, props.attrName, e.id)
            .then((resp: any) => {
                console.log(resp)
                setValues([...values, ...[{id: e.id, name: e.name, descr: e.descr, isNew: true}]]);
                setRelationData(filterExistingItems(relationData));
                //getRelation();
                setDataLoaded(true)
                toast.success("Сохранено")
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
    }

    const removeItem = (e: any) => {
        setDataLoaded(false)

        RelationManyRemove(props.model?.info?.name ?? "", props.modelId, props.attrName, e.id)
            .then((resp: any) => {
                console.log(resp)
                values.find((v: any) => v.id === e.id).isRemoved = true
                //let newValues = values.filter((v: any) => !(v.id == e.id))
                //setValues([...values, ...[{id: e.id, name: e.name, descr: e.descr, isRemoved: true}]]);
                getRelation();
                setDataLoaded(true)
                toast.success("Удалено")
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));

    }
    return <Modal show={props.show}>
        <div className="modal-header">
            <h5 className="modal-title">Добавить {props.attrName} в {props.model?.info.name} #{props.modelId}</h5>
        </div>
        <div className="modal-body">
            {
                values?.map((v: any, i: number) =>
                    <Row key={props.attrName + i}>
                        {
                            ((v.isRemoved) ?? false)
                                ? <span
                                    className="btn btn-danger"><s>{v[props.attr.params.name ?? "name"]}</s></span>
                                : <Dropdown as={ButtonGroup} key={props.attrName + i}>
                                    <Link
                                        className={"btn d-inline " + ((v?.isNew ?? false) ? "btn-success" : "btn-outline-primary")}
                                        key={props.attrName + i}
                                        to={'/' + camelToYii(props.attrName) + '/view/' + v.id}>
                                        {v[props.attr.params.name ?? "name"]}
                                    </Link>

                                    <Dropdown.Toggle split variant="primary"/>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={(_) => removeItem(v)}
                                            className="btn-danger"
                                        >Удалить</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                        }
                    </Row>
                )
            }

            <hr/>
            {
                isDataLoaded &&
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    getOptionLabel={(v: any) => v.name}
                    getOptionValue={(v: any) => v.id}
                    defaultValue={null}
                    isLoading={!isDataLoaded}
                    isSearchable={true}
                    //isDisabled={isDisabled}
                    //isClearable={isClearable}
                    //isRtl={isRtl}
                    //name="color"
                    onChange={addItem}
                    options={relationData}
                />
            }
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={props.closeModal}>Закрыть</button>
        </div>
    </Modal>
}

export default RelationManyViewer