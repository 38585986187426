/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {UsersModel} from "./_model";
//import SpaCrud from "../../crud/base/SpaCrud/SPAcrud";
//import UsersForm from "./_form";
import BaseIndexPage from "../../crud/base/BaseIndexPage";

class UsersIndex extends BaseIndexPage {
    //public model = UsersModel;

    constructor(props: any) {
        super(props);
        super.model = UsersModel;
        //super.FormComponent = UsersForm;
        super.defaultShowAttrs = UsersModel.defaultShowAttrs;
        super.relationTree = UsersModel.relationTree
    }
}

export default UsersIndex;