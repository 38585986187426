/**
 * Generated by ReactYii 3.0.27 at 27.11.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {EventUserCheckpointParticipantsModel} from "./_model";

class EventUserCheckpointParticipantsIndex extends BaseIndexPage {
    //public model = EventUserCheckpointParticipantsModel;

    constructor(props: any) {
        super(props);
        super.model = EventUserCheckpointParticipantsModel;
        super.defaultShowAttrs = EventUserCheckpointParticipantsModel.defaultShowAttrs;
        super.relationTree = EventUserCheckpointParticipantsModel.relationTree
    }
}

export default EventUserCheckpointParticipantsIndex;