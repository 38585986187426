/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";
// @ts-ignore
import {RolesModel} from "../roles/_model";
import {Constants} from "../../utils/constants";
import UserProfileView from "./UserProfileView";

export const UsersModel: BaseModelInfo = {
    info: {
        name: "users",
        label: "Юзвери",
        apiPath: "/rapi/users",
        viewPath: "/users"
    },
    attrs: {
        profile: {
            sourceAttr: null,
            label: "Профиль",
            type: FieldType.Custom,
            params: (val: any) => <UserProfileView id={val?.id} image_name={val?.image_name} name={val?.name}
                                                   role={val?.role?.name}/>,
            //onSort: () => "name",
            sortField: "name",
            //onSearch: () => "name"
            searchField: "name"
        },

        id: {label: "ID"},
        //`${Constants.publicImageViewPath}/users/400/${image?.image_name}`
        //telegram_chat_id: {label: "Telegram id"},
        //current_module_id: {label: "Текущий модуль бота"},
        //current_module_name: {label: "Текущее название модуля бота"},
        //telegram_name: {label: "Telegram name"},
        role_id: {label: "ID Роли", type: FieldType.RelationOneID, params: {name: "name", model: RolesModel}},
        barcode: {label: "Qr Код", type: FieldType.BarCode},
        username: {label: "Login"},
        name: {label: "ФИО"},
        password_hash: {label: "Пароль"},
        email: {label: "Почта"},
        vk_link: {label: "VK id"},
        fb_link: {label: "Facebook id"},
        phone: {label: "Телефон"},
        comment: {label: "Комментарий"},
        balance: {label: "Баланс"},
        is_dead_soul: {label: "Флаг мертвых душ"},
        status: {label: "Статус"},
        created_at: {label: "Дата регистрации", type: FieldType.MomentDate},
        updated_at: {label: "Дата обновления", type: FieldType.MomentDate},
        birthday: {label: "Дата рождения"},
        special_end_date: {label: "Дата автосмены тарифа"},
        tarif: {label: '"Tarif"', type: FieldType.RelationOneData, params: {name: "name"}},
        role: {
            label: 'Роль',
            type: FieldType.RelationOneData,
            params: {name: "name"},
            sortField: "role_id",
            searchField: ""
        },
        //userDatas: {label: 'user_datas', type: FieldType.RelationOneData, params: {name: "name"}},
        telegrambotUserMessages: {
            label: '"TelegrambotUserMessages"',
            type: FieldType.RelationOneData,
            params: {name: "name"}
        },
        telegrambotUserTriggers: {
            label: '"TelegrambotUserTriggers"',
            type: FieldType.RelationOneData,
            params: {name: "name"}
        },
        userCheckpoints: {label: '"UserCheckpoints"', type: FieldType.RelationOneData, params: {name: "name"}},
        userEvents: {label: '"UserEvents"', type: FieldType.RelationOneData, params: {name: "name"}},
        userInvoices: {label: '"UserInvoices"', type: FieldType.RelationOneData, params: {name: "name"}},
        userTokens: {label: '"UserTokens"', type: FieldType.RelationOneData, params: {name: "name"}},
        userAuthLogins: {label: '"userAuthLogins"', type: FieldType.RelationOneData, params: {name: "name"}},
        userAuthEmails: {label: '"userAuthEmails"', type: FieldType.RelationOneData, params: {name: "name"}},
        userAuthTelegrams: {label: '"userAuthTelegrams"', type: FieldType.RelationOneData, params: {name: "name"}},
        userImages: {label: '"userImages"', type: FieldType.RelationOneData, params: {name: "image_name"}},
        image_name: {
            label: "Аватар",
            type: FieldType.Custom,
            sourceAttr: null,
            params: (val: any) => <img alt="avatar_only" className="img-circle" width={50} height={50}
                                       src={Constants.publicImageViewPath + "users/400/" + val?.image_name}/>
        },

    },
    relationTree: {
        relation: {
            role: []
        },
        relations: {
            //userCheckpoints: [],
            // "userImages",
            userImages: [],
            //"userCheckpoints",
            //userEvents: [],
            //userInvoices: [],
            userAuthLogins: [],
            userAuthEmails: [],
            userAuthTelegrams: []
            //"userAuthLogins",
            //"userAuthEmails",
            // "userAuthTelegrams",
        },
    },
    defaultShowAttrs: ["profile", /*"barcode", */"phone", "updated_at"]
};


