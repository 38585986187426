import {Constants} from "../../utils/constants";
import React from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

type Props = {
    id?: number,
    //tarif_id?: number,
    //role_group_id?: number,
    //level?: number,
    //descr?: number,
    image_name?: string,
    name?: string,
    role?: string
}

export default function UserProfileView(props: Props) {

    const avatarUrl = (props?.image_name)
        ? Constants.publicImageViewPath + "users/400/" + props?.image_name
        : Constants.publicImageViewPath + "noAvatar.png";
    return <Row className='d-flex'>
        <Col md={"auto"}>
            <div className="img-circle"
                 style={{
                     height: 40,
                     width: 40,
                     backgroundPosition: "center",
                     backgroundSize: "contain",
                     backgroundImage: "url(" + avatarUrl + ")"
                 }}
            >
            </div>
            {/*<img alt="Ava"
                 className="img-circle"
                 width={50} height={50}
                 src={(props?.image_name)
                     ? Constants.publicImageViewPath + "users/400/" + props?.image_name
                     : Constants.publicImageViewPath + "noAvatar.png"
                 }/>*/}
        </Col>

        {/*style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth:"140px"}}*/}
        <Col style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            <Link to={"/users/view/" + props?.id} className="text-md pl-1">{props.name}</Link><br/>
            <span className="text-sm pl-1">{props.role}</span>
        </Col>

    </Row>
}