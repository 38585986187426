/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import RolesCreate from './create';
import RolesUpdate from './update';
import RolesView from './view';
import RolesIndex from './index';
import {RolesModel} from "./_model";

const RolesRoutes = [
    <>
        <Route path={RolesModel.info.viewPath} element={<RolesIndex/>}/>
        <Route path={RolesModel.info.viewPath + '/create'} element={<RolesCreate/>}/>
        <Route path={RolesModel.info.viewPath + '/update/:id'} element={<RolesUpdate/>}/>
        <Route path={RolesModel.info.viewPath + '/view/:id'} element={<RolesView/>}/>
    </>
];
export default RolesRoutes;
