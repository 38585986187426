/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {WikiCategoriesModel} from "./_model";

class WikiCategoriesView extends BaseViewPage {
    //public model = WikiCategoriesModel;

    constructor(props: any) {
        super(props);
        super.model = WikiCategoriesModel;
        super.relationTree = WikiCategoriesModel.relationTree
    }
}

export default withRouter(WikiCategoriesView);