/**
 * Generated by ReactYii 3.0.27 at 22.11.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {WssLogModel} from "./_model";

class WssLogView extends BaseViewPage {
    //public model = WssLogModel;

    constructor(props: any) {
        super(props);
        super.model = WssLogModel;
        super.relationTree = WssLogModel.relationTree
    }
}

export default withRouter(WssLogView);