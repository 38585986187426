import stringify from "qs-stringify";
import {ApiRequest, HttpMethod} from "api/api";
import {Constants} from "../../utils/constants";
import {format} from "date-fns";


const UserUpdateStudDate = (id: any, special_end_date: any): Promise<any> => {
    console.log("UserUpdateStudDate")
    const params = {
        special_end_date: format(special_end_date ?? new Date(), 'yyyy-MM-dd')
    };
    console.log(HttpMethod.POST,Constants.ApiVersion + `/users/update-stud-date?id=${id}`,stringify(params))
    return ApiRequest<any>(HttpMethod.POST, Constants.ApiVersion + `/users/update-stud-date?id=${id}`, stringify(params));
};

const UserUpdateFreeDate = (id: string, special_end_date: any): Promise<any> => {
    const params = stringify({
        special_end_date: format(special_end_date ?? new Date(), 'yyyy-MM-dd')
    });
    return ApiRequest<any>(HttpMethod.POST, Constants.ApiVersion + `/users/update-free-date?id=${id}`, params);
};


export {UserUpdateStudDate, UserUpdateFreeDate};