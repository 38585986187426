/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import RoleGroupsCreate from './create';
import RoleGroupsUpdate from './update';
import RoleGroupsView from './view';
import RoleGroupsIndex from './index';
import {RoleGroupsModel} from "./_model";

const RoleGroupsRoutes = [
    <>
        <Route path={RoleGroupsModel.info.viewPath} element={<RoleGroupsIndex/>}/>
        <Route path={RoleGroupsModel.info.viewPath + '/create'} element={<RoleGroupsCreate/>}/>
        <Route path={RoleGroupsModel.info.viewPath + '/update/:id'} element={<RoleGroupsUpdate/>}/>
        <Route path={RoleGroupsModel.info.viewPath + '/view/:id'} element={<RoleGroupsView/>}/>
    </>
];
export default RoleGroupsRoutes;
