/**
 * Generated by ReactYii 3.0.27 at 02.10.2023
 */
import {Route} from "react-router-dom";
import UserTableGameEventsCreate from './create';
import UserTableGameEventsUpdate from './update';
import UserTableGameEventsView from './view';
import UserTableGameEventsIndex from './index';
import {UserTableGameEventsModel} from "./_model";

const UserTableGameEventsRoutes = [
    <>
        <Route path={UserTableGameEventsModel.info.viewPath} element={<UserTableGameEventsIndex/>}/>
        <Route path={UserTableGameEventsModel.info.viewPath + '/create'} element={<UserTableGameEventsCreate/>}/>
        <Route path={UserTableGameEventsModel.info.viewPath + '/update/:id'} element={<UserTableGameEventsUpdate/>}/>
        <Route path={UserTableGameEventsModel.info.viewPath + '/view/:id'} element={<UserTableGameEventsView/>}/>
    </>
];
export default UserTableGameEventsRoutes;
