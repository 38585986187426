/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import TableGamesCoopsForm from "./_form";
import {TableGamesCoopsModel} from "./_model";

class TableGamesCoopsUpdate extends BaseUpdatePage {
    //public model = TableGamesCoopsModel;
    //public FormComponent = TableGamesCoopsForm;

    constructor(props: any) {
        super(props);
        super.model = TableGamesCoopsModel;
        super.FormComponent = TableGamesCoopsForm;
        super.relationTree = TableGamesCoopsModel.relationTree
    }
}

export default withTranslation()(withRouter(TableGamesCoopsUpdate));
