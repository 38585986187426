/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const TableGamesImgsModel: BaseModelInfo = {
    info: {
        name: "table-games-imgs",
        label: "Table Games imgs",
        apiPath: "/rapi/table-games-imgs",
        viewPath: "/table-games/table-games-imgs"
    },
    attrs: {
        id: {label: "ID"},
        table_game_id: {label: "Table Games ID"},
        name: {label: "название изображения"},
        tableGames: {label: '"TableGames"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },
    relationTree: {},
    defaultShowAttrs: ["table_game_id", "name",]
};


