/**
 * Generated by ReactYii 3.0.27 at 02.10.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const UserTableGameEventParticipantsModel: BaseModelInfo = {
    info: {
        name: "user-table-game-events-participants",
        label: "User Table Game Event Participants",
        apiPath: "/rapi/user-table-game-events-participants",
        viewPath: "/table-games/user-table-game-events-participants"
    },
    attrs: {
        id: {label: "ID"},
        user_table_game_event_id: {label: "Событие"},
        user_id: {label: "Участник"},
        created_at: {label: "Created At"},
        user: {label: '"User"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        userTableGameEvent: {
            label: '"UserTableGameEvent"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
    },
    relationTree: {},
    defaultShowAttrs: ["user_table_game_event_id", "user_id", "created_at",]
};


