/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import {Route} from "react-router-dom";
import {lazy, Suspense} from "react";

const WikiIndex = lazy(() => import("./index"));


const WikiRoutes = [
    <>
        <Route path="/wiki" element={<Suspense><WikiIndex/></Suspense>}/>
        <Route path="/wiki/:id" element={<Suspense><WikiIndex/></Suspense>}/>
    </>
];
export default WikiRoutes;
