/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import {Route} from "react-router-dom";
import WikiPagesCreate from './create';
import WikiPagesUpdate from './update';
import WikiPagesView from './view';
import WikiPagesIndex from './index';
import {WikiPagesModel} from "./_model";

const WikiPagesRoutes = [
    <>
        <Route path={WikiPagesModel.info.viewPath} element={<WikiPagesIndex/>}/>
        <Route path={WikiPagesModel.info.viewPath + '/create'} element={<WikiPagesCreate/>}/>
        <Route path={WikiPagesModel.info.viewPath + '/update/:id'} element={<WikiPagesUpdate/>}/>
        <Route path={WikiPagesModel.info.viewPath + '/view/:id'} element={<WikiPagesView/>}/>
    </>
];
export default WikiPagesRoutes;
