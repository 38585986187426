import React, {Component} from "react";

type Props = {
    value: any
}

class BoolViewer extends Component<Props> {

    render() {
        return (
            <span className={`btn btn-sm disabled ${this.props.value ? "btn-outline-success" : "btn-outline-danger"}`}>
                    {this.props.value ? "Да" : "Нет"}
            </span>
        )
    }
}

export default BoolViewer