import React, {Component} from 'react';
import {Navigate} from "react-router-dom";
import InputField from "../FormInputs/InputField";
import SwitchField from "../FormInputs/SwitchField";
import WysiwygEditor from "../FormInputs/WysiwygEditor";
import {FormProps} from "../types/IFormState";
import {IFormState} from "../types/FormProps";
import {toast} from "react-toastify";
import {SaveModel} from "../api/crudApi";
import {BaseModelInfo, defaultModel} from "../types/BaseModelInfo";

class BaseForm extends Component<FormProps, IFormState> {
    public model: BaseModelInfo = defaultModel;

    constructor(props: FormProps) {
        super(props);
        this.state = {
            validation: '',
            form: {},
            redirect: false,
        };
    }

    componentDidUpdate(prevProps: FormProps) {
        if (this.props.data !== prevProps.data) {
            console.log("Props have changed, do something here...");
        }

        if (this.props.data !== prevProps.data) {
            console.log("prevProps", prevProps)
            if (this.props.hasOwnProperty('data')) {
                this.setState({form: this.props.data});
            }
        }
    }

    componentDidMount() {
        if (this.props.hasOwnProperty('data')) {
            this.setState({form: this.props.data});
        }
    }

    handleSubmit = (event: any) => {
        event.preventDefault();
        //let data = PrepareForm(this.modelName, this.state.form);
        let data = this.state.form;

        SaveModel(this.props.action, data)
            .then((resp: any) => {
                this.setState({validation: ''});
                this.setState({id: resp.data.id});
                this.setState({redirect: true});
                toast.success("Сохранено")
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
    }

    handleInputChange = (event: any) => {
        let value
        let field;
        //console.log(event)
        if (event?.target != null) {
            value = event?.target?.value;
            field = event?.target?.name;
        } else {
            value = event?.value;
            field = event?.name;
        }

        let form = this.state.form;
        form[field] = value;
        console.log("handleInputChange", field, value)
        this.setState({form: form});
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.apiPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>
                    <InputField name={'name'} model={this.model.info.name} label={'название'}
                                onChange={this.handleInputChange} value={this.state.form['name']}/>

                    <WysiwygEditor name={'descr'} model={this.model.info.name} label={'Описание'}
                                   onChange={this.handleInputChange} value={this.state.form['descr']}/>

                    <SwitchField name={'enabled'} model={this.model.info.name} label={'enabled'}
                                 onChange={this.handleInputChange} value={this.state.form['enabled']}/>

                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"align-center"}>
                    <button type={"submit"} className={'btn btn-success'}>Save</button>
                </div>
            </form>
        );
    }
}

export default BaseForm;
