/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {TableGamesCoopsModel} from "./_model";

class TableGamesCoopsView extends BaseViewPage {
    //public model = TableGamesCoopsModel;

    constructor(props: any) {
        super(props);
        super.model = TableGamesCoopsModel;
        super.relationTree = TableGamesCoopsModel.relationTree
    }
}

export default withRouter(TableGamesCoopsView);