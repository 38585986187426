function camelToUnderscore(key: string) {
    let result = key.replace(/([A-Z])/g, " $1");
    return result.split(' ').join('_').toLowerCase();
}

function camelToYii(key: string) {
    let result = key.replace(/([A-Z])/g, " $1");
    return result.split(' ').join('-').toLowerCase();
}

function underscoreToCamel(key: string) {
    return key.replace(/_([a-z])/g, function (g) {
        return g[1].toUpperCase();
    });
}

function YiiToCamel(key: string) {
    return key.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
    });
}


export {camelToUnderscore, camelToYii, underscoreToCamel, YiiToCamel}