/**
 * Generated by ReactYii 3.0.27 at 23.11.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField} from "crud";
import BaseForm from "crud/base/BaseForm";
import RelationOneDropDown from "crud/FormInputs/RelationOneDropDown";
import {UserTurnsModel} from "./_model";

class UserTurnsForm extends BaseForm {
    //public model = UserTurnsModel;

    constructor(props: any) {
        super(props);
        super.model = UserTurnsModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                user_id: '',
                //is_closed: '',
                open_dt: '',
                close_dt: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>
                    {/*   <InputField name={'id'}
                                model={this.model.info.name}
                                label={this.model.attrs.id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.id}/>*/}

                    <InputField name={'user_id'}
                                model={this.model.info.name}
                                label={this.model.attrs.user_id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.user_id}/>

                    {/* <InputField name={'is_closed'}
                                model={this.model.info.name}
                                label={this.model.attrs.is_closed.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.is_closed}/>*/}

                    {/*     <InputField name={'open_dt'}
                                model={this.model.info.name}
                                label={this.model.attrs.open_dt.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.open_dt}/>

                    <InputField name={'close_dt'}
                                model={this.model.info.name}
                                label={this.model.attrs.close_dt.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.close_dt}/>*/}


                    <RelationOneDropDown name={'user_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.user_id?.label}
                                         relationUrl={this.model.attrs.user.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "user_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['user_id']}/>


                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default UserTurnsForm;
