/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField} from "crud";
import BaseForm from "crud/base/BaseForm";
import {UserInvoicesModel} from "./_model";

class UserInvoicesForm extends BaseForm {
    //public model = UserInvoicesModel;

    constructor(props: any) {
        super(props);
        super.model = UserInvoicesModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                user_id: '',
                user_checkpoint_id: '',
                tarif_id: '',
                sum: '',
                minutes: '',
                is_payed: '',
                payment_type: '',
                descr: '',
                created_at: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>
                    <InputField name={'id'}
                                model={this.model.info.name}
                                label={this.model.attrs.id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['id']}/>

                    <InputField name={'user_id'}
                                model={this.model.info.name}
                                label={this.model.attrs.user_id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['user_id']}/>

                    <InputField name={'user_checkpoint_id'}
                                model={this.model.info.name}
                                label={this.model.attrs.user_checkpoint_id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['user_checkpoint_id']}/>

                    <InputField name={'tarif_id'}
                                model={this.model.info.name}
                                label={this.model.attrs.tarif_id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['tarif_id']}/>

                    <InputField name={'sum'}
                                model={this.model.info.name}
                                label={this.model.attrs.sum.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['sum']}/>

                    <InputField name={'minutes'}
                                model={this.model.info.name}
                                label={this.model.attrs.minutes.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['minutes']}/>

                    <InputField name={'is_payed'}
                                model={this.model.info.name}
                                label={this.model.attrs.is_payed.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['is_payed']}/>

                    <InputField name={'payment_type'}
                                model={this.model.info.name}
                                label={this.model.attrs.payment_type.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['payment_type']}/>

                    <InputField name={'descr'}
                                model={this.model.info.name}
                                label={this.model.attrs.descr.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['descr']}/>

                    <InputField name={'created_at'}
                                model={this.model.info.name}
                                label={this.model.attrs.created_at.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['created_at']}/>

                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default UserInvoicesForm;
