/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import {Route} from "react-router-dom";
import WikiCategoriesCreate from './create';
import WikiCategoriesUpdate from './update';
import WikiCategoriesView from './view';
import WikiCategoriesIndex from './index';
import {WikiCategoriesModel} from "./_model";

const WikiCategoriesRoutes = [
    <>
        <Route path={WikiCategoriesModel.info.viewPath} element={<WikiCategoriesIndex/>}/>
        <Route path={WikiCategoriesModel.info.viewPath + '/create'} element={<WikiCategoriesCreate/>}/>
        <Route path={WikiCategoriesModel.info.viewPath + '/update/:id'} element={<WikiCategoriesUpdate/>}/>
        <Route path={WikiCategoriesModel.info.viewPath + '/view/:id'} element={<WikiCategoriesView/>}/>
    </>
];
export default WikiCategoriesRoutes;
