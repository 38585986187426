/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseCreatePage from "crud/base/BaseCreatePage";
import TableGamesPricesForm from "./_form";
import {TableGamesPricesModel} from "./_model";


class TableGamesPricesCreate extends BaseCreatePage {
    //public model = TableGamesPricesModel;
    //public FormComponent = TableGamesPricesForm;

    constructor(props: any) {
        super(props);
        super.model = TableGamesPricesModel;
        super.FormComponent = TableGamesPricesForm;
        super.relationTree = TableGamesPricesModel.relationTree
    }
}

export default TableGamesPricesCreate;