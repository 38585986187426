import React from 'react';
import ProfileImages from "../ProfileImages";
import {User} from "../../../models/users/User";
import {Card} from "react-bootstrap";

type Props = {
    profile?: User
}

const SettingsTab = (props: Props) => {
    return (
        <div>
            <Card>
                <ProfileImages images={props.profile?.userImages}/>
            </Card>
        </div>
    );
};

export default SettingsTab;
