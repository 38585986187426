/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const EventTypesModel: BaseModelInfo = {
    info: {
        name: "event-types",
        label: "Event Types",
        apiPath: "/rapi/event-types",
        viewPath: "/table-games/event-types"
    },
    attrs: {
        id: {label: "ID"},
        name: {label: "Name"},
        events: {label: '"Events"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },
    relationTree: {},
    defaultShowAttrs: ["name",]
};


