import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {User} from "../../../../models/users/User";
import {Card} from "react-bootstrap";
import DataTable from "../../../../crud/data_viewers/DataTable";
import {UsersModel} from "../../../users/_model";
import axios from "axios";
import {Constants} from "../../../../utils/constants";
import {authStore} from "../../../../store/AuthStore";
import Modal from "react-bootstrap/Modal";
import DateTimeField from "../../../../crud/FormInputs/DateTimeField";
import {UserUpdateStudDate} from "../../../../api/user/user";
import {toast} from "react-toastify";

type Props = {
    profile?: User
}


const InfoTab = (props: Props) => {
    const [showStudyModal, setShowStudyModal] = useState<boolean>(false);
    const [specialEndDate, setSpecialEndDate] = useState(props?.profile?.special_end_date);

    const barCodeActive = () => {
        let barCodeUrl = Constants.API_URL + `/api/scanner/scan?barcode=${props.profile?.barcode}`;
        axios.get(barCodeUrl, {})
            .then((resp) => console.log(resp.data))
            .catch((error) => console.log(error));
    }

    return (
        <div>
            <Modal size={"lg"}
                   show={showStudyModal}
                   onHide={() => setShowStudyModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Редактирование студенчиского билета</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <DateTimeField name={'from_dt'}
                                   dateFormat={"dd.MM.yyyy HH:mm"}
                                   model=""//todo?
                                   label="Действителен до "
                                   showTimeSelect={true}
                                   onChange={(v: any) => setSpecialEndDate(v)}
                                   value={specialEndDate}
                        /*onChange={(val: any) => this.handleInputChange({
                            name: "from_dt",
                            //value: val.toISOString().slice(0, 19).replace('T', ' ')
                            value: format(val, 'yyyy-MM-dd HH:mm:ss')
                        })}*/
                        //value={this.state.form.from_dt}
                    />
                    <button
                        className="btn btn-success"
                        onClick={() => {
                            UserUpdateStudDate(props.profile?.id, specialEndDate).then((resp: any) => {
                                console.log(resp.data)
                                toast.success("Сохранено")
                                setShowStudyModal(false)
                            });
                        }}>
                        Сохранить
                    </button>
                </Modal.Body>
            </Modal>

            <Card className="card-success">
                <Card.Body>
                    <div className="float-right">
                        <div className="m-1 btn btn-primary" onClick={() => setShowStudyModal(true)}>
                            Указать студенчиский
                        </div>
                        {
                            (authStore.isAdmin) &&
                            <Link className="m-1 btn btn-danger"
                                  to={UsersModel.info.viewPath + '/update/' + props?.profile?.id}>
                                Прямое редактирование
                            </Link>
                        }

                        <Link className="m-1 btn btn-info" onClick={barCodeActive} to="#">
                            Записать
                        </Link>
                    </div>
                    <DataTable
                        data={props.profile}
                        model={UsersModel}
                        disabledFields={[
                            "id",
                            "role_id", "" +
                            "userCheckpoints",
                            "userImages",
                            "userAuthLogins",
                            "userAuthEmails",
                            "is_dead_soul",
                            "userAuthTelegrams"]}
                    />
                </Card.Body>
            </Card>
        </div>
    );
};

export default InfoTab;
