import {makeAutoObservable, observable} from 'mobx'
import {CheckpointUser} from "../../models/users/UserCheckpoint";
import {KassaGetOnlineCheckpoints, KassaUpdateCheckpoint} from "../kassa/kassa";
import {toast} from "react-toastify";
import {UserInvoice} from "../../models/kassa/UserInvoice";

export const checkpointTreeJson = {
    relation: {
        tarif: [],
        user: {
            fields: ['id', 'image_name', 'name', 'barcode', 'balance'],
            relation: {'role': []}
        }
    }
}

export class CheckoutStore {

    constructor() {
        this._checkpoints = []
        //this._currentCheckpoint = null;
        this._currentCheckpoints = [];
        this._currentCheckInPoints = [];
        this._currentInvoice = null;
        this._currentCheckpointTabId = "";
        makeAutoObservable(this);
    }

    @observable private _currentCheckpointTabId: string;

    get currentCheckpointTabId(): string {
        return this._currentCheckpointTabId;
    }

    set currentCheckpointTabId(value: string) {
        this._currentCheckpointTabId = value;
    }

    @observable private _checkpoints: Array<CheckpointUser>;

    get checkpoints(): Array<CheckpointUser> {
        return this._checkpoints;
    }

    set checkpoints(value: Array<CheckpointUser>) {
        this._checkpoints = value;
    }

    @observable private _currentCheckInPoints: Array<CheckpointUser>;

    get currentCheckInPoints(): Array<CheckpointUser> {
        return this._currentCheckInPoints;
    }

    set currentCheckInPoints(value: Array<CheckpointUser>) {
        this._currentCheckInPoints = value;
    }

    @observable private _currentCheckpoints: Array<CheckpointUser>;

    get currentCheckpoints(): Array<CheckpointUser> {
        return this._currentCheckpoints;
    }

    @observable private _currentInvoice: UserInvoice | null;

    /*    set currentCheckpoints(value: CheckpointUser | null) {
            this._currentCheckpoints = value;
        }*/

    get currentInvoice(): UserInvoice | null {
        return this._currentInvoice;
    }

    set currentInvoice(value: UserInvoice | null) {
        console.log("currentInvoice", value)
        this._currentInvoice = value;
    }

    reCalcCurIndex = () => {
        if (checkoutStore.currentCheckInPoints.length >= 1) {
            this._currentCheckpointTabId = (checkoutStore.currentCheckInPoints[checkoutStore.currentCheckInPoints.length - 1]?.id?.toString());

        } else {
            this._currentCheckpointTabId = (checkoutStore.currentCheckpoints[checkoutStore.currentCheckpoints.length - 1]?.id?.toString());
        }
    }

    addCurrentCheckpoint(value: CheckpointUser) {
        //this._currentCheckpoints.push(value);

        const index = this._currentCheckpoints.findIndex(obj => obj.id === value?.id);
        if (index >= 0) {
            this._currentCheckpoints[index] = value;
        } else {
            this._currentCheckpoints.push(value);
        }
    }

    removeCurrentCheckpoint(checkpointId: number) {
        this._currentCheckpoints = this._currentCheckpoints.filter(obj => obj.id !== checkpointId);
    }

    removeCurrentCheckInPoint(checkpointId: number) {
        this._currentCheckInPoints = this._currentCheckInPoints.filter(obj => obj.id !== checkpointId);
    }

    removeNowDisplayedCheckpoint() {
        this._currentCheckpoints = this._currentCheckpoints.filter(obj => obj.id.toString() !== this._currentCheckpointTabId);
        this._currentCheckInPoints = this._currentCheckInPoints.filter(obj => obj.id.toString() !== this._currentCheckpointTabId);
        this.reCalcCurIndex();
    }

    getData = () => {
        KassaGetOnlineCheckpoints(checkpointTreeJson)
            .then((resp: any) => this.checkpoints = resp.data)
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
    }

    updateCheckpointField = (event: any, checkpointId: number) => {
        // const checkpointId = this.currentCheckpoint?.id ?? 0;
        let params: any = {}
        params[event?.name] = event?.value;
        KassaUpdateCheckpoint(checkpointId, params).then((resp) => {
                return true;
                //console.log(resp.data);
                //this.currentCheckpoint[event?.name] = event?.value;
            }
        )
    }
}


export const checkoutStore = new CheckoutStore()