/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";
import {RolesModel} from "../roles/_model";

export const UsersModel: BaseModelInfo = {
    info: {
        name: "cards",
        label: "Users",
        apiPath: "/rapi/cards",
        viewPath: "/Cards"
    },
    attrs: {
        id: {label: "ID"},
        current_module_name: {label: "Текущее название модуля бота"},
        role_id: {label: "Роль", params: {model: RolesModel}},
        barcode: {label: "Barcode"},
        name: {label: "ФИО"},
        email: {label: "Почта"},
        phone: {label: "Телефон"},
        comment: {label: "Комментарий"},
        balance: {label: "Баланс"},
        is_dead_soul: {label: "Флаг мертвых душ"},
        status: {label: "Статус"},
        created_at: {label: "Дата создания"},
        updated_at: {label: "Дата обновления"},
        birthday: {label: "Дата рождения"},
        tarif: {label: 'Тариф', type: FieldType.RelationOneData, params: {name: "name"}},
        role: {label: 'Роль', type: FieldType.RelationOneData, params: {name: "name"}},
        telegrambotUserMessages: {
            label: '"TelegrambotUserMessages"',
            type: FieldType.RelationOneData,
            params: {name: "name"}
        },
        telegrambotUserTriggers: {
            label: '"TelegrambotUserTriggers"',
            type: FieldType.RelationOneData,
            params: {name: "name"}
        },
        userCheckpoints: {
            label: '"UserCheckpoints"',
            type: FieldType.RelationOneData,
            params: {name: "name"}
        },
        userEvents: {
            label: '"UserEvents"',
            type: FieldType.RelationOneData,
            params: {name: "name"}
        },
        userInvoices: {
            label: '"UserInvoices"',
            type: FieldType.RelationOneData,
            params: {name: "name"}
        },
    },
    relationTree: {
        relation: {
            role: [],
            tarif: [],
        }
    },
    defaultShowAttrs: ["name", "barcode", "role", "tarif", "username", "fio", "updated_at"]
};


