import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {Button, Form, InputGroup, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import LoginAPI from "api/auth/login";
import {authStore} from "store/AuthStore";

const LoginPage = () => {
    const [isAuthLoading, setAuthLoading] = useState(false);

    const navigate = useNavigate();
    let location = useLocation();
    const [t] = useTranslation();

    const login = async (login: string, password: string) => {
        try {
            setAuthLoading(true);
            LoginAPI(login, password)
                .then((resp: any) => {
                    toast.success('Login is succeed!');
                    const token = resp.data.token;
                    console.log(resp)
                    if (token.length > 5) {
                        authStore.token = token;
                        authStore.profile = resp.data.profile
                        console.log(token);
                        window.location.href = "/"
                        //navigate("/index", {replace: true});
                    } else {
                        toast.error('Empty token')
                    }

                })
                .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));


            setAuthLoading(false);
        } catch (error: any) {
            setAuthLoading(false);
            toast.error(error.message || 'Failed');
        }
    };


    const {handleChange, values, handleSubmit, touched, errors} = useFormik({
        initialValues: {email: '', password: ''},
        validationSchema: Yup.object({
            email: Yup.string()
                //.email('Invalid email address')
                .required(t('validation.required') ?? "required"),
            password: Yup.string()
                .min(5, 'Must be 5 characters or more')
                .max(30, 'Must be 30 characters or less')
                .required(t('validation.required') ?? "required"),
        }),
        onSubmit: (values: any) => {
            login(values.email, values.password);
        }
    });

    //  setWindowClass('hold-transition login-page');

    return (
        <Row className="flex-column justify-content-center align-items-center mt-5">
            <div className="login-box">
                <div className="card card-outline card-success">
                    <div className="card-header text-center">
                        <Link to="/" className="h1">
                            <b>ZD</b>
                            <span>admin</span>
                        </Link>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">{t('login.label.signIn')}</p>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        id="email"
                                        name="email"
                                        /*type="email"*/
                                        placeholder="Email"
                                        onChange={handleChange}
                                        value={values.email}
                                        isValid={touched.email && !errors.email}
                                        isInvalid={touched.email && !!errors.email}
                                    />
                                    {touched.email && errors.email ? (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    ) : (
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={solid("envelope")}/>
                                        </InputGroup.Text>

                                    )}
                                </InputGroup>

                                <InputGroup className="mb-3">
                                    <Form.Control
                                        id="password"
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        value={values.password}
                                        isValid={touched.password && !errors.password}
                                        isInvalid={touched.password && !!errors.password}
                                    />
                                    {touched.password && errors.password ? (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    ) : (

                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={solid("lock")}/>
                                        </InputGroup.Text>
                                    )}
                                </InputGroup>
                            </div>

                            <div className="row">
                                <div className="col-8">
                                    {/*  <Checkbox checked={false}>
                                    {t<string>('login.label.rememberMe')}
                                </Checkbox>*/
                                    }
                                </div>
                                <div className="col-4">

                                </div>
                            </div>
                            <div className="social-auth-links text-center mt-2 mb-3">
                                <Button
                                    type="submit"
                                    //loading={isAuthLoading}
                                    disabled={isAuthLoading}
                                >
                                    {t<string>('login.button.signIn.label')}
                                </Button>
                            </div>
                        </form>


                        <p className="mb-1">
                            <Link to="/forgot-password">
                                {t<string>('login.label.forgotPass')}
                            </Link>
                        </p>
                        <p className="mb-0">
                            <Link to="/register" className="text-center">
                                {t<string>('login.label.registerNew')}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </Row>
    );
};

export default LoginPage;
