/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import TableGamesPricesCreate from './create';
import TableGamesPricesUpdate from './update';
import TableGamesPricesView from './view';
import TableGamesPricesIndex from './index';
import {TableGamesPricesModel} from "./_model";

const TableGamesPricesRoutes = [
    <>
        <Route path={TableGamesPricesModel.info.viewPath} element={<TableGamesPricesIndex/>}/>
        <Route path={TableGamesPricesModel.info.viewPath + '/create'} element={<TableGamesPricesCreate/>}/>
        <Route path={TableGamesPricesModel.info.viewPath + '/update/:id'} element={<TableGamesPricesUpdate/>}/>
        <Route path={TableGamesPricesModel.info.viewPath + '/view/:id'} element={<TableGamesPricesView/>}/>
    </>
];
export default TableGamesPricesRoutes;
