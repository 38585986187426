/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const RoleGroupsModel: BaseModelInfo = {
    info: {
        name: "role-groups",
        label: "Role Groups",
        apiPath: "/rapi/role-groups",
        viewPath: "/role-groups"
    },
    attrs: {
        id: {label: "ID"},
        name: {label: "название"},
        descr: {label: "Описание"},
        //roles: {label:'"Roles"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },
    relationTree: {},
    defaultShowAttrs: ["name", "descr",]
};


