/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";
import PriceWidget from "../kassa/widgets/PriceWidget";
import {getCheckpointStatus, getInvoicePayStatus} from "../kassa/helpers";
import UserProfileView from "../users/UserProfileView";

export const UserCheckpointsModel: BaseModelInfo = {
    info: {
        name: "Checkpoints",
        label: "Посещения",
        apiPath: "/rapi/user-checkpoints",
        //apiPath: "/rapi/user-checkpoints/history/",
        viewPath: "/user-checkpoints"
    },
    attrs: {
        id: {label: "ID"},
        user: {label: 'Пользователь', type: FieldType.RelationOneData, params: {name: "name"},},
        profile: {
            sourceAttr: "user",
            label: "Профиль",
            type: FieldType.Custom,
            params: (val: any) => {
                //alert(val)
                return <UserProfileView id={val?.id} image_name={val?.image_name} name={val?.name}
                                        role={val?.role?.name}/>;
            }
        },

        created_at: {label: "Дата входа", type: FieldType.MomentDate},
        outside_at: {label: "Дата выхода", type: FieldType.MomentDate},
        updated_at: {label: "Дата обновления", type: FieldType.MomentDate},

        role: {
            label: "Роль",
            sourceAttr: "user",
            sortField: "",
            searchField: "",
            type: FieldType.Custom,
            params: (val: any) => <span>{val?.role?.name}</span>
        },
        tarif: {
            label: 'Тариф',
            type: FieldType.RelationOneData,
            params: {name: "name"},
            searchField: "",
            sortField: "tarif_id"
        },

        user_id: {label: "ID Пользователя"},
        tarif_id: {label: "ID тарифa"},
        is_inside: {label: "флаг входа"},
        is_outside: {label: "флаг выхода"},
        from: {label: "источник"},
        is_payed: {label: "-1 без оплаты, 0 не оплачено, 1 оплачено"},
        is_first_time: {label: "Перворазник", type: FieldType.Bool},
        is_flyer_invited: {label: "По флаеру", type: FieldType.Bool},
        is_prepayment: {label: "Предоплата", type: FieldType.Bool},
        is_reserved: {label: "Пришел на бронь", type: FieldType.Bool},
        is_help: {label: "Помогал в смене", type: FieldType.Bool},
        user_turn_id: {label: "Смена №"},


        // created_at: {label: "дата входа"},
        // outside_at: {label: "дата выхода"},

        // role: {label: 'Роль', type: FieldType.RelationOneData, params: {name: "name"}},


        //userInvoices: {label: '"UserInvoices"', type: FieldType.RelationOneData, params: {name: "name"}},

        userInvoices: {
            label: '"Счета"',
            type: FieldType.RelationMany,
            params: {
                name: "id",
                model: {info: {viewPath: "/user-invoices"}}
            },
        },


        minutes: {label: "Просидел", type: FieldType.Custom, params: (val: any) => <span>{val}мин</span>},
        sum: {label: "Сумма", type: FieldType.Custom, params: (val: any) => <PriceWidget value={val}/>},
        status: {
            sourceAttr: null,
            label: "Статус",
            searchField: "status",
            sortField: "status",
            type: FieldType.Custom,
            params: (val?: any) => <span>{getCheckpointStatus(val)} {getInvoicePayStatus(val)} </span>
        },
    },
    relationTree: {
        relation: {
            tarif: [],
            user: {
                fields: ['id', 'image_name', 'name', 'barcode', 'balance'],
                relation: {'role': []}
            }
        },
        relations: {
            userInvoices: []
        }
    },
    defaultShowAttrs: ["profile", "created_at", "minutes", "role", "tarif", "sum", "status"]
    //defaultShowAttrs: ["user_id", "tarif_id", "is_inside", "is_outside", "from",]
};


