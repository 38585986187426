import React from 'react';
import {Modal, Tab, Tabs} from "react-bootstrap";
import {checkoutStore} from "../../../api/store/CheckoutStore";
import {observer} from "mobx-react-lite";
import {CheckpointModal} from "./CheckpointModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

export const CheckpointsModal = observer(() => {


    let showModal = (checkoutStore.currentCheckpoints.length >= 1) || (checkoutStore.currentCheckInPoints.length >= 1);

    return <Modal size="lg"
                  variant="primary"
                  show={showModal}
                  key={"sdsd"}
        //onHide={() => checkoutStore.removeNowDisplayedCheckpoint()}

    >
        <Modal.Body className="modal-info" key="ked">
            <Tabs
                //activeKey={}
                //defaultActiveKey={defaultActiveKey}
                activeKey={checkoutStore.currentCheckpointTabId}
                onSelect={(k) => checkoutStore.currentCheckpointTabId = (k ?? "")}
                id="uncontrolled-tab-example222"
                className="mb-3"
            >
                {
                    checkoutStore.currentCheckInPoints.map((checkpoint) => {
                        console.log("currentCheckInPoints", checkpoint.id)
                        return <Tab
                            eventKey={checkpoint.id}
                            title={<>
                                {checkpoint.user.name}
                                <FontAwesomeIcon icon={faClose}
                                                 color="red"
                                                 className="pl-2"
                                                 onClick={() => {
                                                     checkoutStore.removeCurrentCheckInPoint(checkpoint.id);
                                                 }}
                                /></>
                            }
                            tabClassName="bg-success">
                            <CheckpointModal checkpoint={checkpoint} checkIn={true}/>
                        </Tab>;
                    })
                }
                {
                    checkoutStore.currentCheckpoints.map((checkpoint) => {
                        console.log("currentCheckpoints", checkpoint.id)
                        return <Tab eventKey={checkpoint.id}
                                    key={checkpoint.id}
                                    title={<>
                                        {checkpoint.user.name}
                                        <FontAwesomeIcon icon={faClose}
                                                         color="red"
                                                         className="pl-2"
                                                         onClick={() => {
                                                             checkoutStore.removeCurrentCheckpoint(checkpoint.id);
                                                         }}
                                        /></>
                                    }>
                            <CheckpointModal checkpoint={checkpoint} checkIn={false}/>
                        </Tab>;
                    })
                }
            </Tabs>

        </Modal.Body>
    </Modal>
})
