/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TableGamesPlacesModel} from "./_model";

class TableGamesPlacesIndex extends BaseIndexPage {
    //public model = TableGamesPlacesModel;

    constructor(props: any) {
        super(props);
        super.model = TableGamesPlacesModel;
        super.defaultShowAttrs = TableGamesPlacesModel.defaultShowAttrs;
        super.relationTree = TableGamesPlacesModel.relationTree
    }
}

export default TableGamesPlacesIndex;