import {Component} from 'react'
import {prepareLabel} from '../api/yiiValidation'

type Props = {
    name: string,
    model: string,
    label: boolean | string,
    class: string,
    options: any,
    value: string | number,
    placeholder: string,
    onChange: Function,
    pluginProps?: any,
    required?: boolean,
};

interface State {
    //labelName: string,
    helpBlock: string,
    validation: string | boolean,
}

class RadioButton extends Component<Props, State> {
    private labelName: any;

    constructor(props: Props) {
        super(props)

        this.state = {
            helpBlock: '',
            validation: ''
        }
        this.labelName = prepareLabel(this.props.label, this.props.name)
        this.editApi = this.editApi.bind(this)
    }

    radioItem() {
        let array: any = []
        Object.entries(this.props.options).map((data: any) => {
            let value = false;
            if (data[0] === this.props.value) {
                value = true;
            }

            array.push(
                <div key={'divRadio-' + data[0]} className={'form-check form-check-inline'}>
                    <input
                        {...this.props.pluginProps}
                        id={this.props.model + '-' + this.props.name + ' radio-' + data[0]}
                        className={'form-check-input ' + (this.state.validation !== '' ? (this.state.validation === true) ? 'is-valid' : 'is-invalid' : '')}
                        type={'radio'}
                        name={this.props.name}
                        key={'radio-' + data[0]}
                        checked={value}
                        onChange={(event) => this.editApi(event, data[0])}
                    />
                    <label key={'labelForRadio-' + data[0]} className={'form-check-label'}
                           htmlFor={this.props.model + '-' + this.props.name + ' radio-' + data[0]}>{data[1]}</label>
                </div>
            )
        })
        return array
    }

    editApi = async (event: any, radioItemId: any) => {
        //this.setState({value: event.target.value});
        this.props.onChange(radioItemId)
        //console.log(event.target.value);
    }


    render() {
        let required = (this.props.pluginProps !== undefined) ? (this.props.pluginProps.hasOwnProperty("required") ? 'required' : '') : '';

        return (
            <div className={'form-group field-' + this.props.model + '-' + this.props.name + ' ' + this.props.class}>
                <div>
                    <label className={'control-label ' + required}
                           htmlFor={this.props.model + '-' + this.props.name}>
                        {(this.labelName)}
                    </label>
                </div>
                {(this.radioItem())}
                <div
                    className={(this.state.validation !== '' ? (this.state.validation === true) ? 'valid-feedback' : 'invalid-feedback' : '')}>{this.state.helpBlock}</div>
            </div>
        )
    }
}

export default RadioButton;
