/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {TarifsModel} from "./_model";

class TarifsView extends BaseViewPage {
    //public model = TarifsModel;

    constructor(props: any) {
        super(props);
        super.model = TarifsModel;
        super.relationTree = TarifsModel.relationTree
    }
}

export default withRouter(TarifsView);