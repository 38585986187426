import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import React from "react";

export function BaseDeleteModal({show, onClose, onDelete}: { show: boolean, onClose: any, onDelete: any }) {
    return <Modal show={show} onHide={onClose}>
        <Modal.Header>
            <h5 className="modal-title">Удаление</h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <p>Хотите удалить этот элемент? Точно?</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>Отмена</Button>
            <button type="button" className="btn btn-danger" onClick={onDelete}>УНИЧТОЖИТЬ!</button>
        </Modal.Footer>
    </Modal>
}