/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField} from "crud";
import BaseForm from "crud/base/BaseForm";
import RelationOneDropDown from "crud/FormInputs/RelationOneDropDown";
import {UsersModel} from "./_model";
import {Row} from "react-bootstrap";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DateTimeField from "../../crud/FormInputs/DateTimeField";

class UsersForm extends BaseForm {
    //public model = UsersModel;

    constructor(props: any) {
        super(props);
        super.model = UsersModel;

        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                telegram_chat_id: '',
                current_module_id: '',
                current_module_name: '',
                role_id: '',
                barcode: '',
                username: '',
                telegram_name: '',
                fio: '',
                password_hash: '',
                email: '',
                vk_link: '',
                fb_link: '',
                phone: '',
                comment: '',
                balance: '',
                is_dead_soul: '',
                status: '',
                created_at: '',
                updated_at: '',
                birthday: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>

                    {/* <InputField name={'current_module_name'}
                                model={this.model.info.name}
                                label={this.model.attrs.current_module_name.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['current_module_name']}/>*/}


                    <InputField name={'name'}
                                placeholder="Фамилия Имя Отчество"
                                model={this.model.info.name}
                                label={this.model.attrs.name.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['name']}/>
                    {/*
                    <InputField name={'password_hash'}
                                model={this.model.info.name}
                                label={this.model.attrs.password_hash.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['password_hash']}/>*/}


                    <Row className="row-cols-2">
                        <InputField name={'phone'} model={this.model.info.name}
                                    label={<FontAwesomeIcon icon={faPhone}/>}
                                    placeholder="+7 (XXX) XXX-XX-XX" class="p-2"
                                    onChange={this.handleInputChange} value={this.state.form['phone']}/>

                        <InputField name={'email'} model={this.model.info.name}
                                    required={true}
                                    label={<FontAwesomeIcon icon={faEnvelope}/>}
                                    placeholder="e@mail.ru" class="p-2"
                                    onChange={this.handleInputChange} value={this.state.form['email']}/>
                    </Row>

                    <DateTimeField name={'birthday'}
                                   model={this.model.info.name}
                                   label="День рождения"
                                   placeholder="00.00.0000" value={this.state.form['birthday']}
                                   onChange={(date: any) => {
                                       let form = this.state.form;
                                       form['birthday'] = date;
                                       this.setState({form: form});
                                   }}
                    />


                    <InputField name={'comment'}
                                model={this.model.info.name}
                                label={this.model.attrs.comment.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['comment']}/>

                    <InputField name={'balance'}
                                model={this.model.info.name}
                                label={this.model.attrs.balance.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['balance']}/>


                    <hr/>
                    <RelationOneDropDown
                        name={'role_id'}
                        model={this.model.info.name}
                        label={this.model.attrs?.role_id?.label}
                        placeholder="Выберите роль"
                        relationUrl={this.model.attrs.role_id.params.model?.info?.apiPath + "/index"}
                        onChange={(val: any) => this.handleInputChange({name: "role_id", value: val.id})}
                        value={this.state.form['role_id']}
                        class=""/>

                    <InputField name={'barcode'}
                                model={this.model.info.name}
                                label={this.model.attrs.barcode.label}
                                onChange={this.handleInputChange} value={this.state.form['barcode']}/>


                    <InputField name={'status'}
                                model={this.model.info.name}
                                label={this.model.attrs.status.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['status']}/>

                    <Row className="row-cols-2">
                        <InputField name="vk_link"
                                    model={this.model.info.name}
                                    label={<b>vk.com://</b>}
                                    onChange={this.handleInputChange} value={this.state.form['vk_link']} class="p-2"/>

                        <InputField name={'fb_link'}
                                    model={this.model.info.name}
                                    label={'Fb Link'}
                                    onChange={this.handleInputChange} value={this.state.form['fb_link']} class="p-2"/>
                    </Row>

                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default UsersForm;
