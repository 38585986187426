import React from 'react'
import {breadcrumbsStore} from "../../../store/BreadcrumbsStore";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";

const BreadCrumbs = observer(() => {
    return (
        <ol className='breadcrumb m-0'>
            {
                breadcrumbsStore.items.map((item: any, i) => (
                    <li key={item.name + i}
                        className={(item.link === '') ? "breadcrumb-item active" : "breadcrumb-item "}>
                        <Link to={item.link}>{item.name}</Link>
                    </li>

                    /* <Breadcrumb.Item
                             key={item.name}

                             href={item.link}
                         >
                             {item.name}
                         </Breadcrumb.Item>*/
                ))
            }
        </ol>
    )
});
export default BreadCrumbs
