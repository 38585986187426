import React, {Component} from 'react';
import {Translation} from "react-i18next";
import {Card} from "react-bootstrap";
import {breadcrumbsStore} from "store/BreadcrumbsStore";
import BaseForm from "./BaseForm";
import {BaseModelInfo, defaultModel} from "../types/BaseModelInfo";

type State = {
    model: any,
    isDataLoaded: boolean
}

class BaseCreatePage extends Component<any, State> {
    public model: BaseModelInfo = defaultModel;
    public relationTree = {}
    public FormComponent = BaseForm;

    constructor(props: any) {
        super(props);
        this.state = {model: {}, isDataLoaded: false}
    }

    componentDidMount() {
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
            {name: this.model.info.label, link: this.model.info.viewPath},
            {name: <Translation>{(t: any) => t('crud.create')}</Translation>, link: ''},
        ]
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    <Translation>{(t: any) => t('crud.create')}</Translation> {this.model.info.label}
                </Card.Header>
                <Card.Body>
                    <this.FormComponent action={this.model.info.apiPath + '/create'} data={{}}/>
                </Card.Body>
            </Card>
        );
    }
}

export default BaseCreatePage;