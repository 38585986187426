import React, {useState} from 'react';
import {Card, Row} from "react-bootstrap";

export const KassaCash = () => {
    //const [currentUsers, setCurrentUsers] = useState<Array<CheckpointUser>>([]);

    // const [currentUser, setCurrentUser] = useState<CheckpointUser | null>(null);
    const [messageHistory, setMessageHistory] = useState<any>([]);


    return (
        <div className="pages-index">
            <Row>
                <div className="col-md-4">
                    <Card className="card">
                        <table className="table table-sm">
                            <thead>
                            <tr>
                                <th>Номинал</th>
                                <th>Колличество</th>
                                <th>Сумма</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style={{backgroundColor: "#f9d4aa"}}>
                                <td>5000</td>
                                <td>1</td>
                                <td>5000</td>
                            </tr>
                            <tr style={{backgroundColor: "#8cc0cb"}}>
                                <td>1000</td>
                                <td>3</td>
                                <td>3000</td>
                            </tr>

                            <tr style={{backgroundColor: "#d6b7c4"}}>
                                <td>500</td>
                                <td>1</td>
                                <td>500</td>
                            </tr>
                            <tr style={{backgroundColor: "#dbc18e"}}>
                                <td>100</td>
                                <td>1</td>
                                <td>1000</td>
                            </tr>
                            <tr style={{backgroundColor: "#a5c1b0"}}>
                                <td>50</td>
                                <td>1</td>
                                <td>50</td>
                            </tr>
                            <tr style={{backgroundColor: "#d4d8cc"}}>
                                <td>10</td>
                                <td>1</td>
                                <td>10</td>
                            </tr>
                            <hr/>
                            <tr style={{backgroundColor: "#d3cabd"}}>
                                <td>5</td>
                                <td>2</td>
                                <td>10</td>
                            </tr>
                            <tr style={{backgroundColor: "#d1c8b1"}}>
                                <td>2</td>
                                <td>2</td>
                                <td>4</td>
                            </tr>
                            </tbody>
                        </table>
                    </Card>
                </div>

                <div className="col-md-2">
                    <Card className="card">
                        <Card.Body className="card-body">
                            <div id="wsDebug">
                                <ul>
                                    {messageHistory.map((message: any, idx: number) => (
                                        <li key={idx}>
                                            {/*   <Moment>message.timeStamp</Moment>*/}
                                            <small>{message ? JSON.parse(message).barcode : message}</small>
                                        </li>
                                    ))}
                                </ul>
                                <br/>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    );
}