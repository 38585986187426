import React from "react";
import {ButtonGroup, Card, Col, Dropdown, DropdownButton, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {CamViewState} from "./types";

type Props = {
    value: CamViewState,
}
export default function CamToolbar(props: Props) {

    return (
        <Card className="p-2">
            <Form>
                <Row>
                    <Col>
                        <DropdownButton as={ButtonGroup}
                                        onClick={(e) => console.log(e)}
                                        title={<>{props.value.cols}x?&nbsp;<FontAwesomeIcon
                                            icon={solid("table-cells-large")}/></>}
                                        id="bg-vertical-dropdown-2">
                            <Dropdown.Item value="1">1x?</Dropdown.Item>
                            <Dropdown.Item value="2">2x?</Dropdown.Item>
                            <Dropdown.Item value="3">3x?</Dropdown.Item>
                            <Dropdown.Item value="4">4x?</Dropdown.Item>
                        </DropdownButton>
                    </Col>


                    <Col>
                        <DropdownButton as={ButtonGroup}
                                        onClick={(e) => console.log(e)}
                                        title={<>800x600&nbsp;<FontAwesomeIcon icon={solid("display")}/></>}
                                        id="bg-vertical-dropdown-2">
                            <Dropdown.Item value="1">320x640</Dropdown.Item>
                            <Dropdown.Item value="2">800x600</Dropdown.Item>
                            <Dropdown.Item value="3">1024x</Dropdown.Item>
                            <Dropdown.Item value="4">1248</Dropdown.Item>
                        </DropdownButton>
                    </Col>
                    <Col>


                        <Form.Group controlId="formBasicRange">
                            <Form.Label>Качество</Form.Label>
                            <Form.Control type="range"/>
                        </Form.Group>

                    </Col>


                </Row>
            </Form>
        </Card>
    )
}

