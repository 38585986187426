/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {WikiPagesModel} from "./_model";

class WikiPagesView extends BaseViewPage {
    //public model = WikiPagesModel;

    constructor(props: any) {
        super(props);
        super.model = WikiPagesModel;
        super.relationTree = WikiPagesModel.relationTree
    }
}

export default withRouter(WikiPagesView);