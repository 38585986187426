/**
 * Generated by ReactYii 3.0.27 at 15.08.2023
 */
import BaseIndexPage from "crud/base/BaseIndexPage";
import {UserPaymentsModel} from "./_model";
import {Pagination} from "../../crud/types/Pagination";

class UserPaymentsIndex extends BaseIndexPage {
    //public model = UserPaymentsModel;

    constructor(props: any) {
        super(props);
        super.model = UserPaymentsModel;
        super.defaultShowAttrs = UserPaymentsModel.defaultShowAttrs;
        super.relationTree = UserPaymentsModel.relationTree
        this.state = {
            data: {data: [], pagination: new Pagination()},
            isLoadedFirstPage: false,
            hasError: false,
            hasMore: true,
            sortBy: "-id", //id,-id, someField
            searchArray: {}, //id=2&someField=someValue&
            showAttr: {},
        };
    }
}

export default UserPaymentsIndex;