/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import TarifsCreate from './create';
import TarifsUpdate from './update';
import TarifsView from './view';
import TarifsIndex from './index';
import {TarifsModel} from "./_model";

const TarifsRoutes = [
    <>
        <Route path={TarifsModel.info.viewPath} element={<TarifsIndex/>}/>
        <Route path={TarifsModel.info.viewPath + '/create'} element={<TarifsCreate/>}/>
        <Route path={TarifsModel.info.viewPath + '/update/:id'} element={<TarifsUpdate/>}/>
        <Route path={TarifsModel.info.viewPath + '/view/:id'} element={<TarifsView/>}/>
    </>
];
export default TarifsRoutes;
