/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import BaseIndexPage from "crud/base/BaseIndexPage";
import {EventCalendarsModel} from "./_model";

class EventCalendarsIndex extends BaseIndexPage {
    //public model = EventCalendarsModel;

    constructor(props: any) {
        super(props);
        super.model = EventCalendarsModel;
        super.defaultShowAttrs = EventCalendarsModel.defaultShowAttrs;
        super.relationTree = EventCalendarsModel.relationTree
    }
}

export default EventCalendarsIndex;