/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";
import {TagsModel} from "../../tags/_model";
import {WikiCategoriesModel} from "../wiki-categories/_model";

export const WikiPagesModel: BaseModelInfo = {
    info: {
        name: "wiki-pages",
        label: "Wiki Pages",
        apiPath: "/rapi/wiki-pages",
        viewPath: "/wiki/wiki-pages"
    },
    attrs: {
        id: {label: "ID"},
        wiki_category_id: {label: "Wiki Category ID", type: FieldType.RelationOneID},
        title: {label: "Заголовок"},
        text: {label: "Текст страницы", type: FieldType.MarkDown},
        wikiTags: {label: '"WikiTags"', type: FieldType.RelationOneData, params: {name: "name", model: TagsModel}},
        wikiCategory: {
            label: 'Категория',
            type: FieldType.RelationOneData,
            params: {name: "name", model: WikiCategoriesModel}
        },
        wikiToTags: {label: '"WikiToTags"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },
    relationTree: {
        /*relations: [
            "wikiTags"
        ]*/
    },
    defaultShowAttrs: ["wiki_category_id", "title", "text",]
};


