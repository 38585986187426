import React, {Component} from "react";
import {IStateCrudIndex} from "../types/IStateCrudIndex";
import {Card} from "react-bootstrap";
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";
import TableIndex from "../TableIndex/TableIndex";
import {AttrInfoArray} from "../types/AttrInfo";
import MainLoader from "../../ui/loaders/MainLoader";
import ErrorWidget from "../components/ErrorWidget";
import {Pagination} from "../types/Pagination";
import {GetModels} from "../api/crudApi";
import {toast} from "react-toastify";
import {Translation} from "react-i18next";
import {FieldType} from "../types/FieldType";
import {BaseModelInfo, defaultModel, IRelationTree} from "../types/BaseModelInfo";
import {Link} from "react-router-dom";

class BaseIndexPage extends Component<any, IStateCrudIndex> {
    public model: BaseModelInfo = defaultModel;
    public relationTree: IRelationTree = {}
    public defaultShowAttrs: string[] = []// = ["id", "name"];

    constructor(props: any) {
        super(props);
        this.state = {
            data: {data: [], pagination: new Pagination()},
            isLoadedFirstPage: false,
            hasError: false,
            hasMore: true,
            sortBy: "", //id,-id, someField
            searchArray: {}, //id=2&someField=someValue&
            showAttr: {},
        };
    }

    componentDidMount() {
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
            {name: this.model.info.label, link: ''}
        ];
        this.getData(this.state.data.pagination, true);
    }

    getData = (pagination?: Pagination, replace = false, sortBy = this.state.sortBy, search: object = {}) => {
        this.setState({sortBy: sortBy, searchArray: search});

        GetModels(this.model.info.apiPath, JSON.stringify(search), sortBy, this.relationTree, pagination)
            .then((resp: any) => {
                // if (page + 1 >= resp.pagination.pageCount) {
                if ((this.state.data.pagination.page + 1) >= this.state.data.pagination.pageCount) {
                    this.setState({hasMore: false});
                }

                if (!replace && (this.state.data.data?.length ?? 0) > 0) {
                    resp.data = [...this.state.data.data, ...resp.data.data]
                }

                this.setState({data: resp, isLoadedFirstPage: true});
            })
            .catch((error) => {
                console.log(error)
                toast.error(error?.response?.data?.message || "Failed")
                this.setState({hasError: true})
            });
    };

    /*loadMore = () => {
        if (this.state.data.pagination.page < this.state.data.pagination.pageCount) {
            this.getData(this.state.data.pagination.page + 1, false, this.state.sortBy, this.state.searchString);
        } else {
            this.setState({hasMore: false});
        }
    };*/

    actionPagination = (pagination: Pagination) => this.getData(pagination, true, this.state.sortBy, this.state.searchArray);

    onSortChange = (sortBy: string) => this.getData(undefined, true, sortBy, this.state.searchArray);

    onSearch = (searchArray: object) => this.getData(undefined, true, this.state.sortBy, searchArray);

    onTreeChange = (attrArray: AttrInfoArray) => {
        let relations: any = {};
        let relation: any = {};

        for (const attrName in attrArray) {
            console.log(attrName)
            const attr = attrArray[attrName];
            if (attr?.type === FieldType.RelationMany) {
                relations[attrName] = [];
                //return (relations[attrName] = []);
            }
            if (attr?.type === FieldType.RelationOneData || attr?.type === FieldType.RelationOneID) {
                relation[attrName] = [];
                //console.log("FIND relationOne in enable attrs", attr)
            }
        }


        this.relationTree = {relation: relation, relations: relations};
        this.getData(this.state.data.pagination, true, this.state.sortBy, this.state.searchArray);
    };

    render() {
        if (this.state.hasError) {
            return <ErrorWidget/>;
        }

        return (
            <Card className="card-outline card-success">
                <Card.Header>
                    <Card.Title>{this.model.info.label}</Card.Title>
                    <div className="card-tools">
                        <Link className="btn-outline-success btn btn-sm" to={'create'}>Create</Link>
                    </div>
                </Card.Header>

                <Card.Body className="p-0">
                    {this.state.isLoadedFirstPage ? (
                        <>
                            {/* <InfiniteScroll
                                dataLength={this.state.data.data.length}
                                next={this.loadMore}
                                //hasMore={this.state.hasMore}
                                hasMore={false}
                                loader={<MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>}
                                //endMessage={<p style={{textAlign: 'center'}}>На этом все</p>}
                                endMessage=""*/}
                            <TableIndex
                                model={this.model}
                                data={this.state.data.data}
                                enabledAttributes={this.defaultShowAttrs}
                                actions={{update: {title: "update"}}}
                                pagination={{data: this.state.data.pagination, callback: this.actionPagination}}
                                sortBy={this.state.sortBy}
                                onSortChange={this.onSortChange}
                                searchQuery={this.state.searchArray}
                                onSearch={this.onSearch}
                                onRelationTreeChange={this.onTreeChange}
                            />
                            {/*  <span>
                                Страница: {this.state.data.pagination.page} из {this.state.data.pagination.pageCount}
                                <br/>
                            Элементы: {this.state.data.pagination.pageSize}/{this.state.data.pagination.totalItems},
                            </span>*/}
                        </>
                    ) : (
                        <MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>
                    )}
                </Card.Body>
            </Card>
        );
    }
}

export default BaseIndexPage;