import React from 'react';
import {InvoiceModal} from "../../modals/InvoiceModal";
import TableIndex from "../../../../crud/TableIndex/TableIndex";
import {Pagination} from "../../../../crud/types/Pagination";
import BaseIndexPage from "../../../../crud/base/BaseIndexPage";
import ErrorWidget from "../../../../crud/components/ErrorWidget";
import {Card} from "react-bootstrap";
import MainLoader from "../../../../ui/loaders/MainLoader";
import {UserPaymentsModel} from "../../../user-payments/_model";
import {PaymentsCreateModal} from "./payments_create_modal";


class PaymentsTable extends BaseIndexPage {
    //public model = UserInvoicesModel;

    constructor(props: any) {
        super(props);
        super.model = UserPaymentsModel;
        super.defaultShowAttrs = UserPaymentsModel.defaultShowAttrs;
        super.relationTree = UserPaymentsModel.relationTree;
        super.state = {
            data: {data: [], pagination: new Pagination()},
            isLoadedFirstPage: false,
            hasError: false,
            hasMore: true,
            sortBy: "-id", //id,-id, someField
            searchArray: {}, //id=2&someField=someValue&
            showAttr: {},
            options: null,
        };
        //this.state.sortBy ="id"
        // super.onSortChange("-id")
    }

    render() {
        if (this.state.hasError) {
            return <ErrorWidget/>;
        }

        return (
            <>
                <InvoiceModal onClose={() => this.setState({options: null})} invoice={this.state.options?.invoice}/>
                <PaymentsCreateModal onClose={() => this.setState({options: null})} isShow={this.state.options?.createModal ?? false}/>
                <Card className="card-outline card-success">
                    <Card.Body>
                        {this.state.isLoadedFirstPage ? (
                            <>
                                <TableIndex
                                    model={this.model}
                                    data={this.state.data.data}
                                    enabledAttributes={this.defaultShowAttrs}
                                    pagination={{data: this.state.data.pagination, callback: this.actionPagination}}
                                    sortBy={this.state.sortBy}
                                    onSortChange={this.onSortChange}
                                    searchQuery={this.state.searchArray}
                                    onSearch={this.onSearch}
                                    onCreateClick={() => {
                                        this.setState({options:{"createModal":true}});
                                    }}
                                    onRelationTreeChange={this.onTreeChange}
                                    onRowClick={(invoice) => {
                                        console.log(invoice)
                                        this.setState({options:{"invoice":invoice}});
                                    }}
                                />
                            </>
                        ) : (
                            <MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>
                        )}
                    </Card.Body>
                </Card>
            </>
        );
    }
}


export {PaymentsTable};

/*
const InvoicesTableF = () => {
    const [userInvoices, setUserInvoices] = useState<Array<UserInvoice>>([]);
    const [currentInvoice, setCurrentInvoice] = useState<UserInvoice | null>(null);
    const [pagination, setPagination] = useState<Pagination>(new Pagination());

    const getData = () => {
        const treeJson = {
            relation: {
                tarif: [],
                user: {
                    fields: ['image_name', 'id', 'barcode', 'name', 'balance'],
                    relation: {'roles': []}
                }
            }
        }

        Invoices(treeJson)
            .then((resp: any) => {
                setUserInvoices(resp.data)
                //setPagination(resp.pagination)
                if (JSON.stringify(resp.pagination) !== JSON.stringify(pagination)) {
                    setPagination(resp.pagination)
                }
            })
            //.finally(() => setDataLoaded(true))
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
    }

    useEffect(() => getData(), [])
    useEffect(() => getData(), [ setPagination])


    return (
        <div className="table-responsive-sm">
            <InvoiceModal onClose={() => setCurrentInvoice(null)} invoice={currentInvoice}/>
            <TableIndex
                model={UserInvoicesModel}
                data={userInvoices}
                enabledAttributes={UserInvoicesModel.defaultShowAttrs}
                showSettings={true}
                pagination={{
                    data: pagination,
                    callback: setPagination
                }}
                onRowClick={(invoice) => setCurrentInvoice(invoice)}
                sortBy="-id"
                //onSortChange={this.onSortChange}
                //searchQuery={this.state.searchString}
                //onSearch={this.onSearch}
                onRelationTreeChange={() => {
                }}
            />
        </div>
    )
}
*/
