import React, {ReactElement, useState} from 'react'
import {InputGroup} from "react-bootstrap";
import DatePicker, {registerLocale} from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import {AttrInfo} from "../types/AttrInfo";

export type Props = {
    /** Name of field in model. */
    name: string,
    /** Name of a model. */
    model: string,
    /** Value of field. */
    value: any,
    /** Label for input. If empty or bool:false, using a field name. */
    label: string | ReactElement,
    /** Add more classes to field container. */
    class?: string,
    /** Placeholder for input. */
    placeholder?: string,
    /** Function, that handle change event. */
    onChange: Function,
    /** Required. */
    required?: boolean,
    disabled?: boolean,
    /** Help block text */
    helpBlock?: string,
    validated?: any,
    /** Other props for plugin */
    pluginProps?: any,

    dateFormat?: string
    showTimeSelect?: boolean

    attr?: AttrInfo,
    size?: 'sm' | 'lg',
};
export default function DateTimeField(props: Props) {
    const [helpBlock,] = useState("");
    const [validated,] = useState<boolean | undefined>();
    //TODO now ignore props.validated
    registerLocale('ru', ru)

    const onChangeValidate = (date: Date | null) => {
        // let value = format(date, 'dd.MM.yyyy')
        console.log(date)
        props.onChange(date)

        /* if (props.model !== undefined && props.model !== '') {
             validate(
                 props.model,
                 props.name,
                 date,
                 () => {
                     setHelpBlock("")
                     setValidated(true)
                 },
                 (err: any) => {
                     setHelpBlock(err)
                     setValidated(false)
                 }).then();
         }*/
    }

    let required = (props.pluginProps !== undefined) ? (props.pluginProps.hasOwnProperty("required") ? (props.pluginProps.required === true) ? 'required' : '' : '') : '';

    return (
        <InputGroup size={props.size}
                    className={'form-group field-' + props.model + '-' + props.name + ' ' + props.class}>
            <InputGroup.Prepend>
                <InputGroup.Text className={'control-label ' + required}>{props.label}</InputGroup.Text>
            </InputGroup.Prepend>

            <DatePicker
                locale="ru"
                placeholderText={props.placeholder}
                className="form-control formControlDateTimeFix"
                wrapperClassName="form-control"
                showTimeSelect={props.showTimeSelect}
                //required={true}
                onSelect={(date) => onChangeValidate(date)}
                dateFormat={props.dateFormat ?? "dd.MM.yyyy"} //dateFormat="d MMMM yyyy HH:mm"
                selected={(props.value) ? new Date(props.value) : null}
                onChange={(date) => onChangeValidate(date)}
            />

            {/* <input
                id={props.model + '-' + props.name}
                className={'form-control ' + classPostfix(validated)}
                {...props.pluginProps}
                name={props.name}
                disabled={props.disabled}
                placeholder={props.placeholder}
                value={props.value || ''}
                onChange={onChangeValidate}
            />*/}
            {
                (validated !== undefined && !validated) &&
                <InputGroup.Append>
                    <InputGroup.Text>
                        {(props.validated !== undefined) ? props.helpBlock : helpBlock}
                    </InputGroup.Text>
                </InputGroup.Append>
            }
        </InputGroup>
    )
}

