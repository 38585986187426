import React from 'react';

import GitInfo from 'react-git-info/macro';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faCodeBranch, faCodeCommit} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import 'moment/locale/ru';
import moment from "moment";


const Footer = () => {
    moment.locale("ru");

    let getVersion = () => {
        //return "0.0.1"

        let gitInfo = GitInfo();
        if (gitInfo.tags.length > 0) {
            return <span className="d-inline p-2"><FontAwesomeIcon icon={faCodeCommit}/>{gitInfo.tags[0]}</span>
        } else {
            return <>
                <span className="d-inline p-2"><FontAwesomeIcon icon={faCodeBranch}/>{gitInfo.branch}</span>
                <span className="d-inline p-2"><FontAwesomeIcon icon={faCodeCommit}/>{gitInfo.commit.shortHash}</span>
                <span className="d-inline p-2">
                      <FontAwesomeIcon icon={faCalendar}/>
                      <Moment fromNow ago>{gitInfo.commit.date}</Moment> ago
                  </span>
            </>
        }

    }

// or from JSX
    return (
        <footer className="main-footer">
            <strong>
                <span>© Revol-Idep Team</span>
            </strong>

            <div className="float-right d-sm-inline-block">
                {getVersion()}
            </div>
        </footer>
    );
};

export default Footer;
