/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import BaseCreatePage from "crud/base/BaseCreatePage";
import EventCalendarsForm from "./_form";
import {EventCalendarsModel} from "./_model";


class EventCalendarsCreate extends BaseCreatePage {
    //public model = EventCalendarsModel;
    //public FormComponent = EventCalendarsForm;

    constructor(props: any) {
        super(props);
        super.model = EventCalendarsModel;
        super.FormComponent = EventCalendarsForm;
        super.relationTree = EventCalendarsModel.relationTree
    }
}

export default EventCalendarsCreate;