/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {EventTypesModel} from "./_model";

class EventTypesView extends BaseViewPage {
    //public model = EventTypesModel;

    constructor(props: any) {
        super(props);
        super.model = EventTypesModel;
        super.relationTree = EventTypesModel.relationTree
    }
}

export default withRouter(EventTypesView);