/**
 * Generated by ReactYii 3.0.27 at 23.11.2023
 */
import {Route} from "react-router-dom";
import UserTurnsCreate from './create';
import UserTurnsUpdate from './update';
import UserTurnsView from './view';
import UserTurnsIndex from './index';
import {UserTurnsModel} from "./_model";

const UserTurnsRoutes = [
    <>
        <Route path={UserTurnsModel.info.viewPath} element={<UserTurnsIndex/>}/>
        <Route path={UserTurnsModel.info.viewPath + '/create'} element={<UserTurnsCreate/>}/>
        <Route path={UserTurnsModel.info.viewPath + '/update/:id'} element={<UserTurnsUpdate/>}/>
        <Route path={UserTurnsModel.info.viewPath + '/view/:id'} element={<UserTurnsView/>}/>
    </>
];
export default UserTurnsRoutes;
