/**
 * Generated by ReactYii 3.0.27 at 07.05.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {TagsModel} from "./_model";

class TagsView extends BaseViewPage {
    //public model = TagsModel;

    constructor(props: any) {
        super(props);
        super.model = TagsModel;
        super.relationTree = TagsModel.relationTree
    }
}

export default withRouter(TagsView);