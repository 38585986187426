import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Col, Form} from "react-bootstrap";

const thumbsContainer = {
    display: 'flex',
    //flexDirection: 'row',
    //flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

type Props = {
    label?: string
    onDrop: (formData: FormData) => void;
}

function DndImages(props: Props) {
    const [files, setFiles] = useState<any>([]);

    const onDrop = useCallback(<T extends File>(acceptedFiles: T[]) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        const formData = new FormData();
        formData.append('files', acceptedFiles[0]);
        props.onDrop(formData)
    }, [props]);


    const {getRootProps, getInputProps} = useDropzone({accept: {'image/*': []}, onDrop: onDrop});

    const thumbs = files.map((file: any) => (
        <div style={thumb} key={file?.name}>
            <div style={thumbInner}>
                <img style={img} alt={file?.name} src={file?.preview} className="img-fluid"
                     onLoad={() => URL.revokeObjectURL(file?.preview)}
                />
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach((file: any) => URL.revokeObjectURL(file?.preview));
    }, [files]);

    return (
        <Form.Group controlId="formFile" className="">
            <Form.Label>{props.label || ''}</Form.Label>
            <Form.Row className="pl-1 pr-1 bg-white rounded">
                <Col>
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop files here, or click to select</p>
                        <p className="btn btn-success">Upload</p>
                    </div>
                    <aside style={thumbsContainer}>{thumbs}</aside>
                    {files.map((uploadedFile: any) => {
                        // console.log(uploadedFile)
                        return (
                            <div key={uploadedFile.name}>
                                {/*  <div style={{
                                backgroundImage: `url(${uploadedFile.previewUrl})`,
                                backgroundSize: 'cover',
                                width: '100px',
                                height: '100px',
                                }}
                                />*/}
                                {/* {uploadProgress?.[uploadedFile?.name] && (
                                    <p>Upload progress: {uploadProgress[uploadedFile?.name]}%</p>
                                )}*/}
                            </div>
                        );
                    })}
                </Col>
            </Form.Row>
        </Form.Group>
    );
}

export default DndImages;