/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {EventCalendarsModel} from "./_model";

class EventCalendarsView extends BaseViewPage {
    //public model = EventCalendarsModel;

    constructor(props: any) {
        super(props);
        super.model = EventCalendarsModel;
        super.relationTree = EventCalendarsModel.relationTree
    }
}

export default withRouter(EventCalendarsView);