import React, {useState} from 'react'
import {classPostfix, validate} from '../api/yiiValidation'
import {FieldInputProps} from "../types/FieldInputProps";
import {InputGroup} from "react-bootstrap";


export default function InputField(props: FieldInputProps) {
    const [helpBlock, setHelpBlock] = useState("");
    const [validated, setValidated] = useState<boolean | undefined>();
    //TODO now ignore props.validated

    const onChangeValidate = (event: any) => {
        props.onChange(event)

        if (props.model !== undefined && props.model !== '') {
            validate(
                props.model,
                props.name,
                event.target.value,
                () => {
                    setHelpBlock("")
                    setValidated(true)
                },
                (err: any) => {
                    setHelpBlock(err)
                    setValidated(false)
                }).then();
        }
    }

    let required = (props.pluginProps !== undefined) ? (props.pluginProps.hasOwnProperty("required") ? (props.pluginProps.required === true) ? 'required' : '' : '') : '';

    return (
        <InputGroup
            className={'form-group field-' + props.model + '-' + props.name + ' ' + props.class}>
            <InputGroup.Prepend>
                <InputGroup.Text className={'control-label ' + required}>{props.label}</InputGroup.Text>
            </InputGroup.Prepend>
            <input
                id={props.model + '-' + props.name}
                className={'form-control ' + classPostfix(validated)}
                {...props.pluginProps}
                name={props.name}
                disabled={props.disabled}
                placeholder={props.placeholder}
                value={props.value || ''}
                onChange={onChangeValidate}
            />
            {
                (validated !== undefined && !validated) &&
                <InputGroup.Append>
                    <InputGroup.Text>
                        {(props.validated !== undefined) ? props.helpBlock : helpBlock}
                    </InputGroup.Text>
                </InputGroup.Append>
            }
        </InputGroup>
    )
}

