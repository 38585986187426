/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import TableGamesPlacesForm from "./_form";
import {TableGamesPlacesModel} from "./_model";

class TableGamesPlacesUpdate extends BaseUpdatePage {
    //public model = TableGamesPlacesModel;
    //public FormComponent = TableGamesPlacesForm;

    constructor(props: any) {
        super(props);
        super.model = TableGamesPlacesModel;
        super.FormComponent = TableGamesPlacesForm;
        super.relationTree = TableGamesPlacesModel.relationTree
    }
}

export default withTranslation()(withRouter(TableGamesPlacesUpdate));
