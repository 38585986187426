/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";
import {EventTypesModel} from "../event-types/_model";
import {EventCalendarsModel} from "../event-calendars/_model";
import {Constants} from "../../../utils/constants";

export const EventsModel: BaseModelInfo = {
    info: {
        name: "events",
        label: "Ивенты",
        apiPath: "/rapi/events",
        viewPath: "/table-games/events"
    },
    attrs: {
        id: {label: "ID"},
        event_type_id: {label: "EventType ID"},
        is_enabled: {label: "Активно?", type: FieldType.Bool},
        //url: {label: <span><b>Url </b> <small>zdver.com/events/</small></span>},
        url: {
            label: <span><b>Url </b> <small>zdver.com/events/</small></span>,
            type: FieldType.Custom,
            params: (val: any) => <a target="_blank" href={"https://zdver.com/events/" + val}>{val}</a>

        },
        name: {label: "Имя"},
        description: {label: "Описание"},
        fee: {label: "Цена"},
        duration: {label: "Время (мин)"},
        //image_name: {label:"Image Name"},
        /*    image_name: {
                label: "Фото",
                type: FieldType.Custom,
                params: (val: any) => <img alt="avatar_only" className="img-circle" width={50} height={50}
                                           src={Constants.publicImageViewPath+ "events/" + val}/>
            },*/
        image_name: {
            label: "Фото",
            type: FieldType.Image,
            params: {url: Constants.publicImageViewPath + "events/"}
        },
        place: {label: "Зал"},
        //obsolete: {label: "Obsolete", type: FieldType.Bool},


        eventCalendars: {
            label: 'Расписание',
            type: FieldType.RelationOneData,
            params: {name: "name", model: EventCalendarsModel}
        },
        //eventParts: {label: '"EventParts"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        eventType: {label: 'Тип', type: FieldType.RelationOneData, params: {name: "name", model: EventTypesModel}},
        vk: {label: "vk.com/"},
        facebook: {label: "Facebook"},

    },
    relationTree: {
        relation: [
            "eventType"
        ],
        relations: [
            "eventCalendars",
        ]
    },
    defaultShowAttrs: ["id", "image_name", "name", "eventType", "url", "fee", "is_enabled"]
};


