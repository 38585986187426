/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import TableGamesImgsCreate from './create';
import TableGamesImgsUpdate from './update';
import TableGamesImgsView from './view';
import TableGamesImgsIndex from './index';
import {TableGamesImgsModel} from "./_model";

const TableGamesImgsRoutes = [
    <>
        <Route path={TableGamesImgsModel.info.viewPath} element={<TableGamesImgsIndex/>}/>
        <Route path={TableGamesImgsModel.info.viewPath + '/create'} element={<TableGamesImgsCreate/>}/>
        <Route path={TableGamesImgsModel.info.viewPath + '/update/:id'} element={<TableGamesImgsUpdate/>}/>
        <Route path={TableGamesImgsModel.info.viewPath + '/view/:id'} element={<TableGamesImgsView/>}/>
    </>
];
export default TableGamesImgsRoutes;
