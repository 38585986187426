import {FieldType} from "./FieldType";
import {AttrInfo} from "./AttrInfo";

export type IRelationTree = {
    relations?: {}
    relation?: {}
    counts?: {}
}

export interface BaseModelInfo {
    info: {
        name: string;
        label: string;
        apiPath: string;
        viewPath: string;
    };
    attrs: Record<string, AttrInfo>;
    /*attrs: Record<string, {
        //name: string;
        label: string;
        type?: FieldType;
        params?: any;
    }>;*/
    form?: any
    relationTree: IRelationTree;
    defaultShowAttrs: string[];
}

export const defaultModel: BaseModelInfo = {
    info: {
        name: 'defaultModel',
        label: 'DefaultModel',
        apiPath: '/api/default-model',
        viewPath: '/model'
    },
    attrs: {
        id: {label: 'ID'},
        name: {label: 'Название'},
        tarifs: {label: 'Тариф', type: FieldType.RelationOneData, params: {name: 'name'}},
        role_groups: {label: 'Группа', type: FieldType.RelationOneData, params: {name: 'name'}},
        level: {label: 'Уровень'},
        descr: {label: 'Описание'},
        tarif_id: {

            label: 'Тариф ID',
            type: FieldType.RelationOneID,
            params: {name: 'name', model: 'rapi/tarifs'}
        },
        role_group_id: {

            label: 'ID Группы',
            type: FieldType.RelationOneID,
            params: {name: 'name', model: 'rapi/role-groups'}
        }
    },
    relationTree: {},
    defaultShowAttrs: ['id', 'name']
};