import React from 'react';
import {Card, Modal} from "react-bootstrap";
//import {KassaCheckout} from "../../../api/kassa/kassa";
//import {toast} from "react-toastify";
import {UserInvoice} from "../../../models/kassa/UserInvoice";
import {getPaymentType} from "../helpers";
import MomentViewer from "../../../crud/field_viewers/MomentViewer";
import UserProfileView from "../../users/UserProfileView";
import {PaymentStatus} from "../../../models/kassa/PaymentStatus";
import {Link} from "react-router-dom";
//import CheckoutType from "../../../models/CheckoutType";

type Props = {
    onClose: any,
    invoice: UserInvoice | null,
}

export const InvoiceModal = (props: Props) => {
    // const navigate = useNavigate();

    const onClose = () => {
        props.onClose();
    }

    //const checkout = (id: number, type: keyof typeof CheckoutType) => {
    /*    const checkout = (id: number, type: CheckoutType) => {
            KassaCheckout(id, type)
                .then((resp: any) => {
                    console.log(resp)
                    //setCurrentUsers(resp.data);
                    onClose()
                })
                .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
        }*/

    return <Modal className="modal-warning" show={(props.invoice != null)} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Чек №{props.invoice?.id} от {props.invoice?.created_at}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Card>
                <Card.Body>
                    <>
                        <table className="table">
                            <tbody>
                            {
                                /*  <tr>
                                <td>Карта</td>
                                <td>{props.invoice?.user?.barcode}</td>
                                 </tr>
                            */}
                            <tr>
                                <td colSpan={2}>
                                    {/* <button type="button" className="btn btn-outline-info btn-sm"
                                        onClick={() => navigate(UsersModel.info.viewPath + "/view/" + props.invoice?.user?.id)}>
                                    {props.invoice?.user?.name}
                                </button>*/}


                                    <UserProfileView id={props.invoice?.user?.id}
                                                     image_name={props.invoice?.user?.image_name}
                                                     name={props.invoice?.user?.name}
                                                     role={props.invoice?.user?.role?.name}
                                    />


                                </td>
                            </tr>
                            {/*  <tr>
                            <td>Роль</td>
                            <td>{props.invoice?.user?.role?.name}</td>
                        </tr>*/}
                            <tr>
                                <td>Тариф</td>
                                <td>{props.invoice?.tarif?.name}</td>
                            </tr>
                            <tr>
                                <td>Тип оплаты</td>
                                <td>{getPaymentType(props?.invoice?.payment_type)}</td>
                            </tr>

                            <tr>
                                <td>Время прихода</td>
                                <td><MomentViewer value={props.invoice?.created_at}/></td>
                            </tr>
                            <tr>
                                <td>Времени провел</td>
                                <td>{props.invoice?.minutes} мин</td>
                            </tr>
                            <tr>
                                <td>Итого</td>
                                <td>
                                    <b>{props.invoice?.sum}₽</b>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                        <table className="table">
                            {
                                props?.invoice?.userPayments?.map((pay) => {
                                    return <tr>
                                        <td><Link to={"/user-payments/view/" + pay.id}>#{pay.id}</Link></td>
                                        <td>{pay.sum}₽</td>
                                        <td>{pay.created_at}</td>
                                        <td>{pay.descr}</td>
                                        <td>{PaymentStatus[pay.status]}</td>
                                    </tr>
                                })
                            }
                            {/*  {
                                props?.invoice?.userPayments.forEach((pay) => {

                                })
                            }*/}
                        </table>
                    </>
                </Card.Body>
            </Card>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">

        </Modal.Footer>
    </Modal>
}
