/**
 * Generated by ReactYii 3.0.27 at 27.11.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import EventUserCheckpointParticipantsForm from "./_form";
import {EventUserCheckpointParticipantsModel} from "./_model";


class EventUserCheckpointParticipantsCreate extends BaseCreatePage {
    //public model = EventUserCheckpointParticipantsModel;
    //public FormComponent = EventUserCheckpointParticipantsForm;

    constructor(props: any) {
        super(props);
        super.model = EventUserCheckpointParticipantsModel;
        super.FormComponent = EventUserCheckpointParticipantsForm;
        super.relationTree = EventUserCheckpointParticipantsModel.relationTree
    }
}

export default EventUserCheckpointParticipantsCreate;