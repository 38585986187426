/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TableGamesTypesModel} from "./_model";

class TableGamesTypesIndex extends BaseIndexPage {
    //public model = TableGamesTypesModel;

    constructor(props: any) {
        super(props);
        super.model = TableGamesTypesModel;
        super.defaultShowAttrs = TableGamesTypesModel.defaultShowAttrs;
        super.relationTree = TableGamesTypesModel.relationTree
    }
}

export default TableGamesTypesIndex;