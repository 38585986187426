/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField, SwitchField} from "crud";
import BaseForm from "crud/base/BaseForm";
import {TarifsModel} from "./_model";
import {Col, Row} from "react-bootstrap";

class TarifsForm extends BaseForm {
    //public model = TarifsModel;

    constructor(props: any) {
        super(props);
        super.model = TarifsModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                free_minutes: '',
                first_period_price: '',
                first_period_minutes: '',
                second_period_price: '',
                stop_check_price: '',
                use_stopcheck_for_cash: '',
                use_stopcheck_for_card: '',
                discount_percent: '',
                name: '',
                descr: '',
                created_at: '',
                updated_at: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>
                    <InputField name={'name'}
                                model={this.model.info.name}
                                label={this.model.attrs.name.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['name']}/>

                    <InputField name={'descr'}
                                model={this.model.info.name}
                                label={this.model.attrs.descr.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['descr']}/>


                    <InputField name={'free_minutes'}
                                model={this.model.info.name}
                                label={this.model.attrs.free_minutes.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['free_minutes']}/>

                    <hr/>
                    <Row>
                        <Col>
                            <InputField name={'first_period_price'}
                                        model={this.model.info.name}
                                        label={this.model.attrs.first_period_price.label}
                                        onChange={this.handleInputChange}
                                        value={this.state.form['first_period_price']}/>

                        </Col>
                        <Col>
                            <InputField name={'first_period_minutes'}
                                        model={this.model.info.name}
                                        label={this.model.attrs.first_period_minutes.label}
                                        onChange={this.handleInputChange}
                                        value={this.state.form['first_period_minutes']}/>

                        </Col>
                    </Row>


                    <hr/>
                    <InputField name={'second_period_price'}
                                model={this.model.info.name}
                                label={this.model.attrs.second_period_price.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['second_period_price']}/>

                    <hr/>
                    <InputField name={'stop_check_price'}
                                model={this.model.info.name}
                                label={this.model.attrs.stop_check_price.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['stop_check_price']}/>

                    <Row>
                        <Col>
                            <SwitchField name={'use_stopcheck_for_cash'} model={this.model.info.name}
                                         label={this.model.attrs.use_stopcheck_for_cash.label}
                                         onChange={this.handleInputChange}
                                         value={this.state.form['use_stopcheck_for_cash']}
                                         class="p-2"/>
                        </Col>
                        <Col>
                            <SwitchField name={'use_stopcheck_for_card'} model={this.model.info.name}
                                         label={this.model.attrs.use_stopcheck_for_card.label}
                                         onChange={this.handleInputChange}
                                         value={this.state.form['use_stopcheck_for_card']}
                                         class="p-2"/>
                        </Col>
                    </Row>


                    <InputField name={'discount_percent'}
                                model={this.model.info.name}
                                label={this.model.attrs.discount_percent.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['discount_percent']}/>


                    {/*<RelationOneDropDown name={'role_id'}
                            model={this.model.info.name}
                            label={this.model.attrs?.role_id?.label}
                            relationUrl={this.model.attrs.roles.params.model?.info?.apiPath + "/index"}
                            onChange={(val: any) => this.handleInputChange({name: "role_id", value: val.id})}
                            value={this.state.form['role_id']}/>

                    <RelationOneDropDown name={'userCheckpoints_id'}
                            model={this.model.info.name}
                            label={this.model.attrs?.userCheckpoints_id?.label}
                            relationUrl={this.model.attrs.userCheckpoints.params.model?.info?.apiPath + "/index"}
                            onChange={(val: any) => this.handleInputChange({name: "userCheckpoints_id", value: val.id})}
                            value={this.state.form['userCheckpoints_id']}/>

                    <RelationOneDropDown name={'userInvoices_id'}
                            model={this.model.info.name}
                            label={this.model.attrs?.userInvoices_id?.label}
                            relationUrl={this.model.attrs.userInvoices.params.model?.info?.apiPath + "/index"}
                            onChange={(val: any) => this.handleInputChange({name: "userInvoices_id", value: val.id})}
                            value={this.state.form['userInvoices_id']}/>*/}


                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default TarifsForm;
