/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import TableGamesCoopsCreate from './create';
import TableGamesCoopsUpdate from './update';
import TableGamesCoopsView from './view';
import TableGamesCoopsIndex from './index';
import {TableGamesCoopsModel} from "./_model";

const TableGamesCoopsRoutes = [
    <>
        <Route path={TableGamesCoopsModel.info.viewPath} element={<TableGamesCoopsIndex/>}/>
        <Route path={TableGamesCoopsModel.info.viewPath + '/create'} element={<TableGamesCoopsCreate/>}/>
        <Route path={TableGamesCoopsModel.info.viewPath + '/update/:id'} element={<TableGamesCoopsUpdate/>}/>
        <Route path={TableGamesCoopsModel.info.viewPath + '/view/:id'} element={<TableGamesCoopsView/>}/>
    </>
];
export default TableGamesCoopsRoutes;
