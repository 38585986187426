/**
 * Generated by ReactYii 3.0.27 at 02.10.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {UserTableGameEventsModel} from "./_model";

class UserTableGameEventsIndex extends BaseIndexPage {
    //public model = UserTableGameEventsModel;

    constructor(props: any) {
        super(props);
        super.model = UserTableGameEventsModel;
        super.defaultShowAttrs = UserTableGameEventsModel.defaultShowAttrs;
        super.relationTree = UserTableGameEventsModel.relationTree
    }
}

export default UserTableGameEventsIndex;