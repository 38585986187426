import {CheckpointUser} from "../../models/users/UserCheckpoint";
import userStatus from "../../pages/kassa/models/userStatus";
import {toast} from "react-toastify";
import UserProfileView from "../../pages/users/UserProfileView";
import {checkoutStore} from "../../api/store/CheckoutStore";
import React from "react";

export default function onCheckpoint(resp: any) {

    let checkpoint: (CheckpointUser | null) = null;
    if (resp.scan?.user_checkpoint?.length > 0) {
        checkpoint = resp.scan?.user_checkpoint[0];
    }

    if (resp.scan.user_status === userStatus.notExist) {
        console.log(resp)
        toast.warning("Несуществующая карта \n " + resp.scan?.user_status + "\n" +
            <b>{checkpoint?.user?.barcode.slice(-3)}</b>);
        return
    } else if (resp.scan.user_status === userStatus.checkIn) {
        if (checkpoint?.user?.is_dead_soul) {
            toast.success(<p>Записанна карта: <b>{checkpoint?.user?.barcode.slice(-3)}</b></p>);
        } else {
            toast.success(
                <div>
                    <UserProfileView id={checkpoint?.user?.id} name={checkpoint?.user?.name}
                                     image_name={checkpoint?.user?.image_name}
                                     role={checkpoint?.user?.role?.name}/>
                    <p><b>Записан успешно!<br/></b></p>
                    <b>{checkpoint?.user?.name}</b>
                </div>
            );
        }
        if (checkpoint) {
            const index = checkoutStore.currentCheckInPoints.findIndex(obj => obj.id === checkpoint?.id);
            if (index >= 0) {
                checkoutStore.currentCheckInPoints[index] = checkpoint;
            } else {
                checkoutStore.currentCheckInPoints.push(checkpoint);
            }
            checkoutStore.reCalcCurIndex();
        }
    } else if (resp.scan.user_status === userStatus.checkOut) {
        toast.info(
            <div>
                <UserProfileView id={checkpoint?.user?.id} name={checkpoint?.user?.name}
                                 image_name={checkpoint?.user?.image_name}
                                 role={checkpoint?.user?.role?.name}/>
                <p><b>Уже записан!<br/></b></p>
            </div>);
        if (checkpoint) {
            const index = checkoutStore.currentCheckpoints.findIndex(obj => obj.id === checkpoint?.id);
            if (index >= 0) {
                checkoutStore.currentCheckpoints[index] = checkpoint;
            } else {
                checkoutStore.currentCheckpoints.push(checkpoint);
            }
            checkoutStore.reCalcCurIndex();
        }
    }
}
