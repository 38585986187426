/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import UserInvoicesCreate from './create';
import UserInvoicesUpdate from './update';
import UserInvoicesView from './view';
import UserInvoicesIndex from './index';
import {UserInvoicesModel} from "./_model";

const UserInvoicesRoutes = [
    <>
        <Route path={UserInvoicesModel.info.viewPath} element={<UserInvoicesIndex/>}/>
        <Route path={UserInvoicesModel.info.viewPath + '/create'} element={<UserInvoicesCreate/>}/>
        <Route path={UserInvoicesModel.info.viewPath + '/update/:id'} element={<UserInvoicesUpdate/>}/>
        <Route path={UserInvoicesModel.info.viewPath + '/view/:id'} element={<UserInvoicesView/>}/>
    </>
];
export default UserInvoicesRoutes;
