import stringify from "qs-stringify";
import {ApiRequest, HttpMethod} from "api/api";
import {Constants} from "../../utils/constants";


const LoginAPI = (login: string, password: string): Promise<any> => {
    const params = stringify({"username": login, "password": password});
    return ApiRequest<any>(HttpMethod.POST, Constants.ApiVersion + "/auth/login", params);
};

/*const LoginAPI = async (login: string, password: string, onResponse: any) => {
    await axios.post(
        Constants.API_URL + "/auth/login",
        stringify({"username": login, "password": password}),
    )
        .then((response) => GlobalOnResponse(response, onResponse))
        .catch((error: any) => GlobalOnError(error, onResponse))
}*/

export default LoginAPI;