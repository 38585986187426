/**
 * Generated by ReactYii 3.0.27 at 07.05.2023
 */
import {Route} from "react-router-dom";
import TagsCreate from './create';
import TagsUpdate from './update';
import TagsView from './view';
import TagsIndex from './index';
import {TagsModel} from "./_model";

const TagsRoutes = [
    <>
        <Route path={TagsModel.info.viewPath} element={<TagsIndex/>}/>
        <Route path={TagsModel.info.viewPath + '/create'} element={<TagsCreate/>}/>
        <Route path={TagsModel.info.viewPath + '/update/:id'} element={<TagsUpdate/>}/>
        <Route path={TagsModel.info.viewPath + '/view/:id'} element={<TagsView/>}/>
    </>
];
export default TagsRoutes;
