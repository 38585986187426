/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import BaseCreatePage from "crud/base/BaseCreatePage";
import RoleGroupsForm from "./_form";
import {RoleGroupsModel} from "./_model";


class RoleGroupsCreate extends BaseCreatePage {
    //public model = RoleGroupsModel;
    //public FormComponent = RoleGroupsForm;

    constructor(props: any) {
        super(props);
        super.model = RoleGroupsModel;
        super.FormComponent = RoleGroupsForm;
        super.relationTree = RoleGroupsModel.relationTree
    }
}

export default RoleGroupsCreate;