import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const ErrorWidget = () => {
    return (
        <div className="flex-column justify-content-center align-items-center text-center">
            <FontAwesomeIcon icon={faBug} size="6x" color="red"/><br/>
            <h6>Все отвалилось.</h6>
        </div>
    )
}

export default ErrorWidget;