/**
 * Generated by ReactYii 3.0.27 at 02.10.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";
import {TableGamesModel} from "../table-games/_model";
import {UsersModel} from "../../users/_model";

export const UserTableGameEventsModel: BaseModelInfo = {
    info: {
        name: "user-table-game-events",
        label: "User Table Game Events",
        apiPath: "/rapi/user-table-game-events",
        viewPath: "/table-games/user-table-game-events"
    },
    attrs: {
        id: {label: "ID"},
        user_id: {label: "Создатель события"},
        table_game_id: {label: "Настолка"},
        players_from: {label: "Минимальное количество игроков"},
        players_to: {label: "Максимальное количество игроков"},
        duration: {label: "Длительность в минутах"},
        status: {label: "0-создано, -1-удалено, 1-активно, 10-выключено"},
        name: {label: "Название"},
        descr: {label: "Описание"},
        short_descr: {label: "Краткое описание"},
        created_at: {label: "Дата создания"},
        from_dt: {label: "Дата начала события"},
        to_dt: {label: "Дата окончания события"},
        tableGame: {
            label: '"TableGame"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: TableGamesModel}
        },
        user: {label: '"User"', type: FieldType.RelationOneData, params: {name: "name", model: UsersModel}},
    },
    relationTree: {},
    defaultShowAttrs: ["user_id", "table_game_id", "players_from", "players_to", "duration",]
};


