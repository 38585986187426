/**
 * Generated by ReactYii 3.0.27 at 22.11.2023
 */
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const WssLogModel: BaseModelInfo = {
    info: {
        name: "wss-log",
        label: "Wss Log",
        apiPath: "/rapi/wss-log",
        viewPath: "/wss"
    },
    attrs: {
        id: {label: "ID"},
        type: {label: "Раздел сообщения"},
        content: {label: "Содержимое сообщения"},
        created_at: {label: "Created At"},
    },
    relationTree: {},
    defaultShowAttrs: ["type", "content", "created_at",]
};


