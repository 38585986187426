import {CheckpointUser} from "../../models/users/UserCheckpoint";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {PaymentType} from "../../models/kassa/PaymentType";

const getInvoicePayStatus = (is_payed?: any) => {
    //console.log("getPayStatus",is_payed)

    if (typeof is_payed === 'object' && is_payed !== null) {
        return (is_payed.is_payed ?? false)
            ? <span className="bg-success btn-sm">Оплачен</span>
            : <div className="bg-warning btn-sm text-sm">Не оплачен</div>
    } else {
        return (is_payed ?? false)
            ? <span className="bg-success btn-sm">Оплачен</span>
            : <div className="bg-warning btn-sm text-sm">Не оплачен</div>
    }
}
const getPaymentStatus = (status?: any) => {
    if (Number.isInteger(status)) {
        //     "created": 0,
        //     "sended": 1,
        //     "verified": 2,
        //     "deleted": 3
        switch (status) {
            case -1:
                return <span className="bg-warning btn-sm">-</span>
            case 0:
                return <span className="bg-primary btn-sm">Создан</span>
            case 1:
                return <span className="bg-primary btn-sm">Отправлен</span>
            case 2:
                return <span className="bg-success btn-sm">Оплачен</span>
            case 3:
                return <span className="bg-warning btn-sm">Удален</span>

            default:
                return <span className=" text-danger">???</span>
        }

    }
}

const getCheckpointStatus = (checkpoint?: CheckpointUser) => {
    if (checkpoint?.is_outside) {
        return <span className="bg-dark btn-sm">Выписан</span>
    } else if (checkpoint?.is_inside) {
        return <span className="bg-info btn-sm">Записан</span>
    } else {
        return <span className="bg-info btn-sm">-</span>
    }
}

const getPaymentType = (payment_type: PaymentType | undefined) => {
    if (Number.isInteger(payment_type)) {

        switch (payment_type) {
            case -1:
                return <span className="text-danger">
                <FontAwesomeIcon icon={solid("cross")}/>Отмена.
            </span>
            case 0:
                return <span className="text-success">
                <FontAwesomeIcon icon={solid("money-bill-1")}/> Нал.
            </span>
            case 1:
                return <span className="text-primary">
                <FontAwesomeIcon icon={solid("credit-card")}/> Безнал.
            </span>
            case 2:
                return <span className="text-info">
                <FontAwesomeIcon icon={solid("piggy-bank")}/> Депозит.
            </span>
            case 3:
                return <span className="text-warning">
                <FontAwesomeIcon icon={solid("qrcode")}/> Qr-код
            </span>
            case 4:
                return <span className="text-warning">
                <FontAwesomeIcon icon={solid("qrcode")}/> QR-SPB
            </span>
            case 5:
                return <span className="text-warning">
                <FontAwesomeIcon icon={solid("qrcode")}/> T-telegram
            </span>

            default:
                return <span className=" text-danger">???</span>
        }

    }

}

export {getInvoicePayStatus, getCheckpointStatus, getPaymentType, getPaymentStatus}