export enum FieldType {
    Integer,
    String,
    Bool,
    Image,
    Html,
    RelationOneID,
    RelationOneData,
    RelationMany,
    MomentDate,
    Custom,
    BarCode,
    MarkDown,
}