/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import TableGamesPlacesCreate from './create';
import TableGamesPlacesUpdate from './update';
import TableGamesPlacesView from './view';
import TableGamesPlacesIndex from './index';
import {TableGamesPlacesModel} from "./_model";

const TableGamesPlacesRoutes = [
    <>
        <Route path={TableGamesPlacesModel.info.viewPath} element={<TableGamesPlacesIndex/>}/>
        <Route path={TableGamesPlacesModel.info.viewPath + '/create'} element={<TableGamesPlacesCreate/>}/>
        <Route path={TableGamesPlacesModel.info.viewPath + '/update/:id'} element={<TableGamesPlacesUpdate/>}/>
        <Route path={TableGamesPlacesModel.info.viewPath + '/view/:id'} element={<TableGamesPlacesView/>}/>
    </>
];
export default TableGamesPlacesRoutes;
