/**
 * Generated by ReactYii 3.0.27 at 15.08.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {UserPaymentsModel} from "./_model";

class UserPaymentsView extends BaseViewPage {
    //public model = UserPaymentsModel;

    constructor(props: any) {
        super(props);
        super.model = UserPaymentsModel;
        super.relationTree = UserPaymentsModel.relationTree
    }
}

export default withRouter(UserPaymentsView);