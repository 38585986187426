import {makeAutoObservable, observable} from 'mobx'

export class UiStore {
    get sidebarSkin(): string {
        return this._sidebarSkin;
    }

    set sidebarSkin(value: string) {
        this._sidebarSkin = value;
    }

    get navbarVariant(): string {
        return this._navbarVariant;
    }

    set navbarVariant(value: string) {
        this._navbarVariant = value;
    }


    get menuChildIndent(): boolean {
        return this._menuChildIndent;
    }


    toggleMenuChildIndent = () => this._menuChildIndent = !this._menuChildIndent;

    get menuItemFlat(): boolean {
        return this._menuItemFlat;
    }

    set menuItemFlat(value: boolean) {
        this._menuItemFlat = value;
    }

    toggleMenuItemFlat = () => this._menuItemFlat = !this._menuItemFlat;

    get layoutFixed(): boolean {
        return this._layoutFixed;
    }

    toggleLayoutFixed = () => this._layoutFixed = !this._layoutFixed;

    get layoutBoxed(): boolean {
        return this._layoutBoxed;
    }


    toggleLayoutBoxed = () => this._layoutBoxed = !this._layoutBoxed;


    get footerFixed(): boolean {
        return this._footerFixed;
    }


    toggleFooterFixed = () => this._footerFixed = !this._footerFixed;

    get privateHeaderFixed(): boolean {
        return this._privateHeaderFixed;
    }

    togglePrivateHeaderFixed = () => this._privateHeaderFixed = !this._privateHeaderFixed;


    get privateHeaderBorder(): boolean {
        return this._privateHeaderBorder;
    }


    togglePrivateHeaderBorder = () => this._privateHeaderBorder = !this._privateHeaderBorder;


    get darkMode(): boolean {
        return this._darkMode;
    }

    toggleDarkMode = () => {
        this._darkMode = !this._darkMode;
        console.log(this._darkMode)
    }


    /*   get controlSidebarCollapsed(): boolean {
           return this._controlSidebarCollapsed;
       }

       toggleControlSidebarCollapsed = () => this._controlSidebarCollapsed = !this._controlSidebarCollapsed;*/

    get menuSidebarCollapsed(): boolean {
        return this._menuSidebarCollapsed;
    }

    toggleMenuSidebarCollapsed = () => this._menuSidebarCollapsed = !this._menuSidebarCollapsed;


    get screenSize(): string {
        return this._screenSize;
    }

    set screenSize(value: string) {
        //calculateWindowSize(window.innerWidth)
        //calculateWindowSize(value)
        this._screenSize = value;
    }

    constructor() {
        makeAutoObservable(this);
    }


    @observable private _screenSize: string = ""
    @observable private _menuSidebarCollapsed: boolean = false;
    @observable private _controlSidebarCollapsed: boolean = true;
    @observable private _darkMode: boolean = true;
    @observable private _privateHeaderBorder: boolean = true;
    @observable private _privateHeaderFixed: boolean = true;
    @observable private _footerFixed: boolean = true
    @observable private _layoutBoxed: boolean = true
    @observable private _layoutFixed: boolean = true
    @observable private _menuItemFlat: boolean = false
    @observable private _menuChildIndent: boolean = true
    @observable private _navbarVariant: string = "navbar-success navbar-dark"
    @observable private _sidebarSkin: string = "sidebar-dark-primary"


    /*    get items(): string {
            return this._token;
        }

        set items(value: string) {
            this._token = value;
        }*/

}

export const uiStore = new UiStore()

/*
import {createSlice} from '@reduxjs/toolkit';
import {addWindowClass, calculateWindowSize, removeWindowClass} from '../../../utils/helpers';
import {NAVBAR_DARK_VARIANTS, NAVBAR_LIGHT_VARIANTS, SIDEBAR_DARK_SKINS, SIDEBAR_LIGHT_SKINS} from '../../utils/themes';

export interface UiState {
    screenSize: string;
    menuSidebarCollapsed: boolean;
    controlSidebarCollapsed: boolean;
    darkMode: boolean;
    headerBorder: boolean;
    headerFixed: boolean;
    footerFixed: boolean;
    layoutBoxed: boolean;
    layoutFixed: boolean;

    menuItemFlat: boolean;
    menuChildIndent: boolean;
    navbarVariant: string;
    sidebarSkin: string;
}

const initialState: UiState = {
    screenSize: calculateWindowSize(window.innerWidth),
    darkMode: true,
    navbarVariant: 'navbar-light',
    sidebarSkin: 'sidebar-dark-primary',
    menuSidebarCollapsed: false,
    controlSidebarCollapsed: true,
    headerBorder: false,
    headerFixed: true,
    footerFixed: false,
    layoutBoxed: false,
    menuItemFlat: false,
    menuChildIndent: false,
    layoutFixed: false
};

//addWindowClass('layout-footer-fixed');

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        toggleSidebarMenu: (state) => {
            state.menuSidebarCollapsed = !state.menuSidebarCollapsed;
        },
        toggleControlSidebar: (state) => {
            state.controlSidebarCollapsed = !state.controlSidebarCollapsed;
        },
        toggleHeaderBorder: (state) => {
            state.headerBorder = !state.headerBorder;
        },
        toggleHeaderFixed: (state) => {
            state.headerFixed = !state.headerFixed;
            if (state.headerFixed) {
                addWindowClass('layout-navbar-fixed');
            } else {
                removeWindowClass('layout-navbar-fixed');
            }
        },
        toggleFooterFixed: (state) => {
            state.footerFixed = !state.footerFixed;
            if (state.footerFixed) {
                addWindowClass('layout-footer-fixed');
            } else {
                removeWindowClass('layout-footer-fixed');
            }
        },
        toggleLayoutBoxed: (state) => {
            state.layoutBoxed = !state.layoutBoxed;
            if (state.layoutBoxed) {
                addWindowClass('layout-boxed');
            } else {
                removeWindowClass('layout-boxed');
            }
        },
        toggleLayoutFixed: (state) => {
            state.layoutFixed = !state.layoutFixed;
            if (state.layoutFixed) {
                removeWindowClass('layout-fixed');
            } else {
                addWindowClass('layout-fixed');
            }
        },
        toggleMenuItemFlat: (state) => {
            state.menuItemFlat = !state.menuItemFlat;
        },
        toggleMenuChildIndent: (state) => {
            state.menuChildIndent = !state.menuChildIndent;
        },
        toggleDarkMode: (state) => {
            state.darkMode = !state.darkMode;
            if (state.darkMode) {
                state.navbarVariant = NAVBAR_DARK_VARIANTS[0].value;
                state.sidebarSkin = SIDEBAR_DARK_SKINS[0].value;
            } else {
                state.navbarVariant = NAVBAR_LIGHT_VARIANTS[0].value;
                state.sidebarSkin = SIDEBAR_LIGHT_SKINS[0].value;
            }
            if (state.darkMode) {
                addWindowClass('dark-mode');
            } else {
                removeWindowClass('dark-mode');
            }
        },
        setNavbarVariant: (state, {payload}) => {
            if (state.darkMode) {
                state.navbarVariant = payload || NAVBAR_DARK_VARIANTS[0].value;
            } else {
                state.navbarVariant = payload || NAVBAR_LIGHT_VARIANTS[0].value;
            }
        },
        setSidebarSkin: (state, {payload}) => {
            if (state.darkMode) {
                state.sidebarSkin = payload || SIDEBAR_DARK_SKINS[0].value;
            } else {
                state.sidebarSkin = payload || SIDEBAR_LIGHT_SKINS[0].value;
            }
        },
        setWindowSize: (state, {payload}) => {
            state.screenSize = payload;
        }
    }
});

export const {
    toggleSidebarMenu,
    setWindowSize,
    toggleControlSidebar,
    toggleDarkMode,
    setNavbarVariant,
    setSidebarSkin,
    toggleHeaderBorder,
    toggleHeaderFixed,
    toggleFooterFixed,
    toggleLayoutBoxed,
    toggleMenuItemFlat,
    toggleMenuChildIndent,
    toggleLayoutFixed
} = uiSlice.actions;

export default uiSlice.reducer;
*/


export interface UiState {
    screenSize: string;
    menuSidebarCollapsed: boolean;
    controlSidebarCollapsed: boolean;
    darkMode: boolean;
    headerBorder: boolean;
    headerFixed: boolean;
    footerFixed: boolean;
    layoutBoxed: boolean;
    layoutFixed: boolean;
    menuItemFlat: boolean;
    menuChildIndent: boolean;
    navbarVariant: string;
    sidebarSkin: string;
}