/**
 * Generated by ReactYii 3.0.27 at 27.11.2023
 */
import {Route} from "react-router-dom";
import EventUserCheckpointParticipantsCreate from './create';
import EventUserCheckpointParticipantsUpdate from './update';
import EventUserCheckpointParticipantsView from './view';
import EventUserCheckpointParticipantsIndex from './index';
import {EventUserCheckpointParticipantsModel} from "./_model";

const EventUserCheckpointParticipantsRoutes = [
    <>
        <Route path={EventUserCheckpointParticipantsModel.info.viewPath}
               element={<EventUserCheckpointParticipantsIndex/>}/>
        <Route path={EventUserCheckpointParticipantsModel.info.viewPath + '/create'}
               element={<EventUserCheckpointParticipantsCreate/>}/>
        <Route path={EventUserCheckpointParticipantsModel.info.viewPath + '/update/:id'}
               element={<EventUserCheckpointParticipantsUpdate/>}/>
        <Route path={EventUserCheckpointParticipantsModel.info.viewPath + '/view/:id'}
               element={<EventUserCheckpointParticipantsView/>}/>
    </>
];
export default EventUserCheckpointParticipantsRoutes;
