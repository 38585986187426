import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";
import {Translation} from "react-i18next";
import {InputField} from "../../crud";
import QRCodeStyling, {DrawType, Mode, Options} from "qr-code-styling";


export default function QrCodeGenerator() {
    useEffect(() => {
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
            {name: "Генератор Qr кодов", link: ''}
        ];


    }, []);

    //useEffect(() => {}, []);


    const [options, setOptions] = useState<Options>({
        width: 300,
        height: 300,
        type: 'svg' as DrawType,
        data: 'zd',
        margin: 10,
        qrOptions: {
            typeNumber: 6,
            mode: 'Byte' as Mode,
            errorCorrectionLevel: "H",
        },
        image: '/favicon/safari-pinned-tab.svg',
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 0,
            //saveAsBlob: false,
            //hideBackgroundDots: false,
            imageSize: 0.4,
        },

        dotsOptions: {
            //color: "#019915",
            color: "#2D6F36",
            //type: "classy"
            type: "rounded"
        },
        //backgroundOptions: {color: "#ffe4bd"},
        backgroundOptions: {color: "#ffffff"},

        /* cornersSquareOptions: {
             color: '#222222',
             type: 'extra-rounded' as CornerSquareType,
             // gradient: {
             //   type: 'linear', // 'radial'
             //   rotation: 180,
             //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
             // },
         },*/
        /*cornersDotOptions: {
            color: '#222222',
            type: 'dot' as CornerDotType,
            // gradient: {
            //   type: 'linear', // 'radial'
            //   rotation: 180,
            //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
            // },
        }*/
    });
    const [fileExt, setFileExt] = useState<any>("svg");
    const [qrCode] = useState<QRCodeStyling>(new QRCodeStyling(options));
    const ref = useRef<HTMLDivElement>(null);

    const styles = {
        inputWrapper: {
            margin: "20px 0",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "300px"
        },
        inputBox: {
            flexGrow: 1,
            marginRight: 20
        }
    };

    useEffect(() => {
        if (ref.current) {
            qrCode.append(ref.current);
        }
    }, [qrCode, ref]);

    useEffect(() => {
        if (!qrCode) return;
        qrCode.update(options);
    }, [qrCode, options]);

    const onDataChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOptions(options => ({
            ...options,
            data: event.target.value
        }));
    };

    const onExtensionChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFileExt(event.target.value);
    };

    const onDownloadClick = () => {
        if (!qrCode) return;
        qrCode.update(options);
        if (ref.current) {
            qrCode.append(ref.current);
        }
        qrCode.download({
            name: qrCode._options.data,
            extension: fileExt
        });
    };


    return (
        <Container fluid={true}>
            <Row>
                <Col>
                    <InputField name={'name'}
                                model={undefined}
                                label={"text"}
                                onChange={onDataChange}
                                value={options.data}/>
                </Col>
                <Col>
                    <div className="App">
                        <div ref={ref}/>
                        <div style={styles.inputWrapper}>
                            <select onChange={onExtensionChange} value={fileExt}>
                                <option value="svg">SVG</option>
                                <option value="png">PNG</option>
                                <option value="jpeg">JPEG</option>
                                <option value="webp">WEBP</option>
                            </select>
                            <Button onClick={onDownloadClick}>Скачать</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

