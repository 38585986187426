import React, {lazy, Suspense, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'moment/locale/ru';
import 'ui/i18n/i18n';

import {authStore} from "./store/AuthStore";
import {sleep} from "./utils/helpers";

import Layout from "ui/Layout";
import crudRouters from "pages/routes"
import KassaIndex from "pages/kassa/kassa";
import Profile from "pages/profile/Profile";
import NotFoundPage from "ui/layout/components/404";
import LoginPage from "modules/auth/login/loginPage";
import CamIndex from "./pages/cam";
import CamView from "./pages/cam/view";
import {KassaCash} from "./pages/kassa/cash";

import {Statistic} from "./pages/stat/Statistic";
import 'css/index.scss'
import MainLoader from "./ui/loaders/MainLoader";
import {Index} from "./pages/index";
import QrCodeGenerator from "./pages/tools/qrCodeGenerator";

//import TelegramEmu from "./pages/telegrambot/emu";
//import Tree from "./pages/telegrambot/tree/graph2";
//import ChatGpt from "./pages/chatgpt";

const SwaggerPage = lazy(() => import("modules/swagger/swagger"));

const ChatGpt = lazy(() => import("./pages/chatgpt"));

const App = () => {
    const [isAppLoaded, setIsAppLoaded] = useState(false);
    //   const [isAuth, setIsAuth] = useState(false);

    const fetchProfile = async () => {
        console.log("app fetchProfile")
        try {
            if (authStore.isAuthorized) {
                console.log("isAuthorized")

                setIsAppLoaded(true)
            } else {
                console.log("!isAuthorized")
                //navigate("/login")

                setIsAppLoaded(true)
                authStore.logout();
            }
            await sleep(500);
            setIsAppLoaded(true);
        } catch (error) {
            authStore.logout();
            await sleep(500);
            setIsAppLoaded(true);
        }
    };

    useEffect(() => {
        fetchProfile().then()
    }, []);

    if (!isAppLoaded) {
        return <MainLoader wrapperClass="preloader flex-column justify-content-center align-items-center"/>
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<LoginPage/>}/>
                {
                    (authStore.isAuthorized) ?
                        <Route path="/" element={<Layout/>}>
                            <Route path="/" element={<Index/>}/>
                            <Route path="/index" element={<Index/>}/>
                            <Route path="/kassa" element={<KassaIndex/>}/>
                            <Route path="/kassa/cash" element={<KassaCash/>}/>
                            <Route path="/swagger" element={<Suspense><SwaggerPage/></Suspense>}/>
                            <Route path="/swagger/:filter" element={<Suspense><SwaggerPage/></Suspense>}/>
                            <Route path="/profile" element={<Profile/>}/>
                            <Route path="/cam" element={<CamIndex/>}/>
                            <Route path="/cam/:id" element={<CamView/>}/>
                            {/* <Route path='/telegrambot/tree/' element={<Tree/>}/>
                    <Route path='/telegrambot/emu/' element={<TelegramEmu/>}/>*/}
                            <Route path='/chatgpt/' element={<Suspense><ChatGpt/></Suspense>}/>
                            <Route path='/stat/' element={<Statistic/>}/>
                            <Route path='/qrCodeGenerator/' element={<QrCodeGenerator/>}/>
                            {crudRouters}
                            <Route path="*" element={<NotFoundPage/>}/>
                        </Route> : <Route path="*" element={<LoginPage/>}/>
                }

            </Routes>
            <ToastContainer
                autoClose={3000}
                draggable={false}
                position="top-right"
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnHover
            />
        </BrowserRouter>
    )


}


export default App
