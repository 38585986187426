/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {UsersModel} from "./_model";
import {Navigate} from "react-router-dom";
import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {Translation, withTranslation} from "react-i18next";
import TableLoader from "../../ui/loaders/TableLoader";
import React from "react";
import UserBox from "../profile/userBox";
import InfoTab from "../profile/tabs/InfoTab/InfoTab";
import AccountTab from "../profile/tabs/AccountTab/AccountTab";
import CheckpointTab from "../profile/tabs/CheckpointTab";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

class UsersView extends BaseViewPage {
    //public model = UsersModel;

    constructor(props: any) {
        super(props);
        super.model = UsersModel;
        super.relationTree = {
            relation: ["role"],
            relations: [
                //role: [],
                //userCheckpoints: [],
                "userImages",
                //"userCheckpoints",
                //userEvents: [],
                //userInvoices: [],
                "userAuthLogins",
                "userAuthEmails",
                "userAuthTelegrams",
            ],
        }
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath}/>;
        }

        return (this.state.isDataLoaded)
            ? <>
                <Container fluid={true}>
                    <Row>
                        <Col md={3}>
                            <UserBox profile={this.state.data}/>
                        </Col>
                        <Col md={9}>
                            <Tabs defaultActiveKey="InfoTab" className="mb-3 card-header bg-light" fill variant="pills">
                                {
                                    /*  <Tab eventKey="activity" title={t('main.label.activity')}>
                                      <ActivityTab/>
                                  </Tab>
                                  <Tab eventKey="timeline" title={t('main.label.timeline')}>
                                      <TimelineTab/>
                                  </Tab>*/
                                }
                                <Tab eventKey="InfoTab"
                                     title={<>
                                         <FontAwesomeIcon icon={solid("info-circle")} className="right pr-1"/>
                                         <Translation>{(t: any) => t('main.label.info')}</Translation>
                                     </>}>
                                    <InfoTab profile={this.state.data}/>
                                </Tab>
                                <Tab eventKey="accounts"
                                     title={<>
                                         <FontAwesomeIcon icon={solid("cog")} className="right pr-1"/>
                                         <Translation>{(t: any) => t('main.label.accounts')}</Translation>
                                     </>}>
                                    <AccountTab profile={this.state.data}/>
                                </Tab>
                                <Tab eventKey="checkpoint"
                                     title={<>
                                         <FontAwesomeIcon icon={solid("list")} className="right pr-1"/>
                                         <Translation>{(t: any) => t('main.label.activity')}</Translation>
                                     </>}>
                                    <CheckpointTab profile={this.state.data}/>
                                </Tab>
                            </Tabs>

                        </Col>
                    </Row>
                </Container>
            </> : <TableLoader/>;
    }
}

export default withTranslation()(withRouter(UsersView));