/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import BaseIndexPage from "crud/base/BaseIndexPage";
import {WikiPagesModel} from "./_model";

class WikiPagesIndex extends BaseIndexPage {
    //public model = WikiPagesModel;

    constructor(props: any) {
        super(props);
        super.model = WikiPagesModel;
        super.defaultShowAttrs = WikiPagesModel.defaultShowAttrs;
        super.relationTree = WikiPagesModel.relationTree
    }
}

export default WikiPagesIndex;