/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {TableGamesTypesModel} from "./_model";

class TableGamesTypesView extends BaseViewPage {
    //public model = TableGamesTypesModel;

    constructor(props: any) {
        super(props);
        super.model = TableGamesTypesModel;
        super.relationTree = TableGamesTypesModel.relationTree
    }
}

export default withRouter(TableGamesTypesView);