import React from "react";
import {Dropdown, DropdownButton, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import {AttrInfoArray} from "../types/AttrInfo";

type Props = {
    /** An array with fetched rows. */
    attributes: AttrInfoArray,
    enabledAttributes: any,
    onChange: any,
};


export default function TableIndexAttrDropdown(props: Props) {
    let attrDropdownTimes = [];
    for (let attrKey in props.attributes) {
        let isEnable = props.enabledAttributes.find((i: any) => i === attrKey) !== undefined
        //console.log(attrKey,isEnable )
        attrDropdownTimes.push(
            <Dropdown.ItemText key={attrKey} className="text-sm">
                <Form.Check
                    type="switch"
                    className="pl-4"
                    style={{minHeight: "0.5rem"}}
                    id={attrKey}
                    onChange={() => props.onChange(attrKey)}
                    label={props.attributes[attrKey].label}
                    /*   defaultChecked={false}*/
                    checked={isEnable}
                />
            </Dropdown.ItemText>
        );
    }

    return <DropdownButton
        menuAlign="right"
        variant="dark btn-sm" className="btn-sm p-0"
        title={<FontAwesomeIcon icon={faCog}/>}>
        {
            attrDropdownTimes
        }
    </DropdownButton>

}