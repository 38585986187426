/**
 * Generated by ReactYii 3.0.27 at 27.11.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {EventUserCheckpointParticipantsModel} from "./_model";

class EventUserCheckpointParticipantsView extends BaseViewPage {
    //public model = EventUserCheckpointParticipantsModel;

    constructor(props: any) {
        super(props);
        super.model = EventUserCheckpointParticipantsModel;
        super.relationTree = EventUserCheckpointParticipantsModel.relationTree
    }
}

export default withRouter(EventUserCheckpointParticipantsView);