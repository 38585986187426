import {ApiRequest, HttpMethod} from "api/api";
import {Pagination} from "../../crud/types/Pagination";
import stringify from "qs-stringify";
import {PaymentType} from "../../models/kassa/PaymentType";


const KassaGetOnlineCheckpoints = (tree: any): Promise<any> => {
    const params = {relationtree: JSON.stringify(tree)};
    return ApiRequest<any>(HttpMethod.GET, "/rapi/user-checkpoints/online?pageSize=999", params);
};

const KassaGetCheckpointsHistory = (tree: any, pagination?: Pagination): Promise<any> => {
    const params = {page: pagination?.page ?? 1, relationtree: JSON.stringify(tree)};
    return ApiRequest<any>(HttpMethod.GET, "/rapi/user-checkpoints/index", params);
};

const KassaByBarCode = (barcode: string): Promise<any> => {
    const params = {barcode};
    return ApiRequest<any>(HttpMethod.GET, "/rapi/user-checkpoints/barcode-entrance", params);
};
const KassaGetCheckpointId = (id: string, tree: any): Promise<any> => {
    const params = {relationtree: JSON.stringify(tree), id};
    return ApiRequest<any>(HttpMethod.GET, "/rapi/user-checkpoints/view", params);
};

//const KassaCheckout = (id: number, checkoutType: CheckoutType): Promise<any> => {
const KassaCheckout = (id: number, checkoutType: PaymentType, jitter: number): Promise<any> => {
    const params = {user_checkpoint_id: id, payment_type: checkoutType, jitter: jitter};
    return ApiRequest<any>(HttpMethod.POST, "/rapi/user-checkpoints/close", stringify(params));
};


/*
const Invoices = (tree: any): Promise<any> => {
    const params = {sort: "-id", relationtree: JSON.stringify(tree)};
    return ApiRequest<any>(HttpMethod.GET, "/rapi/user-invoices/index", params);
};
*/

const KassaGetCurrentTurns = (): Promise<any> => ApiRequest<any>(HttpMethod.GET, "/rapi/user-turns/get-current/");
const KassaCloseTurns = (id: any): Promise<any> => ApiRequest<any>(HttpMethod.GET, `/rapi/user-turns/close/?id=${id}`);

const KassaUpdateCheckpoint = (id: number, params: any): Promise<any> => {
    return ApiRequest<any>(HttpMethod.POST, "/rapi/user-checkpoints/update?id=" + id, stringify(params));
};

export {
    KassaByBarCode,
    KassaGetOnlineCheckpoints,
    KassaCheckout,
    KassaGetCheckpointsHistory,
    KassaGetCurrentTurns,
    KassaCloseTurns,
    KassaGetCheckpointId,
    KassaUpdateCheckpoint
};