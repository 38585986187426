/**
 * Generated by ReactYii 3.0.27 at 23.11.2023
 */

import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {UserTurnsModel} from "./_model";
import {Navigate} from "react-router-dom";
import {Card, Col, Row} from "react-bootstrap";
import {BaseDeleteModal} from "../../crud/dialogs/BaseDeleteModal";
import DataTable from "../../crud/data_viewers/DataTable";
import TableLoader from "../../ui/loaders/TableLoader";
import React from "react";
import TableIndex from "../../crud/TableIndex/TableIndex";
import {CheckpointsCustomModel} from "./CheckpointCustom";
import CollapsedCard from "../../adminLTE/components/CollapsedCard";
import {FinanceReport} from "./report/FinanceReport";
import {ReportLoadByHour} from "./report/ReportLoadByHour";

class UserTurnsView extends BaseViewPage {
    //public model = UserTurnsModel;
    constructor(props: any) {
        super(props);
        super.model = UserTurnsModel;
        super.relationTree = {
            relation: {
                user: [],
            },
            relations: {
                "userCheckpoints": {
                    relation: {
                        tarif: [],
                        user: {
                            fields: ['id', 'image_name', 'name', 'barcode', 'balance'],
                            relation: {'role': []}
                        }
                    },
                    relations: {
                        userInvoices: {
                            relations: {
                                userPayments: [],
                            }
                        }
                    }
                },
            },
        }
    }


    render() {
        const defaultShowAttrs = ["created_at", "minutes", "profile", "role", "tarif", "sum", "status"];

        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath}/>;
        }

        return (this.state.isDataLoaded)
            ? <div>
                <Card className="card-outline card-success">
                    <BaseDeleteModal show={this.state.modal} onDelete={this.deleteModel} onClose={this.closeModal}/>
                    <Card.Header>
                        <Row className="justify-content-between">
                            <Col><h1>{this.state.data?.name ?? "#" + this.state.data?.id}</h1></Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <DataTable
                            data={this.state.data}
                            model={this.model}
                            disabledFields={this.disabledAttr}
                        />
                    </Card.Body>
                </Card>
                <Row>
                    <Col>
                        <CollapsedCard
                            initialOpen={true}
                            title={<h5>Кеш и не кэш</h5>}>
                            <FinanceReport checkpoints={this.state.data?.userCheckpoints}/>
                        </CollapsedCard>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CollapsedCard
                            initialOpen={true}
                            title={<h5>Загрузка по часам</h5>}>
                            <ReportLoadByHour turns_id={this.props.match.params.id}/>
                        </CollapsedCard>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CollapsedCard
                            initialOpen={true}
                            title={<h5>Гостей за смену: <b>({this.state.data?.userCheckpoints?.length})</b></h5>}>
                            <TableIndex
                                model={CheckpointsCustomModel}
                                data={this.state.data?.userCheckpoints}
                                enabledAttributes={defaultShowAttrs}
                                showSettings={true}
                                sortBy="id"
                                //onSortChange={this.onSortChange}
                                //searchQuery={this.state.searchString}
                                //onSearch={this.onSearch}
                                onRelationTreeChange={() => {
                                }}
                            />
                        </CollapsedCard>
                    </Col>
                </Row>

            </div>
            : <TableLoader/>;
    }
}

export default withRouter(UserTurnsView);