/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const WikiCategoriesModel: BaseModelInfo = {
    info: {
        name: "wiki-categories",
        label: "Wiki Categories",
        apiPath: "/rapi/wiki-categories",
        viewPath: "/wiki/wiki-categories"
    },
    attrs: {
        id: {label: "ID"},
        parent_id: {label: "Родитель"},
        name: {label: "Название раздела"},
        parent: {label: '"Parent"', type: FieldType.RelationOneData, params: {name: "name", model: this}},
        children: {label: '"Children"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        wikiPages: {label: '"WikiPages"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },
    relationTree: {relations: {wikiPages: []}},
    defaultShowAttrs: ["parent_id", "name",]
};


