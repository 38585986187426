/* imports */
import EventUserCheckpointParticipantsRoutes from "pages/event-user-checkpoint-participants/_routes"
import UserTurnsRoutes from "pages/user-turns/_routes"
import WssLogRoutes from "pages/wss/_routes"
import UserTableGameEventParticipantsRoutes from "pages/table-games/user-table-game-events-participants/_routes"
import UserTableGameEventsRoutes from "pages/table-games/user-table-game-events/_routes"
import UserPaymentsRoutes from "pages/user-payments/_routes"
import EventCalendarsRoutes from "pages/table-games/event-calendars/_routes"
import TagsRoutes from "pages/tags/_routes"
import WikiCategoriesRoutes from "pages/wiki/wiki-categories/_routes"
import WikiPagesRoutes from "pages/wiki/wiki-pages/_routes"
//import TelegrambotUserTriggersRoutes from "pages/telegrambot/telegrambot-user-triggers/_routes"
//import TelegrambotUserMessagesRoutes from "pages/telegrambot/telegrambot-user-messages/_routes"
//import TelegrambotMessagesRoutes from "pages/telegrambot/telegrambot-messages/_routes"
//import TelegrambotFaqRoutes from "pages/telegrambot/telegrambot-faq/_routes"
//import TelegrambotModulesRoutes from "pages/telegrambot/telegrambot-modules/_routes"
import EventTypesRoutes from "pages/table-games/event-types/_routes"
import EventsRoutes from "pages/table-games/events/_routes"
import TableGamesTypesRoutes from "pages/table-games/table-games-types/_routes"
import TableGamesThemesRoutes from "pages/table-games/table-games-themes/_routes"
import TableGamesPricesRoutes from "pages/table-games/table-games-prices/_routes"
import TableGamesPlacesRoutes from "pages/table-games/table-games-places/_routes"
import TableGamesImgsRoutes from "pages/table-games/table-games-imgs/_routes"
import TableGamesCoopsRoutes from "pages/table-games/table-games-coops/_routes"
import TableGamesRoutes from "pages/table-games/table-games/_routes"
import tableGamesRoutes from "pages/table-games/table-games/_routes"
import RoleGroupsRoutes from "pages/role-groups/_routes"
import TarifsRoutes from "pages/tarifs/_routes"

import UserInvoicesRoutes from "pages/user-invoices/_routes"
import UserCheckpointsRoutes from "pages/user-checkpoints/_routes"
import RolesRoutes from "pages/roles/_routes"
import CardsRoutes from "pages/cards/_routes"
import UsersRoutes from "pages/users/_routes"
import WikiRoutes from "./wiki/_routes";


// eslint-disable-next-line import/no-anonymous-default-export
export default [
    /* exports */
    EventUserCheckpointParticipantsRoutes,
    UserTurnsRoutes,
    WssLogRoutes,
    UserTableGameEventParticipantsRoutes,
    UserTableGameEventsRoutes,
    UserPaymentsRoutes,
    EventCalendarsRoutes,
    TagsRoutes,
    WikiRoutes,
    WikiCategoriesRoutes,
    WikiPagesRoutes,
    //TelegrambotUserTriggersRoutes,
    //TelegrambotUserMessagesRoutes,
    //TelegrambotMessagesRoutes,
    //TelegrambotFaqRoutes,
    //TelegrambotModulesRoutes,
    EventTypesRoutes,
    EventsRoutes,
    TableGamesTypesRoutes,
    TableGamesThemesRoutes,
    TableGamesPricesRoutes,
    TableGamesPlacesRoutes,
    TableGamesImgsRoutes,
    TableGamesCoopsRoutes,
    TableGamesRoutes,
    tableGamesRoutes,
    RoleGroupsRoutes,
    TarifsRoutes,
    UsersRoutes,
    RolesRoutes,
    UserInvoicesRoutes,
    UserCheckpointsRoutes,
    RolesRoutes,
    CardsRoutes,
    UsersRoutes,
]
