/**
 * Generated by ReactYii 3.0.27 at 15.08.2023
 */
import BaseCreatePage from "crud/base/BaseCreatePage";
import UserPaymentsForm from "./_form";
import {UserPaymentsModel} from "./_model";


class UserPaymentsCreate extends BaseCreatePage {
    //public model = UserPaymentsModel;
    //public FormComponent = UserPaymentsForm;

    constructor(props: any) {
        super(props);
        super.model = UserPaymentsModel;
        super.FormComponent = UserPaymentsForm;
        super.relationTree = UserPaymentsModel.relationTree
    }
}

export default UserPaymentsCreate;