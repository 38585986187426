/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import UsersCreate from './create';
import UsersUpdate from './update';
import UsersView from './view';
import UsersIndex from './index';
import {UsersModel} from "./_model";

const UsersRoutes = [
    <>
        <Route path={UsersModel.info.viewPath} element={<UsersIndex/>}/>
        <Route path={UsersModel.info.viewPath + '/create'} element={<UsersCreate/>}/>
        <Route path={UsersModel.info.viewPath + '/update/:id'} element={<UsersUpdate/>}/>
        <Route path={UsersModel.info.viewPath + '/view/:id'} element={<UsersView/>}/>
    </>
];
export default UsersRoutes;
