import React, {useEffect, useState} from 'react'
import Select, {StylesConfig} from 'react-select'
import {ApiRequest, HttpMethod} from "../../../api/api";
import {InputGroup} from "react-bootstrap";
import {toast} from "react-toastify";


type Props = {
    name: string,
    model: string,
    label: boolean | string,
    class?: string,
    value: any,
    onChange: Function,
    required?: boolean,
    helpBlock?: string,
    validated?: any,
    defaultValueText?: string,
    placeholder?: string,
    relationUrl: string,
    pluginProps?: any,
};


export default function EventOneDropDown(props: Props) {
    //const [helpBlock, setHelpBlock] = useState("");
    const [isDataLoaded, setDataLoaded] = useState(false);

    const [relationData, setRelationData] = useState([]);
    const [selected, setSelected] = useState();


    useEffect(() => getRelationValue(), []);

    useEffect(() => {

        Array.isArray(relationData) && setSelected(relationData.find((v: any) => v.id === props.value));
    }, [relationData, props]);

    const getRelationValue = () => {
        console.log("getRelationValue by", props.relationUrl)
        ApiRequest<any>(HttpMethod.GET, "/" + props.relationUrl + "&pageSize=999")
            .then((resp: any) => {
                console.log(resp.data)
                //resp?.data.map((value: any) => value.name = "<b>" + value.name + "</b>")
                setRelationData(resp?.data ?? []);
                setDataLoaded(true)
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed get relation'));

    }


    const dot = (image: any) => ({
        alignItems: 'center',
        display: 'flex',

        ':before': {
            backgroundImage: `url(https://zdbot.zdver.com/files/events/${image})`,
            backgroundSize: "cover",
            //borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 5,
            height: 20,
            width: 20,
        },
    });

    const colourStyles: StylesConfig<any> = {
        container: (baseStyles, _) => ({...baseStyles, padding: '0',}),
        control: (baseStyles, _) => ({...baseStyles, border: "none", minHeight: "9"}),
        /*        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    //const color = data.color;
                    return {
                        ...styles,
                       /!* backgroundColor: isDisabled
                            ? undefined
                            : isSelected
                                ? data.color
                                : isFocused
                                    ? color.alpha(0.1).css()
                                    : undefined,
                        cursor: isDisabled ? 'not-allowed' : 'default',

                        ':active': {
                            ...styles[':active'],
                            backgroundColor: !isDisabled
                                ? isSelected
                                    ? data.color
                                    : color.alpha(0.3).css()
                                : undefined,
                        },*!/
                    };
                },*/
        option: (styles, {data}) => ({...styles, ...dot(data.image_name)}),
        //input: (styles, {options}) => ({...styles, ...dot(data.image_name)}),
        // placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
        singleValue: (styles, {data}) => ({...styles, ...dot(data.image_name)}),
    };


    let required = (props.pluginProps !== undefined) ? (props.pluginProps.hasOwnProperty("required") ? 'required' : '') : '';
    return (
        <InputGroup className={'form-group field-' + props.model + '-' + props.name + ' ' + props.class}>
            <InputGroup.Prepend>
                <InputGroup.Text className={'control-label ' + required}>{props.label}</InputGroup.Text>
            </InputGroup.Prepend>
            <Select
                {...props.pluginProps}
                // styles={customStyles}
                //options={props.options}
                getOptionLabel={(v: any) => v.name}
                getOptionValue={(v: any) => v.id}
                options={relationData}
                value={selected}

                placeholder={props.placeholder ?? "Выберите " + props.label}
                //validated={validated}
                name={props.name}
                isLoading={!isDataLoaded}
                isSearchable={true}
                styles={colourStyles}
                /* styles={{
                     container: (baseStyles, _) => ({...baseStyles, padding: '0',}),
                     control: (baseStyles, _) => ({...baseStyles, border: "none", minHeight: "9"}),
                 }}*/
                classNames={{
                    container: () => "form-control",
                    //control: (state) => "react-select-container form-control",
                }}
                onChange={(e) => props.onChange(e)}
                required={props.required}
                //className={(validated !== '' ? (validated === true) ? 'is-valid' : 'is-invalid' : '')}
            />
        </InputGroup>
    )

}

