/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField} from "crud";
import BaseForm from "crud/base/BaseForm";
import {UsersModel} from "./_model";
import BarcodeInputField from "../../crud/FormInputs/custom/BarcodeInputField";
import RelationOneDropDown from "../../crud/FormInputs/RelationOneDropDown";

class UsersForm extends BaseForm {
    //public model = UsersModel;

    constructor(props: any) {
        super(props);
        super.model = UsersModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                role_id: '',
                barcode: '',
                name: '',
                phone: '',
                comment: '',
                balance: '',
                is_dead_soul: true,
                status: '',
                created_at: '',
                updated_at: '',
                birthday: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>
                    <InputField name={'name'}
                                model={this.model.info.name}
                                label={this.model.attrs.name.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['name']}/>

                    <BarcodeInputField name={'barcode'}
                                       model={this.model.info.name}
                                       label={this.model.attrs.barcode.label}
                                       onChange={(barcode: any) => {
                                           let form = this.state.form;
                                           form["barcode"] = barcode;
                                           form["name"] = "Карта " + barcode?.slice(-3);
                                           this.setState({form: form});
                                       }}
                                       value={this.state.form['barcode']}/>

                    <RelationOneDropDown name={'role_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.role?.label}
                                         relationUrl={"/rapi/roles/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "role_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['parent_id']}/>

                    <InputField name={'comment'}
                                model={this.model.info.name}
                                label={this.model.attrs.comment.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['comment']}/>

                    <InputField name={'is_dead_soul'}
                                model={this.model.info.name}
                                label={'Флаг мертвых душ'}
                                disabled={true}
                                onChange={this.handleInputChange}
                                value={this.state.form['is_dead_soul']}/>

                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default UsersForm;
