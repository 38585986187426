import {toast} from "react-toastify";
import React from "react";

export default function onPayment(resp: any) {
    toast.success(
        <div>
            {resp.payment.Purpose}
            <b>{resp.payment.sum} руб</b><br/>
        </div>
    );
}
