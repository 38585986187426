import {camelToUnderscore, camelToYii} from "../../utils/case_transforms";
import {ApiRequest, HttpMethod} from "../../api/api";
import {Constants} from "../../utils/constants";

export const RelationManyGetList = (relationUrl: string): Promise<any> => {
    const url = `${relationUrl}/index?page_size=999`
    return ApiRequest<any>(HttpMethod.GET, url, []);
};

export const RelationManyAdd = (modelName: string, modelId: number, relationModelName: string, relationModelId: string): Promise<any> => {
    const action = `add-${camelToYii(relationModelName)}`;
    const endpoint = `/${modelName}/${action}`;
    const params = {id: modelId, [camelToUnderscore(relationModelName) + '_id']: relationModelId};
    const url = `${Constants.ApiVersion}${endpoint}`;
    return ApiRequest<any>(HttpMethod.GET, url, params);
};

export const RelationManyRemove = (modelName: string, modelId: number, relationModelName: string, relationModelId: string): Promise<any> => {
    const action = `remove-${camelToYii(relationModelName)}`;
    const endpoint = `/${modelName}/${action}`;
    const params = {id: modelId, [camelToUnderscore(relationModelName) + '_id']: relationModelId};
    const url = `${Constants.ApiVersion}${endpoint}`;
    return ApiRequest<any>(HttpMethod.GET, url, params);
};

/*
export const RelationManyAdd = async (modelName: string, modelId: number, relationModelName: string, relationModelId: string, onResponse: any) => {
    let action = "add-" + camelToYii(relationModelName);
    let endpoint = "/" + modelName + "/" + action;
    let params = "?id=" + modelId + "&" + camelToUnderscore(relationModelName) + "_id=" + relationModelId;

    await axios.get(Constants.API_URL + endpoint + params)
        .then((response) => GlobalOnResponse(response, onResponse))
        .catch((error: any) => GlobalOnError(error, onResponse))
}

export const RelationManyRemove = async (modelName: string, modelId: number, relationModelName: string, relationModelId: string, onResponse: any) => {
    let action = "remove-" + camelToYii(relationModelName);
    let endpoint = "/" + modelName + "/" + action;
    let params = "?id=" + modelId + "&" + camelToUnderscore(relationModelName) + "_id=" + relationModelId;

    await axios.get(Constants.API_URL + endpoint + params)
        .then((response) => GlobalOnResponse(response, onResponse))
        .catch((error: any) => GlobalOnError(error, onResponse))
}*/
