import React, {Component} from 'react'
import {classPostfix} from '../api/yiiValidation'
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {FieldInputProps} from "../types/FieldInputProps";
import {Form} from "react-bootstrap";

interface State {
    helpBlock: string,
    validation: string | boolean,
}

class WysiwygEditor extends Component<FieldInputProps, State> {

    constructor(props: FieldInputProps) {
        super(props)
        this.state = {helpBlock: '', validation: ''}
    }

    onChange = (event: any) => {
        this.props.onChange(event)
    }

    render() {
        let validated = (this.props.validated !== undefined) ? this.props.validated : this.state.validation;
        return (
            <Form.Group controlId="formFile">
                <Form.Label>{this.props.label || ''}</Form.Label>
                <ReactQuill
                    className="bg-white rounded"
                    //id={this.props.model + '-' + this.props.name}
                    //name={this.props.name}
                    //placeholder={this.props.placeholder}
                    theme="snow"
                    value={this.props.value || ''}
                    onChange={this.onChange}
                />
                <div className={classPostfix(validated)}>
                    {(this.props.validated !== undefined) ? this.props.helpBlock : this.state.helpBlock}
                </div>
            </Form.Group>

        )
    }
}

export default WysiwygEditor;
