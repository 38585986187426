/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField} from "crud";
import BaseForm from "crud/base/BaseForm";
import {UserCheckpointsModel} from "./_model";

class UserCheckpointsForm extends BaseForm {
    //public model = UserCheckpointsModel;

    constructor(props: any) {
        super(props);
        super.model = UserCheckpointsModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                user_id: '',
                tarif_id: '',
                is_inside: '',
                is_outside: '',
                from: '',
                is_payed: '',
                created_at: '',
                updated_at: '',
                outside_at: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return <form onSubmit={this.handleSubmit}>
            <div className={"flex-form"}>
                <InputField name={'user_id'}
                            model={this.model.info.name}
                            label={this.model.attrs.user_id.label}
                            onChange={this.handleInputChange}
                            value={this.state.form['user_id']}/>

                <InputField name={'tarif_id'}
                            model={this.model.info.name}
                            label={this.model.attrs.tarif_id.label}
                            onChange={this.handleInputChange}
                            value={this.state.form['tarif_id']}/>

                <InputField name={'is_inside'}
                            model={this.model.info.name}
                            label={this.model.attrs.is_inside.label}
                            onChange={this.handleInputChange}
                            value={this.state.form['is_inside']}/>

                <InputField name={'is_outside'}
                            model={this.model.info.name}
                            label={this.model.attrs.is_outside.label}
                            onChange={this.handleInputChange}
                            value={this.state.form['is_outside']}/>

                <InputField name={'from'}
                            model={this.model.info.name}
                            label={this.model.attrs.from.label}
                            onChange={this.handleInputChange}
                            value={this.state.form['from']}/>

                <InputField name={'is_payed'}
                            model={this.model.info.name}
                            label={this.model.attrs.is_payed.label}
                            onChange={this.handleInputChange}
                            value={this.state.form['is_payed']}/>

                <InputField name={'created_at'}
                            model={this.model.info.name}
                            label={this.model.attrs.created_at.label}
                            onChange={this.handleInputChange}
                            value={this.state.form['created_at']}/>

                <InputField name={'updated_at'}
                            model={this.model.info.name}
                            label={this.model.attrs.updated_at.label}
                            onChange={this.handleInputChange}
                            value={this.state.form['updated_at']}/>

                <InputField name={'outside_at'}
                            model={this.model.info.name}
                            label={this.model.attrs.outside_at.label}
                            onChange={this.handleInputChange}
                            value={this.state.form['outside_at']}/>

                <div className={'form-validation'}>{this.state.validation}</div>
            </div>

            <div className={"text-center"}>
                <button type={"submit"} className={'btn btn-success'}>
                    <Translation>{(t: any) => t('crud.save')}</Translation>
                </button>
            </div>
        </form>

    }
}

export default UserCheckpointsForm;
