import React from 'react';
import {User} from "../../../models/users/User";
import TableIndex from "../../../crud/TableIndex/TableIndex";
import {UserCheckpointsModel} from "../../user-checkpoints/_model";
import BaseIndexPage from "../../../crud/base/BaseIndexPage";
import ErrorWidget from "../../../crud/components/ErrorWidget";
import MainLoader from "../../../ui/loaders/MainLoader";
import {Pagination} from "../../../crud/types/Pagination";
import {breadcrumbsStore} from "../../../store/BreadcrumbsStore";
import {Translation} from "react-i18next";
import {Card} from "react-bootstrap";

type Props = {
    profile?: User
}

/*
const CheckpointTab = (props: Props) => {
    const defaultShowAttrs = ["created_at", "minutes", "sum", "status"];
    //const [checkpoints, setCheckpoints] = useState<Array<CheckpointUser>>([]);
    //const [pagination, setPagination] = useState<Pagination>(new Pagination());

    /!*
    const renderRow = () => props?.profile?.userCheckpoints?.map((checkpoint) => {
        return checkpoint.created_at;
    })
    *!/

    return (
        <div>
            <div className="table-responsive-sm">

                <TableIndex
                    model={UserCheckpointsModel}
                    data={props?.profile?.userCheckpoints ?? []}
                    enabledAttributes={defaultShowAttrs}
                    showSettings={true}
                    //sortBy="-id"
                    //onSortChange={this.onSortChange}
                    //searchQuery={this.state.searchString}
                    //onSearch={this.onSearch}
                    onRelationTreeChange={() => {
                    }}
                />
            </div>

            {/!*  {renderRow()}*!/}
        </div>
    );
};
*/


class CheckpointTab extends BaseIndexPage {
    //public model = UserCheckpointsModel;

    constructor(props: any) {
        super(props);
        super.model = UserCheckpointsModel;
        super.defaultShowAttrs = ["created_at", "minutes", "tarif", "sum", "status"]
        super.relationTree = UserCheckpointsModel.relationTree;
        super.state = {
            data: {data: [], pagination: new Pagination()},
            isLoadedFirstPage: false,
            hasError: false,
            hasMore: true,
            sortBy: "-id", //id,-id, someField
            searchArray: {},//{"user_id": props?.profile?.id},
            showAttr: {},
            options: null,
        };
    }

    filterByUser = () => {
        this.setState({
            //searchArray: {"user_id": this.props?.profile?.id},
            options: this.props.profile,
        })

        this.onSearch({"user_id": this.props?.profile?.id});
    }

    componentDidMount() {
        //super.componentDidMount();
        this.filterByUser()
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
            {name: this.model.info.label, link: ''}
        ];
        //this.getData(this.state.data.pagination, true);
    }


    componentDidUpdate(prevProps: Props) {
        console.log("componentDidUpdate", this.props)
        if (this.state?.options !== this.props.profile) {
            this.filterByUser()
        }
    }


    render() {
        if (this.state.hasError) {
            return <ErrorWidget/>;
        }

        return (
            <Card>
                {this.state.isLoadedFirstPage ? (
                    <TableIndex
                        model={this.model}
                        data={this.state.data.data}
                        enabledAttributes={this.defaultShowAttrs}
                        actions={{update: {title: "update"}}}
                        pagination={{data: this.state.data.pagination, callback: this.actionPagination}}
                        sortBy={this.state.sortBy}
                        onSortChange={this.onSortChange}
                        searchQuery={this.state.searchArray}
                        onSearch={this.onSearch}
                        onRelationTreeChange={this.onTreeChange}
                    />
                ) : (
                    <MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>
                )}
            </Card>
        );
    }
}

export default CheckpointTab;
